import React, {Fragment} from 'react';
import PropTypes from "prop-types";
import {Button, Form, Grid, Icon, Input, Modal, Popup, Radio, Select, Table} from "semantic-ui-react";
import MiniBox from "../common/MiniBox";
import CompatibleCheckbox from '../common/CompatibleCheckbox';

function FormLabel(props) {
  return <label>
    <Popup
        inverted
        basic
        style={{backgroundColor: "#6bb8bc"}}
        trigger={<Icon name='info circle' style={{color:"#6bb8bc"}}/>}
        content={props.helpText}
    />
    {props.text}
  </label>;
}

FormLabel.propTypes = {
  text: PropTypes.string.isRequired,
  helpText: PropTypes.string.isRequired
};

const muligePersonAldre = () => {
  let muligeAldre = [];
  for (let i = 17; i <= 77; i++)
    muligeAldre.push({key: i, value: i, text: i});
  return muligeAldre;
};

const muligePensionsAldre = () => {
  let muligeAldre = [];
  for (let i = 60; i <= 77; i++)
    muligeAldre.push({key: i, value: i, text: i});
  return muligeAldre;
};

const formatNumber = (number, decimals) => {
  if (number == null)
    return null;

  return number.toLocaleString(undefined, {minimumFractionDigits: decimals || 0, maximumFractionDigits: decimals || 0});
};


const OmkostningberegnerPresentation = ({
                                          tablet,
                                          udvidet,
                                          input,
                                          visberegningsdetaljer,
                                          visopsparingOvermaxModal,
                                          genericFieldHandler,
                                          lukopsparingOvermaxModal,
                                          output,
                                          toggleDetaljer
                                        }) => {
  return (
      <div>
        <Modal open={visopsparingOvermaxModal} onClose={lukopsparingOvermaxModal}>
          <Modal.Header>
            Individuel vurdering
          </Modal.Header>
          <Modal.Content>
            <p>Din aftale er så stor, at du skal have en individuel vurdering af, hvilke omkostninger du skal betale.
              Du skal derfor tage fat i dit pengeinstitut, som kan vise dig en beregning, som tager udgangspunkt i netop
              dine muligheder.</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={lukopsparingOvermaxModal}>Luk</Button>
          </Modal.Actions>
        </Modal>

        <Grid>
          <Grid.Row columns={(tablet ? 1 : 2)}>
            <Grid.Column>
              <Form autoComplete="off">
                <table width="100%">
                  <tbody>
                  <tr>
                    <td className="field">
                      <FormLabel
                          text="Din nuværende pensionsopsparing (i kroner)"
                          helpText="Er du kunde i Letpension, kan du se din pensionsopsparing i Letportalen via din netbank. Ellers kan du se din pensionsopsparing i Pensionsinfo"
                      />
                    </td>
                    <td>
                      <Input type="text" fluid value={input.pensionsopsparing} className="form-control" placeholder="Indtast værdi"
                             name="pensionsopsparing" data-numeric={true} onChange={genericFieldHandler}/>
                    </td>
                  </tr>
                  <tr>
                    <td className="field">
                      <FormLabel
                          text="Din årlige indbetaling til pension (i kroner)"
                          helpText="Er du kunde i Letpension, kan du se dine årlige indbetalinger til pension i Letpension via din Netbank. Ellers kan du se dine indbetalinger via PensionsInfo. Hvis din pensionsopsparing bliver betalt via din arbejdsgiver, skal du indtaste indbetalingerne før betaling af arbejdsmarkedbidrag"
                      />
                    </td>
                    <td>
                      <Input type="text" fluid value={input.pensionindbetaling} className="form-control" placeholder="Indtast værdi"
                             name="pensionindbetaling" data-numeric={true} onChange={genericFieldHandler}/>
                    </td>
                  </tr>
                  <tr>
                    <td className="field">
                      <FormLabel
                          text="Din forventede pensionsalder"
                          helpText="Din forventede pensionsalder har sammen med din nuværende alder betydning for beregningen af investeringsomkostninger. Investeringsrisikoen i de fire investeringsprofiler i PFA Plus nedtrappes gradvist frem mod den forventede pensionsalder. Investeringsomkostningerne ændrer sig i takt med nedtrapningen"
                      />
                    </td>
                    <td>
                      <Select fluid name="forventetpensionsalder" onChange={genericFieldHandler} value={input.forventetpensionsalder}
                              options={muligePensionsAldre()}/>
                    </td>
                  </tr>
                  <tr>
                    <td className="field">
                      <FormLabel
                          text="Din alder"
                          helpText="Din nuværende alder har sammen med din forventede pensionsalder betydning for beregningen af investeringsomkostninger. Investeringsrisikoen i de fire investeringsprofiler i PFA Plus nedtrappes gradvist frem mod den forventede pensionsalder. Investeringsomkostningerne ændrer sig i takt med nedtrapningen"
                      />
                    </td>
                    <td>
                      <Select fluid name="alder" onChange={genericFieldHandler} value={input.alder}
                              options={muligePersonAldre()}/>
                    </td>
                  </tr>
                  <tr>
                    <td className="field">
                      <FormLabel
                          text="Din investeringsprofil"
                          helpText="Din opsparing kan blive investeret i en af de fire investeringsprofiler A, B, C eller D, hvor risikoen bliver nedtrappet frem mod den forventede pensionsalder, eller i Investeringsprofilen Valgfri, hvor du selv vælger din fordeling, og hvor sammensætningen er fast, indtil du vælger noget andet. Investeringsprofilen A har den laveste risiko og D har den højeste risiko"
                      />
                    </td>
                    <td>
                      <Select fluid name="investeringsprofil" onChange={genericFieldHandler} value={input.investeringsprofil}
                              options={[
                                {key: 'A', value: 'A', text: 'A - lav risiko'},
                                {key: 'B', value: 'B', text: 'B - lav risiko'},
                                {key: 'C', value: 'C', text: 'C - mellem risiko'},
                                {key: 'D', value: 'D', text: 'D - høj risiko'},
                                {key: 'Valgfri', value: 'Valgfri', text: 'Valgfri'},
                              ]
                              }/>
                    </td>
                  </tr>
                  {input.investeringsprofil === "Valgfri" &&
                  <tr>
                    <td className="field">
                      <label>Andel i Høj risiko fonden</label>
                    </td>
                    <td>
                      <Input fluid type="text" name="valgfriHoejAndel" data-numeric={true}
                             value={input.valgfriHoejAndel} placeholder="Indtast værdi"
                             onChange={genericFieldHandler}/>
                    </td>
                  </tr>
                  }
                  {input.investeringsprofil === "Valgfri" &&
                  <tr>
                    <td className="field">
                      <label>Andel i Lav risiko fonden</label>
                    </td>
                    <td>
                      <Input fluid type="text" value={100 - input.valgfriHoejAndel} readOnly/>
                    </td>
                  </tr>
                  }
                  <tr>
                    <td className="field">
                      <FormLabel
                          text="Din pensionsordning bliver betalt"
                          helpText="Marker om din pensionsordning bliver betalt via din arbejdsgiver eller privat. Bliver din pensionsopsparing betalt via din arbejdsgiver, bliver arbejdsmarkedsbidraget trukke fra den årlige forventede indbetaling"
                      />
                    </td>
                    <td>
                      <Form.Field>
                        <Radio
                            label='via arbejdsgiver'
                            name='tegningsmaade'
                            value='Arbejdsgiver'
                            checked={input.tegningsmaade === 'Arbejdsgiver'}
                            onChange={genericFieldHandler}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                            label='privat'
                            name='tegningsmaade'
                            value='Privat'
                            checked={input.tegningsmaade === 'Privat'}
                            onChange={genericFieldHandler}
                        />
                      </Form.Field>
                    </td>
                  </tr>


                  {udvidet &&
                  <tr>
                    <td className="field">
                      <label>Kundekapital</label>
                    </td>
                    <td>
                      <CompatibleCheckbox name="kundekapital" onChange={genericFieldHandler} checked={input.kundekapital === true}/>
                    </td>
                  </tr>
                  }
                  {udvidet &&
                  <tr>
                    <td className="field">
                      <label>Kundekapital beregnes af</label>
                    </td>
                    <td>
                      <Select fluid name="kundekapitalBeregnesKunAfIndbetalingen" onChange={genericFieldHandler}
                              value={input.kundekapitalBeregnesKunAfIndbetalingen}
                              options={[
                                {key: 1, value: true, text: 'Indbetalingen'},
                                {key: 0, value: false, text: 'Saldo og indbetaling'}
                              ]
                              }/>
                    </td>
                  </tr>
                  }
                  {udvidet &&
                  <tr>
                    <td className="field">
                      <label>Rabat på omkostning af betaling (0 - 100 %)</label>
                    </td>
                    <td>
                      <Input fluid name="betalingomkostningrabat"
                             value={input.betalingomkostningrabat}
                             data-numeric={true}
                             type="text" placeholder="Indtast værdi"
                             onChange={genericFieldHandler}/>
                    </td>
                  </tr>
                  }
                  {udvidet &&

                  <tr>
                    <td className="field">
                      <label>Rabat på omkostning af saldo</label>
                    </td>
                    <td>
                      <CompatibleCheckbox name="saldoOmkostningRabat" onChange={genericFieldHandler}
                                checked={input.saldoOmkostningRabat === true}/>
                    </td>
                  </tr>
                  }
                  </tbody>
                </table>
              </Form>
            </Grid.Column>

            <Grid.Column>
              <MiniBox>
                <h5>Direkte årlige omkostninger af</h5>
                <Table basic="very" compact="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>din betaling til dit pengeinstitut</Table.Cell>
                      <Table.Cell textAlign="right">{formatNumber(output.omkostningerAfDinBetalingTilDitPengeinstitut)} kr.</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>din saldo til dit pengeinstitut</Table.Cell>
                      <Table.Cell textAlign="right">{formatNumber(output.omkostningerAfDinSaldoTilPfaOgDitPengeinstitut)} kr.</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </MiniBox>

              <MiniBox>
                <h5>Indirekte årlige omkostninger</h5>
                <Table basic="very" compact="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Investeringsomkostninger til PFA</Table.Cell>
                      <Table.Cell textAlign="right">{formatNumber(output.investeringsOmkostninger)} kr.</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </MiniBox>

              <MiniBox>
                <h5>Standard årlige omkostninger</h5>
                <Table basic="very" compact="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>I kroner (ÅOK)</Table.Cell>
                      <Table.Cell textAlign="right">{formatNumber(output.standardAaok)} kr.</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>I procent (ÅOP)</Table.Cell>
                      <Table.Cell textAlign="right">{formatNumber(output.standardAaop, 2)} %</Table.Cell>
                    </Table.Row>
                    {!visberegningsdetaljer &&
                    <Table.Row>
                      <Table.Cell colSpan={2} style={{cursor: "pointer", color:"#1e677b"}} onClick={toggleDetaljer}><Icon color="blue" name="plus"/> Vis flere detaljer for
                        beregningen</Table.Cell>
                    </Table.Row>
                    }
                    {visberegningsdetaljer &&
                    <Fragment>
                      <Table.Row>
                        <Table.Cell colSpan={2} style={{cursor: "pointer", color:"#1e677b"}} onClick={toggleDetaljer}><Icon color="blue" name="minus"/> Vis færre detaljer for
                          beregningen</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Nuværende pensionsopsparing</Table.Cell>
                        <Table.Cell textAlign="right">{formatNumber(output.nuvaerendePensionsopsparing)} kr.</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Årlig indbetaling efter AMB</Table.Cell>
                        <Table.Cell textAlign="right">{formatNumber(output.aarligIndbetalingEfterAmb)} kr.</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Afkast før investeringsomkostninger og pal</Table.Cell>
                        <Table.Cell textAlign="right">{formatNumber(output.investeringsAfkast)} kr.</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Administrations- og investeringsomkostninger</Table.Cell>
                        <Table.Cell textAlign="right">-{formatNumber(output.administrationOgInvesteringsomkostninger)} kr.</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Pensionsafkastskat 15,3 %</Table.Cell>
                        <Table.Cell textAlign="right">-{formatNumber(output.pensionsafkastskat)} kr.</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Pensionsopsparing efter 1 år</Table.Cell>
                        <Table.Cell textAlign="right"><u>{formatNumber(output.pensionsopsparingEfter1Aar)} kr.</u></Table.Cell>
                      </Table.Row>
                    </Fragment>
                    }
                  </Table.Body>
                </Table>
              </MiniBox>

            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
  );
};

OmkostningberegnerPresentation.propTypes = {
  tablet: PropTypes.bool.isRequired,
  udvidet: PropTypes.bool.isRequired,
  visopsparingOvermaxModal: PropTypes.bool.isRequired,
  genericFieldHandler: PropTypes.func.isRequired,
  lukopsparingOvermaxModal: PropTypes.func.isRequired,
  input: PropTypes.shape({
    pensionsopsparing: PropTypes.string.isRequired,
    pensionindbetaling: PropTypes.string.isRequired,
    forventetpensionsalder: PropTypes.number.isRequired,
    alder: PropTypes.number.isRequired,
    investeringsprofil: PropTypes.string.isRequired,
    valgfriHoejAndel: PropTypes.string.isRequired,
    tegningsmaade: PropTypes.string.isRequired,
    kundekapital: PropTypes.bool.isRequired,
    kundekapitalBeregnesKunAfIndbetalingen: PropTypes.bool.isRequired,
    betalingomkostningrabat: PropTypes.isRequired,
    saldoOmkostningRabat: PropTypes.bool.isRequired
  }).isRequired,
  output: PropTypes.shape({
    omkostningerAfDinBetalingTilPFA: PropTypes.number,
    omkostningerAfDinBetalingTilDitPengeinstitut: PropTypes.number,
    omkostningerAfDinSaldoTilPfaOgDitPengeinstitut: PropTypes.number,
    investeringsOmkostninger: PropTypes.number,
    standardAaok: PropTypes.number,
    standardAaop: PropTypes.number,
    nuvaerendePensionsopsparing: PropTypes.number,
    aarligIndbetalingEfterAmb: PropTypes.number,
    investeringsAfkast: PropTypes.number,
    administrationOgInvesteringsomkostninger: PropTypes.number,
    pensionsafkastskat: PropTypes.number,
    pensionsopsparingEfter1Aar: PropTypes.number
  }).isRequired
};

export default OmkostningberegnerPresentation;
