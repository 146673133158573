import ApiUtils from '../../util/ApiUtils.js'
import AppConfig from '../../app-config';
//import mockOmkostninger from '../../data/omkostninger.mock.json';

export const BEREGNER_OMKOSTNINGER = 'BEREGNER_OMKOSTNINGER';
export const MODTAG_OMKOSTNINGER = 'MODTAG_OMKOSTNINGER';
export const NULSTIL_OMKOSTNINGER = 'NULSTIL_OMKOSTNINGER';


function initierOmkostningBeregning() {
  return {
    type: BEREGNER_OMKOSTNINGER
  }
}

function modtagOmkostninger(state, json, fejl) {
  return {
    type: MODTAG_OMKOSTNINGER,
    omkostninger: json,
    receivedAt: Date.now(),
    fejlmeddelelse: fejl
  }
}


export function beregnOmkostninger(omkostningData) {

  return (dispatch, getState) => {

    dispatch(initierOmkostningBeregning());
    let url = AppConfig.apiRoot + 'omkostninger';

    return fetch(url,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(omkostningData)
        },
    )
        .then(ApiUtils.checkStatus)
        .then(response =>
                response.json()
                    .then(json => {
                      dispatch(modtagOmkostninger(getState(), json), null)
                    })
            , error => {
              dispatch(modtagOmkostninger(getState(), null, 'Fejl ved beregning af omkostninger'))
            })
  }
}
