import React, {useState} from "react";
import {Accordion, Icon} from "semantic-ui-react";
import PropTypes from "prop-types";

const AutoAccordionItem = () => null;

AutoAccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

const AutoAccordion = props => {
  const {children} = props;
  const [activeIndex, setActiveIndex] = useState(props.activeIndex);

  const handleClick = (e, titleProps) => {
    const {index} = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
      <Accordion fluid styled>
        {
          React.Children.map(children, (item, itemIndex) => {
            return (
                <div key={itemIndex}>
                  <Accordion.Title active={activeIndex === itemIndex} index={itemIndex} onClick={handleClick}>
                    <Icon name='dropdown'/>{item.props.title}
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === itemIndex}>
                    {item.props.children}
                  </Accordion.Content>
                </div>
            )
          })
        }
      </Accordion>
  );
}

AutoAccordion.Item = AutoAccordionItem;

AutoAccordion.propTypes = {
  activeIndex: PropTypes.number
};

AutoAccordion.defaultProps = {
  activeIndex: 0
};

export default AutoAccordion;

