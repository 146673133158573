import React from 'react';
import {Container, Divider, Header, Icon, Loader, Table} from "semantic-ui-react";
import {useDispatch, useSelector} from 'react-redux';
import {indlaesJobopslag} from '../../redux/actions/jobopslag.js';
import withSizes from 'react-sizes';

const mapSizesToProps = ({width}) => ({
    lilleSkaerm: width < 480,
});

const Jobopslag = props => {

    const data = useSelector(state => state.jobopslag);
    const dispatch = useDispatch();

    if (!data.indlaest && !data.indlaeser)
        dispatch(indlaesJobopslag());

    return (
        <div>
            <Header as="h2" textAlign="center">Job hos Letpension</Header>
            <Divider hidden/>
            <Container text>
                <p>Vi er altid interesserede i at høre fra dynamiske og kompetente mennesker, der kan og vil gøre en
                    forskel. Det er vigtig, at du
                    kan håndtere store og små opgaver med lige dele menneskelig åbenhed og professionelt overskud i en
                    hverdag præget af dynamik og innovation.</p>
                <p>Send dit CV og en motiveret ansøgning til os på <a className="link"
                                                                      href="mailto:letpension@letpension.dk">letpension@letpension.dk</a>.
                    Vi
                    glæder os til at høre fra dig.</p>
                <p>Hvis der er ledige stillinger i Letpension, kan du se dem her.</p>

            <Loader active={data.indlaeser} style={{top: 350}}/>
            {data.jobopslag &&
            <JobOpslagTabel jobOpslag={data.jobopslag}/>}
            </Container>
        </div>
    );
}

const JobOpslagTabel = props => {
    const {jobOpslag} = props;
    let JobOpslagTabelRaekker = null;
    if (jobOpslag && jobOpslag.map) {
        JobOpslagTabelRaekker = jobOpslag.map(function (jobOpslagModel) {
            return (
                <JobOpslagTabelRaekke jobOpslag={jobOpslagModel} key={jobOpslagModel.raekkeFoelge}/>
            );
        });
    }

    return (
        <Table color="teal" inverted compact striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Ledige stillinger i letpension</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {JobOpslagTabelRaekker}
            </Table.Body>
        </Table>
    );
}

const JobOpslagTabelRaekke = props => {
    const {jobOpslag} = props;
    const joblink = () => {
        if (jobOpslag.jobLink) {
            return <a href={jobOpslag.jobLink} target="_blank" rel="noopener noreferrer" className="link" style={{color: 'black'}}>{jobOpslag.beskrivelse}&nbsp;<Icon size="small" name="arrow right"/></a>;
        }
        return jobOpslag.beskrivelse || "";
    }

    return (
        <Table.Row>
            <Table.Cell>{joblink()}</Table.Cell>
        </Table.Row>
    );
}


export default withSizes(mapSizesToProps)(Jobopslag);
