import React from "react";
import {Divider, Grid} from "semantic-ui-react";
import Forsidebillede from "../images/Forsidebillede.jpg";
import FindPengeinstitut from "../images/IkonHerfaarDuLetsikringer.svg";
import BrugDinForsikring from "../images/IkonBrugDinForsikring.svg";
import TjekDinPension from "../images/IkonTjekDinPension.svg";
import appRoutes from "../routes/appRoutes";
import CallForAction from "./common/CallForAction";
import HeaderImage from "./common/HeaderImage";

const Forside = props => (
    <div>
        <HeaderImage imageSrc={Forsidebillede} trompet="Pension og forsikring, der er til at forstå"
                     text="Skræddersy din pension og forsikring med din rådgiver i dit pengeinstitut"/>

        <Divider hidden/>
        <Divider hidden/>

        <Grid centered stackable columns={2}>
            <Grid.Row>
                <Grid.Column>
                    <p>Letpension er pensions- og forsikringsformidler, som formidler pensions- og forsikringsløsninger
                        for
                        PFA
                        Pension via en række pengeinstitutter med filialer i hele Danmark.</p>
                </Grid.Column>
                <Grid.Column>
                    <p>Derfor møder du Letpension i dit lokale pengeinstitut. Her er det din egen personlige rådgiver,
                        der
                        sammen
                        med dig skræddersyr din pension og
                        forsikring.</p>
                </Grid.Column>
            </Grid.Row>
        </Grid>


        <Divider hidden/>
        <Divider hidden/>
        <Divider hidden/>

        <Grid centered stackable columns={3}>
            <Grid.Row>
                <CallForAction icon={FindPengeinstitut} color="#e49d3b" text="Her får du Letsikringer"
                               link={appRoutes.BLIV_KUNDE}/>
                <CallForAction icon={BrugDinForsikring} color="#6bb8bc" text="Brug din forsikring"
                               link={appRoutes.BRUG_DIN_FORSIKRING}/>
                <CallForAction icon={TjekDinPension} color="#b9c35d" text="Tjek din pension"
                               link={appRoutes.TJEK_DIN_PENSION}/>
            </Grid.Row>
        </Grid>

        <Divider hidden/>
        <Divider hidden/>

    </div>
);

export default Forside;
