import React from "react";
import {Divider, Grid, Header, Responsive, Tab} from "semantic-ui-react";
import FindPengeinstitut from "../../images/IkonHerfaarDuLetsikringer.svg";
import TjekDinPension from "../../images/IkonTjekDinPension.svg";
import withSizes from "react-sizes";
import appRoutes from "../../routes/appRoutes";
import OmkostningsberegnerContainer from "./OmkostningsberegnerContainer";
import { useSelector } from 'react-redux';
import { formatNumber, formatDate } from '../../util/formatUtils';
import CallForAction from "../common/CallForAction";

const mapSizesToProps = ({width}) => ({
    tablet: width < Responsive.onlyTablet.minWidth,
});

const Omkostninger = props => {
    const {tablet, maxDepotVardi, udvidet} = props;
    const satser = useSelector(state => state.satser.satser)
    if (!satser)
        return null;

    const indevaerendeAar = satser.indevaerendeAar;
    const naesteAar = satser.naesteAar;
    const omkRabatEkstraGraense = satser.omkRabatEkstraGraense;

    return (
        <div>
            <Header as="h2" textAlign="center">Få overblik over dine omkostninger</Header>
            <Divider hidden/>
            <p>Med omkostningsberegneren får du et hurtigt overblik over dine årlige omkostninger
                ved at have en Letsikring af indtægt ved pension. Du kan også læse om forudsætningerne for
                omkostningsberegneren, få en forklaring på, hvad ÅOK/ÅOP er,
                samt et overblik over vores gebyrer.</p>
            <p>Har du allerede produkter via Letpension, kan du se omkostningerne for netop din Letsikring i Letportalen
                ved at bruge "Log ind" i øverste højre hjørne
                her på
                siden eller via din netbank.</p>

            <Divider section hidden/>
            <Tab
                panes={
                    [
                        {
                            menuItem: {
                                icon: "calculator",
                                content: (tablet ? null : "Omkostningsberegner"),
                                key: "omk"
                            }, render: () =>
                                <Tab.Pane>
                                    <OmkostningsberegnerContainer maxDepotVardi={maxDepotVardi} udvidet={udvidet}/>
                                </Tab.Pane>
                        },
                        {
                            menuItem: {icon: "file text", content: (tablet ? null : "Forudsætninger"), key: "foruds"},
                            render: () =>
                                <Tab.Pane>
                                    <Grid>
                                        <Grid.Row columns={(tablet ? 1 : 2)}>
                                            <Grid.Column>
                                                <h4>Forudsætninger for omkostningsberegneren</h4>
                                                <p>Beregningen af de årlige omkostninger for en Letsikring af indtægt
                                                    ved pension er vejledende. Resultatet er altså ikke udtryk
                                                    for
                                                    en præcis opgørelse af de faktiske omkostninger.</p>
                                                <p>Det forudsættes, at de indbetalinger du indtaster, alene vedrører
                                                    opsparing og at indbetalingerne sker i starten af året. Det
                                                    er
                                                    ikke muligt at foretage beregninger for Letsikringer, der bliver
                                                    udbetalt penge fra.</p>
                                                <p>Det forudsættes desuden,
                                                    at en del af opsparingen er
                                                    placeret i PFA KundeKapital. Du kan læse mere om PFA KundeKapital <a
                                                        href="https://pfa.dk/privat/opsparing/pfa-kundekapital/"
                                                        target="_blank" rel="noopener noreferrer">her</a>.</p>

                                                <h4>Arbejdsgiver eller privat</h4>
                                                <p>Sker dine indbetalinger via en arbejdsgiver, bliver
                                                    arbejdsmarkedsbidraget på {formatNumber((1 - satser.ambSats) * 100)} %
                                                    trukket fra i dine indbetalinger.
                                                    Indbetaler du
                                                    pengene privat, bliver arbejdsmarkedsbidraget ikke trukket fra i
                                                    dine indbetalinger, fordi du allerede har betalt
                                                    arbejdsmarkedsbidrag af pengene.</p>

                                                <h4>Direkte omkostninger</h4>
                                                <p>De direkte omkostninger består af en fast betaling i kroner, en
                                                    procent af indbetalingen og en procent af opsparingen.</p>
                                                <p>Omkostningerne bliver beregnet ud fra startsaldoen og en forventning
                                                    om, at indbetalingen sker i starten af året og afkastet
                                                    tilskrives sidst på året. Derfor kan der være marginale udsving,
                                                    hvis din indbetaling eller dit afkast afviger fra dette.</p>

                                                <h4>Indirekte omkostninger</h4>
                                                <p>De indirekte omkostninger bliver fastlagt ud fra den valgte
                                                    investeringsprofil og afhænger af, hvor lang tid der er, til du
                                                    går
                                                    på pension.</p>
                                                <p>Omkostningerne bliver beregnet ud fra startsaldoen og en forventning
                                                    om, at indbetalingen sker i starten af året og afkastet
                                                    tilskrives sidst på året. Derfor kan der være marginale udsving,
                                                    hvis din indbetaling eller dit afkast afviger fra dette.</p>

                                                <h4>Sådan beregner vi afkast</h4>
                                                <p>Til at beregne afkastet på opsparingen bruger vi de forventede afkast
                                                    1. halvår {indevaerendeAar} fra de fælles samfunds&shy;forud&shy;sætninger
                                                    der er fastsat af Finans Danmark og Forsikring & Pension. Derfor
                                                    afhænger afkastet af den valgte
                                                    investeringsprofil og hvor lang tid der er, til du går på
                                                    pension. På den del af saldoen, der er placeret i KundeKapital*,
                                                    regner vi med et forventet afkast
                                                    i {indevaerendeAar} på {formatNumber(satser.kundeKapitalAfkastPct * 100)} %
                                                    efter
                                                    indirekte omkostninger. Forrentningen af Kundekapital
                                                    for {indevaerendeAar} tilskrives i foråret {naesteAar} og kun til
                                                    policer, der fortsat er i kraft.</p>
                                                <p style={{fontStyle: 'italic'}}>*) PFA KundeKapital er PFA Pensions
                                                    model for overskuds- og risikodeling og indgår i
                                                    kapitalgrundlaget for PFA Pension og får
                                                    andel i overskuddet på linje med egenkapitalen, men skal også dække
                                                    eventuelle tab sammen med egenkapitalen. Det betyder, at
                                                    KundeKapital kan blive mindre og i yderste konsekvens forsvinde.
                                                    Forrentningen af PFA KundeKapital fastsættes årligt af PFA
                                                    Pension. Estimeret forrentning af KundeKaptial er derfor helt
                                                    ugaranteret og kan blive lavere, allerede fra {indevaerendeAar}.</p>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <h4>Omkostninger i kroner og ører</h4>
                                                <p>Har du en Letsikring af indtægt ved pension, betaler du omkostninger
                                                    af den del af opsparingen som er placeret i dit
                                                    investeringsvalg, men du betaler ikke omkostninger af den del af din
                                                    opsparing der er placeret i PFA KundeKapital.</p>
                                                <p>Af den del af saldoen der er placeret i dit investeringsvalg, betaler
                                                    du {formatNumber(satser.saldoOmkostningNormal_UG1 * 100, 2)} % af
                                                    opsparingen hvert år. Hvis din saldo er
                                                    over {formatNumber(satser.opsparetVaerdiGraense_G1)} kr. betaler du
                                                    dog kun {formatNumber(satser.saldoOmkostningNormal_UG2 * 100, 2)} %
                                                    af den del der
                                                    overstiger {formatNumber(satser.opsparetVaerdiGraense_G1)} kr. og
                                                    hvis din saldo er
                                                    over {formatNumber(satser.opsparetVaerdiGraense_G2)} kr. så betaler
                                                    du kun {formatNumber(satser.saldoOmkostningNormal_OG2 * 100, 2)} %
                                                    af den del der
                                                    overstiger {formatNumber(satser.opsparetVaerdiGraense_G2)} kr.</p>
                                                <p>Det forudsættes, at du har valgt at sikre din opsparing, så den
                                                    kommer til udbetaling, hvis du dør, før du begynder at få
                                                    udbetaling fra din Letsikring. Denne sikring betaler du et mindre
                                                    beløb for.</p>

                                                <h4>Investeringsomkostninger (indirekte omkostninger)</h4>
                                                <p>Udover de nævnte omkostninger, er der knyttet omkostninger til
                                                    investering af opsparingen.</p>
                                                <p>De nuværende årlige investeringsomkostninger udgør:</p>
                                                <ul>
                                                    <li>{formatNumber((satser.omkostningLavRisiko - satser.omkRabatLavRisikoFond) * 100, 2)} %
                                                        af opsparingen per år for den del, der er investeret i Lav
                                                        risiko fonden.
                                                    </li>
                                                    <li>{formatNumber((satser.omkostningHoejRisiko - satser.omkRabatHoejRisikoFond) * 100, 2)} %
                                                        af opsparingen per år for den del, der er investeret i Høj
                                                        risiko fonden.
                                                    </li>
                                                    <li>{formatNumber((satser.omkostningKlimaLavRisiko - satser.omkRabatKlimaLavRisikoFond) * 100, 2)} %
                                                        af opsparingen per år for den del, der er investeret i
                                                        Klima Lav risiko fonden.
                                                    </li>
                                                    <li>{formatNumber((satser.omkostningKlimaHoejRisiko - satser.omkRabatKlimaHoejRisikoFond) * 100, 2)} %
                                                        af opsparingen per år for den del, der er investeret i
                                                        Klima Høj risiko fonden.
                                                    </li>
                                                    <li>{formatNumber((satser.omkostningVarighedsFond) * 100, 2)} % af
                                                        opsparingen per år for den del, der er investeret i
                                                        Varighedsfondene.
                                                    </li>
                                                    <li>{formatNumber(satser.kundeKapitalVaerdiAaop * 100, 2)} % af
                                                        opsparingen per år for den del, der er investeret i PFA
                                                        KundeKapital.
                                                    </li>
                                                </ul>
                                                <p>Investeringsomkostningerne bliver fratrukket, inden du får afkast fra
                                                    fondene.</p>
                                                <p>I ovenstående satser er fratrukket en omkostningskorrektion, som
                                                    Letpension har forhandlet med PFA. Denne korrektion tilskrives dit
                                                    depot ultimo året. Omkostningskorrektionen er indregnet i
                                                    omkostningsberegneren.</p>
                                                <p>Satserne kan løbende ændres.</p>
                                                <p>
                                                    <a href="/blivkunde" target="_blank" rel="noopener noreferrer">Satserne er gældende for disse pengeinstitutter</a>
                                                </p>
                                                <p>Hvis og så længe din opsparing inkl. KundeKapital er over {formatNumber(omkRabatEkstraGraense)} kr.
                                                    får du en ekstra omkostningsreduktion på {formatNumber(satser.omkRabatEkstraHoejRisikoFond * 100,2)} procentpoint af hele opsparingen placeret i høj eller lav risiko fondene.</p>

                                                <p>Efter den ekstra omkostningskorrektion udgør de nuværende årlige
                                                    investeringsomkostninger:</p>
                                                <ul>
                                                    <li>{formatNumber((satser.omkostningLavRisiko - satser.omkRabatLavRisikoFond - satser.omkRabatEkstraLavRisikoFond) * 100, 2)} %
                                                        af opsparingen per år for den del, der er investeret i Lav
                                                        risiko fonden.
                                                    </li>
                                                    <li>{formatNumber((satser.omkostningHoejRisiko - satser.omkRabatHoejRisikoFond - satser.omkRabatEkstraHoejRisikoFond) * 100, 2)} %
                                                        af opsparingen per år for den del, der er investeret i Høj
                                                        risiko fonden.
                                                    </li>
                                                    <li>{formatNumber((satser.omkostningKlimaLavRisiko - satser.omkRabatKlimaLavRisikoFond - satser.omkRabatEkstraLavRisikoFond) * 100, 2)} %
                                                        af opsparingen per år for den del, der er investeret i
                                                        Klima Lav risiko fonden.
                                                    </li>
                                                    <li>{formatNumber((satser.omkostningKlimaHoejRisiko - satser.omkRabatKlimaHoejRisikoFond - satser.omkRabatEkstraHoejRisikoFond) * 100, 2)} %
                                                        af opsparingen per år for den del, der er investeret i
                                                        Klima Høj risiko fonden.
                                                    </li>
                                                    <li>{formatNumber((satser.omkostningVarighedsFond) * 100, 2)} % af
                                                        opsparingen per år for den del, der er investeret i
                                                        Varighedsfondene.
                                                    </li>
                                                    <li>{formatNumber(satser.kundeKapitalVaerdiAaop * 100, 2)} % af
                                                        opsparingen per år for den del, der er investeret i PFA
                                                        KundeKapital.
                                                    </li>
                                                </ul>

                                                <p>I omkostningsberegneren er det simplificeret så opsparingen er
                                                    kvalificeret til den ekstra omkostningskorrektion på
                                                    investeringsomkostningerne såfremt følgende model giver
                                                    over {formatNumber(omkRabatEkstraGraense)} kr.:</p>
                                                <p><i>Nuværende pensionsopsparing + ½ * årlig indbetaling efter AMB og
                                                    indbetalingsomkostning + ½ * afkast før investeringsomkostninger og
                                                    PAL-skat.</i></p>
                                                <p>Omkostningsberegneren er opdateret pr. {satser.omkostningerGebyrerStartDato != null ? formatDate(satser.omkostningerGebyrerStartDato) : "1. januar 2024"}.</p>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Tab.Pane>
                        },
                        {
                            menuItem: {icon: "percent", content: (tablet ? null : "Hvad er ÅOK/ÅOP"), key: "aok"},
                            render: () =>
                                <Tab.Pane>
                                    <Grid>
                                        <Grid.Row columns={(tablet ? 1 : 2)}>
                                            <Grid.Column>
                                                <h4>Årlige omkostninger i kroner</h4>
                                                <p>ÅOK er en forkortelse for "årlige omkostninger i kroner". Tallet er
                                                    udtryk for de årlige omkostninger, du betaler for at have
                                                    din
                                                    Letsikring - angivet i kroner.</p>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <h4>Årlige omkostninger i procent</h4>
                                                <p>ÅOP er en forkortelse for "årlige omkostninger i procent". Tallet er udtryk for de årlige omkostninger i procent, du betaler for at have din Letsikring og bliver beregnet som de årlige omkostninger i kroner (ÅOK) divideret med opsparingens størrelse efter et år.</p>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Tab.Pane>
                        },
                        {
                            menuItem: {icon: "dollar", content: (tablet ? null : "Gebyrer"), key: "gebyr"},
                            render: () =>
                                <Tab.Pane>
                                    <Grid>
                                        <Grid.Row columns={(tablet ? 1 : 2)}>
                                            <Grid.Column>
                                                <h4>Engangsomkostninger</h4>
                                                <p>Nogle ændringer af Letsikringer falder ikke under de løbende direkte
                                                    omkostninger, du betaler for din Letsikring. For sådanne
                                                    ændringer betaler du derfor omkostningerne enkeltvis. Det drejer sig
                                                    om:</p>
                                                <ul>
                                                    <li>Omkostning ved overførsel til anden
                                                        leverandør: {formatNumber(satser.omkOverfoerselAndenLeverandoer)} kr.
                                                    </li>
                                                    <li>Omkostning ved udbetaling af opsparing før tid
                                                        (ophævelse): {formatNumber(satser.omkUdbetalingOpsparingIUtide)} kr.
                                                        Derudover skal du betale en afgift til staten på 60
                                                        %.
                                                    </li>
                                                    <li>Omkostning ved ændringer, der kræver komplekse
                                                        beregninger: {formatNumber(satser.omkAendringerMedKomplekseBeregninger)} kr.*
                                                    </li>
                                                    <li>Omkostning ved ændring af sikring for
                                                        udbetaling: {formatNumber(satser.omkAendringSikringUdbetaling)} kr.
                                                    </li>
                                                    <li>Omkostning ved ændring af investeringsprofil og andel af
                                                        klima: {formatNumber(satser.omkAendringInvesteringsprofil)} kr.**
                                                    </li>
                                                    <li>Omkostning ved tilvalg eller fravalg af
                                                        ægtefælledækning: {formatNumber(satser.omkAendringAegtefaelledaekning)} kr.
                                                    </li>
                                                    <li>Omkostning ved ændring af valg af
                                                        KundeKapital: {formatNumber(satser.omkAendringKundeKapital)} kr.
                                                    </li>
                                                    <li>Omkostning ved indbetalingsfri ordninger 0 kr.</li>
                                                </ul>
                                                <p><i>* Omfatter for eksempel særlige prognoseberegninger i forbindelse
                                                    med pensionering, hvis det ikke er muligt at imødekomme kundens
                                                    ønsker i Rådgiverportalen.</i></p>
                                                <p><i>** Kunder, der ændrer deres investeringssammensætning mere end tre gange
                                                    inden for samme måned, bliver pålagt et ekstra gebyr for hver efterfølgende ændring på 0,5 % af den omlagte opsparing eksklusive PFA KundeKapital.</i></p>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Tab.Pane>
                        },
                    ]
                }
            />

            <Divider section hidden/>

            <Grid centered stackable columns={2}>
                <Grid.Row>
                    <CallForAction icon={FindPengeinstitut} color="#e49d3b" text="Her får du Letsikringer"
                                   link={appRoutes.BLIV_KUNDE}/>
                    <CallForAction icon={TjekDinPension} color="#b9c35d" text="Tjek din pension"
                                   link={appRoutes.TJEK_DIN_PENSION}/>
                </Grid.Row>
            </Grid>

        </div>
    );
}

export default withSizes(mapSizesToProps)(Omkostninger);
