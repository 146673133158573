import React from "react";
import {Divider, Grid, Header} from "semantic-ui-react";
import LetsikringVedSygdomImg from "../../images/letsikringvedsygdom.jpg";
import LetsikringVedKritiskSygdomImg from "../../images/letsikringvedkritisksygdom.jpg";
import LetsikringAfIndtagtVedPensionImg from "../../images/letsikringafindtaegtvedpension.jpg";
import LetsikringAfBarnVedDoed from "../../images/letsikringafbarnveddoed.jpg";
import LetsikringVedDoed from "../../images/letsikringveddoed.jpg";
import ImageLink from "../common/ImageLink";
import appRoutes from "../../routes/appRoutes";
import LetsikringAfHelbredImg from '../../images/letsikringafhelbred.jpg';

const Produktet = props => (
    <div>
      <Header as="h2" textAlign="center">Få rådgivning i dit pengeinstitut</Header>
      <Divider hidden/>
      <Grid centered stackable columns={3}>
        <Grid.Row>
          <ImageLink imageSrc={LetsikringVedSygdomImg} text="Letsikring ved sygdom" link={appRoutes.LETSIKRING_VED_SYGDOM}/>
          <ImageLink imageSrc={LetsikringVedKritiskSygdomImg} text="Letsikring ved kritisk sygdom" link={appRoutes.LETSIKRING_VED_KRITISK_SYGDOM}/>
          <ImageLink imageSrc={LetsikringAfIndtagtVedPensionImg} text="Letsikring af indtægt ved pension" link={appRoutes.LETSIKRING_AF_INDTAEGT_VED_PENSION}/>
        </Grid.Row>
        <Grid.Row>
          <ImageLink imageSrc={LetsikringAfBarnVedDoed} text="Letsikring af barn" link={appRoutes.LETSIKRING_AF_BARN_VED_DOED}/>
          <ImageLink imageSrc={LetsikringVedDoed} text="Letsikring ved død" link={appRoutes.LETSIKRING_VED_DOED}/>
          <ImageLink imageSrc={LetsikringAfHelbredImg} text="Letsikring af helbred" link={appRoutes.LETSIKRING_AF_HELBRED}/>
        </Grid.Row>
      </Grid>

    </div>
);

export default Produktet;
