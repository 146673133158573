import React from "react";
import {Container, Divider, Grid, Header} from "semantic-ui-react";
import LetsikringAfIndtagtVedPensionImg from "../../images/letsikringafindtaegtvedpension.jpg";
import ImageLink from "../common/ImageLink";
import LetsikringVedSygdomImg from "../../images/letsikringvedsygdom.jpg";
import LetsikringVedKritiskSygdomImg from "../../images/letsikringvedkritisksygdom.jpg";
import LetsikringAfBarnVedDoed from "../../images/letsikringafbarnveddoed.jpg";
import appRoutes from "../../routes/appRoutes";
import {Link} from "react-router-dom";

const LetsikringVedDoed = props => (
    <div>
        <Header as="h2" textAlign="center">Letsikring ved død</Header>
        <Divider hidden/>
        <Container text>

            {/*<Container text>*/}
            {/*  <Embed id="316497492" source="vimeo" active={true} autoplay={false}/>*/}
            {/*</Container>*/}
            <Divider hidden/>
            <p>Med en Letsikring ved død kan du sikre dine efterladte, hvis du dør, inden du fylder 67 år.</p>
            <p>En Letsikring ved død sikrer, at dine efterladte får udbetalt penge enten én gang eller hver måned i 10
                år, hvis du dør, inden du fylder 67 år.</p>
            <p>Du vælger selv, hvem der skal have pengene udbetalt. Du kan for eksempel også vælge at oprette
                Letsikringen sådan, at udbetalingen bliver brugt til at
                dække et lån i dit pengeinstitut.</p>
            <p><Link to="/blivkunde" className="link">Se hvilke pengeinstitutter, der tilbyder Letsikringer</Link></p>
        </Container>
        <Divider section hidden/>

        <Grid centered stackable columns={2}>
            <Grid.Row>
                <ImageLink imageSrc={LetsikringVedKritiskSygdomImg} text="Letsikring ved kritisk sygdom"
                           link={appRoutes.LETSIKRING_VED_KRITISK_SYGDOM}/>
                <ImageLink imageSrc={LetsikringAfIndtagtVedPensionImg} text="Letsikring af indtægt ved pension"
                           link={appRoutes.LETSIKRING_AF_INDTAEGT_VED_PENSION}/>
            </Grid.Row>
            <Grid.Row>
                <ImageLink imageSrc={LetsikringAfBarnVedDoed} text="Letsikring af barn"
                           link={appRoutes.LETSIKRING_AF_BARN_VED_DOED}/>
                <ImageLink imageSrc={LetsikringVedSygdomImg} text="Letsikring ved sygdom"
                           link={appRoutes.LETSIKRING_VED_SYGDOM}/>
            </Grid.Row>
        </Grid>

    </div>
);

export default LetsikringVedDoed;
