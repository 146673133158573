import {HENT_BESTYRELSE, INVALIDER_BESTYRELSE, MODTAG_BESTYRELSE,} from '../actions/bestyrelse.js'


export default function bestyrelse(
    state = {
      indlaeser: false,
      indlaest: false,
      invalideret: false,
      fejlmeddelelse: null,
      medlemmer: null
    },
    action
) {
  switch (action.type) {
    case HENT_BESTYRELSE:
      return Object.assign({}, state, {
        indlaeser: true,
        indlaest: false,
        invalideret: false,
        fejlmeddelelse: null,
        medlemmer: null,
      })
    case MODTAG_BESTYRELSE:
      return Object.assign({}, state, {
        indlaeser: false,
        indlaest: true,
        invalideret: false,
        fejlmeddelelse: action.fejlmeddelelse,
        medlemmer: action.medlemmer
      })
    case INVALIDER_BESTYRELSE:
      return Object.assign({}, state, {
        indlaeser: false,
        indlaest: false,
        fejlmeddelelse: null,
        invalideret: true,
        medlemmer: null
      })
    default:
      return state;
  }
}


