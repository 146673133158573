import { combineReducers } from 'redux'
import medarbejdere from './medarbejdere.js'
import bestyrelse from './bestyrelse.js'
import jobopslag from './jobopslag.js'
import nyheder from './nyheder.js'
import omkostninger from './omkostninger.js'
import satser from './satser';

const rootReducer = combineReducers({
  medarbejdere,
  bestyrelse,
  jobopslag,
  nyheder,
  omkostninger,
  satser
});

export default rootReducer
