import React from "react";
import {Container, Divider, Grid, Header} from "semantic-ui-react";
import LetsikringVedKritiskSygdomImg from "../../images/letsikringvedkritisksygdom.jpg";
import LetsikringAfIndtagtVedPensionImg from "../../images/letsikringafindtaegtvedpension.jpg";
import LetsikringAfBarnVedDoed from "../../images/letsikringafbarnveddoed.jpg";
import LetsikringVedDoed from "../../images/letsikringveddoed.jpg";
import ImageLink from "../common/ImageLink";
import appRoutes from "../../routes/appRoutes";
import {useSelector} from "react-redux";
import {formatNumber} from "../../util/formatUtils";
import {Link} from "react-router-dom";

const LetsikringVedSygdom = props => {
    const satser = useSelector(state => state.satser.satser)

    if (!satser)
        return null;

    return (
        <div>
            <Header as="h2" textAlign="center">Letsikring ved sygdom</Header>
            <Divider hidden/>
            <Container text>

                {/*<Container text>*/}
                {/*    <Embed id="316497086" source="vimeo" active={true} autoplay={false}/>*/}
                {/*</Container>*/}
                <Divider hidden/>
                <p>Med en Letsikring ved sygdom kan du sikre dig selv, hvis du bliver syg eller kommer ud for en ulykke
                    og
                    dermed mister din indtægt.</p>
                <p>En Letsikring ved sygdom sikrer dig penge, hvis du mister halvdelen af din arbejdsevne og indtægt i
                    mere
                    end tre måneder. Udbetalingen er uafhængig af
                    myndighedernes vurdering af din sygdom.</p>
                <p>Du vælger selv, hvor meget af din indtægt, du vil have dækket - dog højst 80 % af din bruttoindkomst
                    ved
                    personligt arbejde og maksimalt {formatNumber(satser.letsikringAfSygdom67)} kr. om
                    året ({satser.indevaerendeAar}).</p>
                <p>Har du en Letsikring ved sygdom, har du også adgang til EarlyCare,
                    som er professionel hjælp og rådgivning, hvis du bliver sygemeldt mere end 14 dage.</p><br/>

                <p><b>EarlyCare</b></p>
                <p>Vidste du, at du via din Letsikring ved sygdom har du adgang til EarlyCare?</p>
                <p>EarlyCare giver dig mulighed for at få professionel hjælp og rådgivning allerede fra første
                    sygedag, hvis du skulle blive sygemeldt i mere end 14 dage.</p>
                <p>Vi ved, at en tidlig og fokuseret støtte er det mest effektive værn mod langvarig sygdom. Derfor
                    sidder faguddannet sundhedspersonale klar til at tale med dig, hvis du bliver syg. De er
                    uddannet til at vurdere, om der er behov for at sammensætte et behandlingsforløb til dig, så du
                    får de bedste muligheder for at komme godt tilbage til arbejdslivet.</p>

                <p><Link to="/blivkunde" className="link">Se hvilke pengeinstitutter, der tilbyder Letsikringer</Link></p>

            </Container>
            <Divider section hidden/>

            <Grid centered stackable columns={2}>
                <Grid.Row>
                    <ImageLink imageSrc={LetsikringVedKritiskSygdomImg} text="Letsikring ved kritisk sygdom"
                               link={appRoutes.LETSIKRING_VED_KRITISK_SYGDOM}/>
                    <ImageLink imageSrc={LetsikringAfIndtagtVedPensionImg} text="Letsikring af indtægt ved pension"
                               link={appRoutes.LETSIKRING_AF_INDTAEGT_VED_PENSION}/>
                </Grid.Row>
                <Grid.Row>
                    <ImageLink imageSrc={LetsikringAfBarnVedDoed} text="Letsikring af barn"
                               link={appRoutes.LETSIKRING_AF_BARN_VED_DOED}/>
                    <ImageLink imageSrc={LetsikringVedDoed} text="Letsikring ved død"
                               link={appRoutes.LETSIKRING_VED_DOED}/>
                </Grid.Row>
            </Grid>

        </div>
    )
};

export default LetsikringVedSygdom;
