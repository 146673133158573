import ApiUtils from '../../util/ApiUtils.js'
import AppConfig from '../../app-config';
// import mockMedarbejdere from '../../data/medarbejdere.mock';

export const HENT_MEDARBEJDERE = 'HENT_MEDARBEJDERE';
export const MODTAG_MEDARBEJDERE = 'MODTAG_MEDARBEJDERE';
export const INVALIDER_MEDARBEJDERE = 'INVALIDER_MEDARBEJDERE';


export function hentMedarbejdere() {
  return {
    type: HENT_MEDARBEJDERE
  }
}

function modtagMedarbejdere(state, json, fejl) {
  return {
    type: MODTAG_MEDARBEJDERE,
    medarbejdere: json,
    receivedAt: Date.now(),
    fejlmeddelelse: fejl
  }
}


export function indlaesMedarbejdere() {

  return (dispatch, getState) => {

    dispatch(hentMedarbejdere());
    let url = AppConfig.apiRoot + 'medarbejdere';

    // dispatch(modtagMedarbejdere(getState(), mockMedarbejdere), null);

    return fetch(url,
        {
          method: 'GET'
        },
    )
        .then(ApiUtils.checkStatus)
        .then(response =>
                response.json()
                    .then(json => {
                      dispatch(modtagMedarbejdere(getState(), json), null)
                    })
            , error => {
              dispatch(modtagMedarbejdere(getState(), null, 'Fejl ved indlæsning af medarbejdere'));
            })
  }
}


