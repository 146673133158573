import React from "react";
import {Container, Divider, Grid, Segment} from "semantic-ui-react";
import IkonSparegrisSort from "../images/IkonSparegrisSort.svg";
import IkonNounPeople from "../images/IkonNounPeople.svg";
import IkonParaplySort from "../images/IkonParaplySort.svg";
import IkonVaekstSort from "../images/IkonVaekstSort.svg";
import "./Selvstaendige.css"
import HeaderImage from "./common/HeaderImage";
import Forsidebillede from "../images/par_i_regn.jpg";

const Pensionsdagen = props => (
    <div style={{backgroundColor: 'white'}}>
        <HeaderImage imageSrc={Forsidebillede}/>
        <Container text>
            <Segment basic>
                <p>Oktober 2022</p>
                <h2 className="trompet">FÅ OVERBLIK OVER DIN PENSION</h2>
                <h2 className="ui header" style={{paddingTop: 0}}>Du får en ekstra time, men det tager kun 10
                    minutter</h2>
            </Segment>
        </Container>

        <Container text>

            <Segment basic>
                <p className="manchet">I en travl hverdag kan det være svært at finde tid til at få overblik over sin
                    pensionsopsparing. Selv med de bedste intentioner, er der bare meget andet, der presser sig på i dagligdagen. Kender du det?</p>

                <p>Hvis tid er en del af forklaringen, så er det oplagt at bruge Pensionsdagen søndag den 30. oktober
                    2022 på at få overblik. Denne sidste søndag i oktober går vi fra sommertid til vintertid, og vi får
                    derfor en ekstra time foræret. Brug den på at få overblik. På PensionsInfo.dk er det nemt og hurtigt
                    – og bare rolig, det behøver ikke tage en time, og der er hjælp at hente.</p>

                <h3>Få hjælp af din rådgiver i dit pengeinstitut</h3>
                <p>Med MitID i hånden og med få klik på computeren kan du på PensionsInfo.dk danne en rapport, som du
                    kan sende til din rådgiver i dit pengeinstitut. Det tager ikke mere end 10 minutter.
                    Så kan du efterfølgende sammen med din rådgiver kigge på, hvor meget du har til gode til
                    pensionisttilværelsen, og om du har de rigtige forsikringsdækninger.</p>
                <p>TIP! Hvis du logger på PensionsInfo fra dit pengeinstitut, kan du sende rapporten
                    direkte til dem.</p>

                <h3>Pension er også forsikring</h3>
                <p>Pension er de penge, du hver måned sparer op, men en pensionsordning indeholder ofte også
                    forsikringsdækninger, fx hvis du mister din erhvervsevne, hvis du bliver syg, eller hvis du dør.
                    Disse dækninger kan du også se på PensionsInfo.dk.
                </p>

                <h3>Hold fast – selvom investeringer i 2022 har budt på store udsving</h3>
                <p>Mange har i dag investeret deres pension i et markedsrenteprodukt, og det vil sige, at afkastet
                    følger de investeringer, der ligger til grund for pensionsordningen. Har du en pensionsopsparing via
                    Letpension, har du et markedsrenteprodukt, og din opsparing består derfor af en blanding af aktier
                    og obligationer. Det betyder, at afkastet på din opsparing kan gå op og ned afhængigt af udviklingen
                    på finansmarkederne. I 2022 har der været store udsving på de finansielle markeder, og mange
                    oplever, at deres pension er blevet mindre værd. Det skyldes især krigen i Ukraine, energikrise og
                    den tårnhøje inflation.</p>
                <Divider hidden/>
                <div className="citat">Aktiemarkederne er altid gået op og ned, men historisk har aktiemarkederne givet
                    et positivt afkast, fx er afkastet over de seneste 10 år på ca. 100 % for en typisk pensionskunde,
                    og 30 % på 5 års sigt (ifølge en nylig beregning fra PFA). Så budskabet er, at du skal holde fast.
                </div>
                <Divider hidden/>

                <p>Ingen kender fremtiden, men pension er en langsigtet opsparing, og det er naturligt, at du hen over
                    et langt opsparingsforløb på måske 35-40 år vil opleve op- og nedture på de finansielle markeder,
                    som vil påvirke afkastet på din pensionsopsparing. Her er det vigtigt at have is i maven og være tro
                    mod sin investeringsprofil fremfor at forsøge at time de ofte uforudsigelige udsving på
                    markederne.</p>

                <h3>Tips til få overblik over pensionen</h3>
                <p>Her er der et par tips til, hvad du kan have fokus på, når du ønsker at få overblik. </p>

                <Divider hidden/>

                <Grid centered stackable columns={2}>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="fordele-ikon" style={{backgroundImage: `url(${IkonSparegrisSort})`}}></div>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <h4>Indbetaler du nok?</h4>
                            <p>Indbetaler du nok til at få det liv, du ønsker, når du forlader arbejdsmarkedet? Hvis
                                ikke, så overvej at lægge lidt ekstra til side hver måned. Selv små beløb vokser sig
                                store over tid. Undværer du fx 1.000 kr. månedligt til opsparing, med et afkast på 3,5 %
                                efter omk. og skat, så vil selve afkastet efter 10 år bidrage med 16 % af den samlede
                                opsparingssum. Er det i stedet over 30 år, så er det nu hele 42,8 % af opsparingen, der
                                stammer fra afkastet. Rentes rente effekten er bare ret fantastisk over tid (ifølge
                                beregninger fra PFA). </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="fordele-ikon" style={{backgroundImage: `url(${IkonParaplySort})`}}></div>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <h4>Hvordan er dit sikkerhedsnet?</h4>
                            <p>Har du overblik over dine forsikringer, og ved du, hvornår og hvordan de dækker dig?
                                Pension er også forsikringer, der dækker dig, hvis du bliver syg eller kommer til skade.
                                Forsikringer, der giver en god tryghed, og med et økonomisk sikkerhedsnet i ryggen kan
                                du bruge energien på det, der er vigtigst i hverdagen. </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="fordele-ikon" style={{backgroundImage: `url(${IkonVaekstSort})`}}></div>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <h4>Har du den rette investeringsprofil? </h4>
                            <p>Den rette investeringsprofil er den, der giver ro i maven. Der er altid en risiko ved at
                                investere, fordi markederne går op og ned, og derfor handler det om at mærke efter,
                                hvilken risiko du er villig til at løbe i forhold til det afkast, du har mulighed for at
                                få. Det er dog værd at bemærke, pensionsopsparing er langsigtet, så vær tro mod din
                                investeringsstrategi fremfor at forsøge at time de ofte uforudsigelige udsving på
                                markederne.</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="fordele-ikon" style={{backgroundImage: `url(${IkonNounPeople})`}}></div>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <h4>Hvem skal have opsparingen, hvis du dør før tid?</h4>
                            <p>Har du taget stilling til, hvem der skal have udbetalingerne fra din pensionsopsparing,
                                hvis du dør før tid? Hvert år dør ca. 9.000 danskere, inden de bliver pensioneret, og
                                dermed får de ikke selv glæde af deres pensionsopsparing. Omkring 9 milliarder kr.
                                bliver hvert år udbetalt til de efterladte. Derfor er det en god ide at tjekke, om det
                                er de rigtige personer, der står til at få pensionen. </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Divider hidden/>

            <Segment basic style={{backgroundColor: '#f6f0e7', color: 'black'}}>
                <h5>Pensionsdagen</h5>
                <p>Den sidste søndag i oktober holder en samlet pensionsbranche kampagnen Pensionsdagen. Kampagnen er
                    blevet holdt siden 2015. Kampagnen bliver holdt den dag, vi går over til vintertid og får en ekstra
                    time i døgnet - en time man kan bruge på at tjekke sine pensionsforhold.</p>
                <p>Pensionsdagen sætter fokus på pension, og branchen opfordrer danskerne til at tjekke deres pensioner.
                    Når ens livssituation ændrer sig, når man fx får børn, bliver gift eller skilt, skifter bolig osv.,
                    kan der være behov for at justere pensionsordningen, og det er der mange, som glemmer. Her er
                    Pensionsdagen en god anledning til at kigge sin ordning igennem. </p>
            </Segment>

        </Container>
    </div>
);

export default Pensionsdagen;
