import React from "react";
import {Grid} from "semantic-ui-react";
import "./CallForAction.css";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const CallForAction = props => {
    if (props.link.startsWith("http"))
    return <Grid.Column style={{maxWidth: 383}}>
        <a href={props.link} target="_blank" rel="noopener noreferrer">
            <div className="CallForAction" style={{backgroundColor: props.color, backgroundImage: `url(${props.icon})`}}>
                <div className="CallForActionText">{props.text}</div>
            </div>
        </a>
    </Grid.Column>
    else
        return <Grid.Column style={{maxWidth: 383}}>
            <Link to={props.link}>
                <div className="CallForAction" style={{backgroundColor: props.color, backgroundImage: `url(${props.icon})`}}>
                    <div className="CallForActionText">{props.text}</div>
                </div>
            </Link>
        </Grid.Column>






};

CallForAction.propTypes = {
    icon: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
};


export default CallForAction;
