const hostname = window && window.location && window.location.hostname;

let env;
// let apiRoot = 'https://www.letportal.dk/letpensiondk/api/';
let apiRoot = '/api/';

if (hostname === 'localhost') {
  env = 'local';
} else if (hostname === 'letpensiondk-test.azurewebsites.net') {
  env = 'test';
} else if (hostname === 'letpension.dk' || hostname === 'www.letpension.dk' || hostname === 'letpensiondk-prod.azurewebsites.net') {
  env = 'prod';
}

let AppConfig = {
  animateCharts: true,
  env,
  buildNumber: '20240430.1',
  apiRoot
}

export default AppConfig;
