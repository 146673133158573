import React from "react";
import {Container, Divider, Grid, Header} from "semantic-ui-react";
import FindPengeinstitut from "../../images/IkonHerfaarDuLetsikringer.svg";
import BrugDinForsikring from "../../images/IkonBrugDinForsikring.svg";

import appRoutes from "../../routes/appRoutes";
import CallForAction from "../common/CallForAction";

const OmLetpension = props => (
    <div>

        <Header as="h2" textAlign="center">Få Letsikringer over hele landet</Header>
        <Divider hidden/>
        <Container text>

            <p>Letpension er pensions- og forsikringsformidler, som formidler pensions- og forsikringsløsninger for PFA
                Pension via en række pengeinstitutter med filialer i hele Danmark.
            </p>
            <p>Du møder Letpension i dit lokale pengeinstitut. Her er det din lokale rådgiver, der sammen med dig
                skræddersyr din pension og forsikring.
            </p>
            <p>PFA leverer pensions- og forsikringsprodukterne, herunder er det PFA, der står for investeringen af de
                opsparede penge.
            </p>

        </Container>

        <Divider hidden/>

        <Grid centered stackable columns={2}>
            <Grid.Row>

                <CallForAction icon={BrugDinForsikring} color="#b9c35d" text="Letsikringer"
                               link={appRoutes.PRODUKTET}/>
                <CallForAction icon={FindPengeinstitut} color="#e49d3b" text="Her får du Letsikringer"
                               link={appRoutes.BLIV_KUNDE}/>

            </Grid.Row>
        </Grid>

    </div>
);

export default OmLetpension;
