import React, {Fragment} from "react";
import {Image, Responsive, Segment} from "semantic-ui-react";
import "./HeaderImage.css";
import PropTypes from "prop-types";

const HeaderImage = props => {
    return (
        <Fragment>
            <Responsive
                minWidth={Responsive.onlyComputer.minWidth}
                fireOnMount={true}>
                <Segment className="HeaderImage">
                    <Image src={props.imageSrc} fluid/>
                    <div className="HeaderImageText">
                        <div className="HeaderImageTrompet">{props.trompet}</div>
                        {props.text}
                    </div>
                </Segment>
            </Responsive>
            <Responsive
                maxWidth={Responsive.onlyTablet.maxWidth}
                fireOnMount={true}>
                <Segment className="HeaderImageMobile">
                    <Image src={props.imageSrc} fluid/>
                    <div className="HeaderImageTextMobile">
                        <div className="HeaderImageTrompetMobile">{props.trompet}</div>
                        {props.text}
                    </div>
                </Segment>
            </Responsive>
        </Fragment>
    )

};

HeaderImage.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    trompet: PropTypes.string,
    text: PropTypes.string,
};


export default HeaderImage;
