const appRoutes = {
  ROOT: "/",
  KONTAKT: "/kontakt",
  FOR_KUNDER: "/forkunder",
  BRUG_DIN_FORSIKRING: "/forkunder/brugdinforsikring",
  TJEK_DIN_PENSION: "/forkunder/tjekdinpension",
  MOEDEFORBEREDELSE: "/forkunder/moedeforberedelse",
  HELBREDSOPLYSNINGER: "/forkunder/helbredsoplysninger",
  BEGUNSTIGELSE: "/forkunder/begunstigelse",
  BEHANDLING_AF_PERSONOPLYSNINGER: "/forkunder/behandlingafpersonoplysninger",
  SKATTEREGLER: "/forkunder/skatteregler",
  PRODUKTET: "/produktet",
  LETSIKRING_VED_SYGDOM: "/produktet/letsikringvedsygdom",
  LETSIKRING_VED_KRITISK_SYGDOM: "/produktet/letsikringvedkritisksygdom",
  LETSIKRING_AF_INDTAEGT_VED_PENSION: "/produktet/letsikringafindtaegtvedpension",
  LETSIKRING_AF_BARN_VED_DOED: "/produktet/letsikringafbarnveddoed",
  LETSIKRING_AF_HELBRED: "/produktet/letsikringafhelbred",
  LETSIKRING_VED_DOED: "/produktet/letsikringveddoed",
  LETSIKRING_TIL_SELVSTAENDIGE: "/produktet/letsikringtilselvstaendige",
  OMKOSTNINGER: "/produktet/omkostninger",
  OMKOSTNINGER_UDVIDET: "/produktet/omkostningerudvidet",
  INVESTERING: "/produktet/investering",
  INVESTERING_AFKAST: "/produktet/investering/afkast",
  INVESTERING_PROFILER: "/produktet/investering/profiler",
  INVESTERING_FONDE: "/produktet/investering/fonde",
  INVESTERING_KUNDEKAPITAL: "/produktet/investering/kundekapital",
  INVESTERING_KLIMA: "/produktet/investering/klima",
  INVESTERING_BAEREDYGTIGHED: "/produktet/investering/baeredygtighed",
  BLIV_KUNDE: "/blivkunde",
  OM_LETPENSION: "/omletpension",
  MEDARBEJDERE: "/omletpension/medarbejdere",
  DIREKTION: "/omletpension/direktion",
  BESTYRELSE: "/omletpension/bestyrelse",
  JOBOPSLAG: "/omletpension/jobopslag",
  NYHEDER: "/omletpension/nyheder",
  PRIVATLIV_OG_COOKIES: "/privatlivogcookies",
  FAQ: "/faq",
  KLIMA: "/klima",
  SELVSTAENDIGE: "/selvstaendige",
  SENIOR: "/senior",
  BEGUNSTIGELSELANDING: "/begunstigelse",
  PENSIONSDAGEN: "/pensionsdagen2022",
  PENSIONSDAGEN2023: "/pensionsdagen2023",
}

export default  appRoutes;
