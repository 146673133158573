import ApiUtils from '../../util/ApiUtils.js'
import AppConfig from '../../app-config';
// import mockBestyrelse from '../../data/bestyrelse.mock.json';

export const HENT_BESTYRELSE = 'HENT_BESTYRELSE';
export const MODTAG_BESTYRELSE = 'MODTAG_BESTYRELSE';
export const INVALIDER_BESTYRELSE = 'INVALIDER_BESTYRELSE';


export function hentBestyrelse() {
  return {
    type: HENT_BESTYRELSE
  }
}

function modtagBestyrelse(state, json, fejl) {
  return {
    type: MODTAG_BESTYRELSE,
    medlemmer: json,
    receivedAt: Date.now(),
    fejlmeddelelse: fejl
  }
}


export function indlaesBestyrelse() {

  return (dispatch, getState) => {

    dispatch(hentBestyrelse())
    let url = AppConfig.apiRoot + 'bestyrelse';

    // dispatch(modtagBestyrelse(getState(), mockBestyrelse), null)

    return fetch(url,
        {
          method: 'GET'
        },
    )
        .then(ApiUtils.checkStatus)
        .then(response =>
                response.json()
                    .then(json => {
                      dispatch(modtagBestyrelse(getState(), json), null)
                    })
            , error => {
              dispatch(modtagBestyrelse(getState(), null, 'Fejl ved indlæsning af bestyrelse'))
            })
  }
}


