import React from 'react';
import {Container, Divider, Header, Icon, Table} from "semantic-ui-react";
import withSizes from 'react-sizes';

const mapSizesToProps = ({width}) => ({
    lilleSkaerm: width < 480,
});

const pengeinstitutter = [
 {
    "id": 4,
    "adresse": "Torvet 5",
    "postnummer": "8500",
    "by": "Grenaa",
    "pengeinstitutNavn": "Djurslands Bank",
    "website": "https://www.djurslandsbank.dk/"
}, {
    "id": 9,
    "adresse": "Østergade 2",
    "postnummer": "7790",
    "by": "Thyholm",
    "pengeinstitutNavn": "Hvidbjerg Bank",
    "website": "https://www.hvidbjergbank.dk/"
}, {
    "id": 10,
    "adresse": "Vestergade 8-16",
    "postnummer": "8600",
    "by": "Silkeborg",
    "pengeinstitutNavn": "Jyske Bank",
    "website": "https://www.jyskebank.dk/"
}, {
    "id": 11,
    "adresse": "H. P. Hanssens Gade 17",
    "postnummer": "6200",
    "by": "Aabenraa",
    "pengeinstitutNavn": "Kreditbanken",
    "website": "http://www.kreditbanken.dk/"
}, {
    "id": 13,
    "adresse": "Nybrogade 3",
    "postnummer": "4900",
    "by": "Nakskov",
    "pengeinstitutNavn": "Lollands Bank",
    "website": "https://www.lollandsbank.dk/"
}, {
    "id": 14,
    "adresse": "Højbro Plads 9-11",
    "postnummer": "1404",
    "by": "København K",
    "pengeinstitutNavn": "Lån & Spar Bank",
    "website": "https://www.lsb.dk/"
}, {
    "id": 15,
    "adresse": "Storegade 29",
    "postnummer": "4780",
    "by": "Stege",
    "pengeinstitutNavn": "Møns Bank",
    "website": "https://www.moensbank.dk/"
}, {
    "id": 16,
    "adresse": "Adelgade 49",
    "postnummer": "5400",
    "by": "Bogense",
    "pengeinstitutNavn": "Nordfyns Bank",
    "website": "http://www.nordfynsbank.dk/"
}, {
    "id": 17,
    "adresse": "Torvet 1",
    "postnummer": "6950",
    "by": "Ringkøbing",
    "pengeinstitutNavn": "Nordjyske Bank",
    "website": "https://www.nordjyskebank.dk/"
}, {
    "id": 18,
    "adresse": "Torvet 1",
    "postnummer": "6950",
    "by": "Ringkøbing",
    "pengeinstitutNavn": "Ringkjøbing Landbobank",
    "website": "https://www.landbobanken.dk/"
}, {
    "id": 20,
    "adresse": "Banktorvet 3",
    "postnummer": "6900",
    "by": "Skjern",
    "pengeinstitutNavn": "Skjern Bank",
    "website": "https://www.skjernbank.dk/"
}, {
    "id": 25,
    "adresse": "Tronholmen 1",
    "postnummer": "8960",
    "by": "Randers SØ",
    "pengeinstitutNavn": "Sparekassen Kronjylland",
    "website": "https://www.sparkron.dk/"
}, {
    "id": 23,
    "adresse": "Peberlyk 4",
    "postnummer": "6200",
    "by": "Aabenraa",
    "pengeinstitutNavn": "Sydbank",
    "website": "https://www.sydbank.dk/"
}]

const BlivKunde = props => {
    const {lilleSkaerm} = props;

    return (
        <div>
            <Header as="h2" textAlign="center">Her får du Letsikringer</Header>
            <Divider hidden/>
            <Container text>

            <p>Du kan få adgang til pensions- og forsikringsprodukter via pengeinstitutter over hele landet. Se hvilke i listen.
            </p>
            <PengeinstitutFilialTabel pengeinstitutter={pengeinstitutter} lilleSkaerm={lilleSkaerm}/>
            </Container>
        </div>
    );
}

const PengeinstitutFilialTabel = props => {
    const {pengeinstitutter, lilleSkaerm} = props;

    let pengeinstitutFilialTabelRaekker = pengeinstitutter.map(function (pengeinstitutModel) {
        return (
            <PengeinstitutFilialTabelRaekke pengeinstitut={pengeinstitutModel} lilleSkaerm={lilleSkaerm}
                                            key={pengeinstitutModel.id}/>
        );
    });


    return (
        <Table color="teal" inverted compact striped>
            <Table.Header>
                {!lilleSkaerm &&
                <Table.Row>
                    <Table.HeaderCell>Pengeinstitut</Table.HeaderCell>
                </Table.Row>
                }
            </Table.Header>
            <Table.Body>
                {pengeinstitutFilialTabelRaekker}
            </Table.Body>
        </Table>
    );
}

const PengeinstitutFilialTabelRaekke = props => {
    const {pengeinstitut} = props;

    return (
        <Table.Row>
            <Table.Cell>
                <span><a href={pengeinstitut.website} target="_blank" rel="noopener noreferrer" className="link" style={{color:'black'}}>{pengeinstitut.pengeinstitutNavn}</a></span>&nbsp;<Icon name="arrow right" style={{fontSize:14}}/>
            </Table.Cell>
        </Table.Row>

    );
}

export default withSizes(mapSizesToProps)(BlivKunde);
