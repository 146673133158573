import {BEREGNER_OMKOSTNINGER, MODTAG_OMKOSTNINGER, NULSTIL_OMKOSTNINGER,} from '../actions/omkostninger.js'


export default function omkostninger(
    state = {
      indlaeser: false,
      invalideret: false,
      fejlmeddelelse: null,
      output: {}
    },
    action
) {
  switch (action.type) {
    case BEREGNER_OMKOSTNINGER:
      return Object.assign({}, state, {
        beregner: true,
        fejlmeddelelse: null
      });
    case MODTAG_OMKOSTNINGER:
      return Object.assign({}, state, {
        beregner: false,
        fejlmeddelelse: action.fejlmeddelelse,
        output: action.omkostninger
      });
    case NULSTIL_OMKOSTNINGER:
      return Object.assign({}, state, {
        beregner: false,
        fejlmeddelelse: null,
        output: null
      });
    default:
      return state;
  }
}


