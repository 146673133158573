import React from 'react';
import {Button, Container, Divider, Grid, Header} from "semantic-ui-react";
import "../components/common/KontaktBoks.css";
import ImageLink from "./common/ImageLink";
import appRoutes from "../routes/appRoutes";
import LetsikringVedKritiskSygdomImg from "../images/letsikringvedkritisksygdom.jpg";
import LetsikringAfHelbredImg from "../images/letsikringafhelbred.jpg";

const Kontakt = props => {
    return (
        <div>
            <Header as="h1" textAlign="center">Brug din forsikring</Header>
            <Divider hidden/>
            <Container text>
                <p>Vil du anmelde en skade, skal det ske til PFA Pension, som Letpension formidler pensions- og
                    forsikringsløsninger for. </p>
                <p>Du kan anmelde skader via PFA Pension’s digitale skadesanmeldelse. Hvis du har spørgsmål, inden du
                    anmelder en skade, kan du kontakte PFA Pension på 7080 5500. </p>

                <Divider hidden/>
                <Button color="teal" as="a" href="https://mit.pfa.dk/" target="blank">Anmeld skade via PFA</Button>
                <Divider hidden/>

                <p>Har du behov for rådgivning om dine forsikringer, skal du kontakte
                    det pengeinstitut, du har forsikring igennem. </p>
            </Container>

            <Divider hidden/>
            <Divider hidden/>

            <Grid centered stackable columns={2}>
                <Grid.Row>
                    <ImageLink imageSrc={LetsikringAfHelbredImg} text="Letsikring af helbred" link={appRoutes.LETSIKRING_AF_HELBRED}/>
                    <ImageLink imageSrc={LetsikringVedKritiskSygdomImg} text="Letsikring ved kritisk sygdom" link={appRoutes.LETSIKRING_VED_KRITISK_SYGDOM}/>
                </Grid.Row>
            </Grid>
        </div>
    );
};

export default Kontakt;
