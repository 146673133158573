import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from "prop-types";

import {useDispatch, useSelector} from 'react-redux';
import {Responsive} from "semantic-ui-react";
import withSizes from "react-sizes";
import OmkostningberegnerPresentation from "./OmkostningsberegnerPresentation";
import {beregnOmkostninger} from '../../redux/actions/omkostninger.js';


const mapSizesToProps = ({width}) => ({
  tablet: width < Responsive.onlyTablet.minWidth,
});

let pendingCalculation = null;

const OmkostningberegnerContainer = props => {
  const {maxDepotVardi, tablet, udvidet} = props;
  const dispatch = useDispatch();
  const output = useSelector(state => state.omkostninger.output || {});

  const [params, setParams] = useState({
    pensionsopsparing: '',
    pensionindbetaling: '',
    forventetpensionsalder: 67,
    alder: 40,
    investeringsprofil: 'A',
    tegningsmaade: 'Arbejdsgiver',
    visberegningsdetaljer: false,
    kundekapital: true,
    kundekapitalBeregnesKunAfIndbetalingen: true,
    betalingomkostningrabat: 0,
    saldoOmkostningRabat: false,
    visopsparingOvermaxModal: false,
    valgfriHoejAndel: '',
    valgfriLavAndel: ''
  });
  const [visOverMax, setVisOverMax] = useState(false);

  const addParams = useCallback((newParams) => {
    setParams({
      ...params,
      ...newParams
    })
  }, [params])

  useEffect(() => {
    const opsparing = output.pensionsopsparingEfter1Aar;
    if (opsparing && maxDepotVardi && (opsparing >= maxDepotVardi))
      setVisOverMax(true);
  }, [maxDepotVardi, output.pensionsopsparingEfter1Aar])

  useEffect(() => {
    if (pendingCalculation)
      clearTimeout(pendingCalculation);
    pendingCalculation = setTimeout(() => {
      if (udvidet)
        dispatch(beregnOmkostninger({
          ...params,
          betalingomkostningrabat: (params.betalingomkostningrabat || 0) / 100
        }));
      else
        dispatch(beregnOmkostninger({
          alder: params.alder,
          forventetpensionsalder: params.forventetpensionsalder,
          investeringsprofil: params.investeringsprofil,
          pensionindbetaling: params.pensionindbetaling,
          pensionsopsparing: params.pensionsopsparing,
          tegningsmaade: params.tegningsmaade,
          valgfriHoejAndel: params.valgfriHoejAndel,
        }));
      pendingCalculation = null;
    }, 200);

  }, [udvidet, params, dispatch]);

  const lukopsparingOvermaxModal = () => {
    setVisOverMax(false);
  }

  const genericFieldHandler = (event, data) => {
    let fieldValue = data.value;
    if (data.type === 'checkbox')
      fieldValue = data.checked;

    if (data['data-numeric'] === true) {
      const re = /^[0-9\b]+$/;
      if (fieldValue !== '' && !re.test(fieldValue))
        return;
    }

    let forventetpensionsalder = params.forventetpensionsalder;
    let alder = params.alder;

    if (data.name === 'forventetpensionsalder') {
      forventetpensionsalder = fieldValue;
    }
    if (data.name === 'alder') {
      alder = fieldValue;
    }

    if (alder > forventetpensionsalder) {
      alder = forventetpensionsalder;
    }


    let betalingomkostningrabat = params.betalingomkostningrabat;
    if (data.name === 'betalingomkostningrabat') {
      betalingomkostningrabat = fieldValue;
      if (betalingomkostningrabat > 100)
        betalingomkostningrabat = 100;
      if (betalingomkostningrabat < 0)
        betalingomkostningrabat = 0;
    }

    addParams({
      [data.name]: fieldValue,
      forventetpensionsalder,
      alder,
      betalingomkostningrabat
    })

  }

  const toggleDetaljer = () => {
    addParams({
      visberegningsdetaljer: !params.visberegningsdetaljer
    })
  }

  return <OmkostningberegnerPresentation
      tablet={tablet}
      udvidet={udvidet}
      visberegningsdetaljer={params.visberegningsdetaljer}
      visopsparingOvermaxModal={visOverMax}
      genericFieldHandler={genericFieldHandler}
      lukopsparingOvermaxModal={lukopsparingOvermaxModal}
      input={params}
      toggleDetaljer={toggleDetaljer}
      output={visOverMax ? {} : {
        omkostningerAfDinBetalingTilPFA: output.omkostningerAfDinBetalingTilPFA,
        omkostningerAfDinBetalingTilDitPengeinstitut: output.omkostningerAfDinBetalingTilPengeinstituttet,
        omkostningerAfDinSaldoTilPfaOgDitPengeinstitut: output.omkostningerAfSaldoPFAOgPengeinstitut,
        investeringsOmkostninger: output.investeringsomkostninger,
        standardAaok: output.standardAAOK,
        standardAaop: output.standardAAOP,
        nuvaerendePensionsopsparing: parseFloat(params.pensionsopsparing || 0),
        aarligIndbetalingEfterAmb: output.aarligIndbetalingEfterAmb,
        investeringsAfkast: output.investeringsAfkast,
        administrationOgInvesteringsomkostninger: output.standardAAOK,
        pensionsafkastskat: output.pensionsafkastskat,
        pensionsopsparingEfter1Aar: output.pensionsopsparingEfter1Aar
      }}
  />
}

OmkostningberegnerContainer.propTypes = {
  maxDepotVardi: PropTypes.number.isRequired,
  udvidet: PropTypes.bool.isRequired,
  omkostninger: PropTypes.shape({
    output: PropTypes.object.isRequired
  }).isRequired
};

OmkostningberegnerContainer.defaultProps = {
  maxDepotVardi: null,
  udvidet: false,
  omkostninger: {
    output: {}
  }
};

export default withSizes(mapSizesToProps)(OmkostningberegnerContainer);
