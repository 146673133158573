import './polyfills'; //Must be imported as first line!!!!
import 'semantic-ui-less/semantic.less'
import React from 'react';
import {render} from 'react-dom';
import {AppContainer} from 'react-hot-loader';
import AppConfig from './app-config';
import App from './components/App';
import configureStore from './redux/configureStore';

import './index.css';

console.log('Launching build number ' + AppConfig.buildNumber);

const store = configureStore();

render(
    <AppContainer>
      <App store={store}/>
    </AppContainer>,
    document.getElementById('root')
);

if (module.hot) {
  module.hot.accept('./components/App', () => {
    const NewRoot = require('./components/App').default;
    render(
        <AppContainer>
          <NewRoot store={store}/>
        </AppContainer>,
        document.getElementById('root')
    );
  });
}

