import React, {useEffect} from "react";
import { Container } from "semantic-ui-react";
import "./Main.css";
import { indlaesSatser } from '../../redux/actions/satser';
import { useDispatch } from 'react-redux';

const Main = props => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(indlaesSatser())
    }, [dispatch])

    return (
        <Container className="Main">
            {props.children}
        </Container>
    )
};

export default Main;
