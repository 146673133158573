import React from "react";
import { NavLink } from "react-router-dom";
import { Divider, Grid, Header, Responsive, Tab } from "semantic-ui-react";
import FindPengeinstitut from "../../images/IkonHerfaarDuLetsikringer.svg";
import TjekDinPension from "../../images/IkonTjekDinPension.svg";
import withSizes from "react-sizes";
import appRoutes from "../../routes/appRoutes";
import { Route } from "react-router";
import CallForAction from "../common/CallForAction";

const mapSizesToProps = ({ width }) => ({
  tablet: width < Responsive.onlyTablet.minWidth,
});

const Investering = (props) => {
  const { tablet } = props;
  const invfiler = [
    {
      headline: "Investeringsprofil A",
      filnavn: "Investeringsprofil_A_2023-03-08",
    },
    {
      headline: "Investeringsprofil B",
      filnavn: "Investeringsprofil_B_2023-03-08",
    },
    {
      headline: "Investeringsprofil C",
      filnavn: "Investeringsprofil_C_2023-03-08",
    },
    {
      headline: "Investeringsprofil D",
      filnavn: "Investeringsprofil_D_2023-03-08",
    },
    {
      headline: "Investeringsprofil Klima A",
      filnavn: "Investeringsprofil_Klima_A_2023-03-08",
    },
    {
      headline: "Investeringsprofil Klima B",
      filnavn: "Investeringsprofil_Klima_B_2023-03-08",
    },
    {
      headline: "Investeringsprofil Klima C",
      filnavn: "Investeringsprofil_Klima_C_2023-03-08",
    },
    {
      headline: "Investeringsprofil Klima D",
      filnavn: "Investeringsprofil_Klima_D_2023-03-08",
    },
    {
      headline: "Investeringsprofil Høj Risiko Fonden",
      filnavn: "Investeringskoncept_Hoej_Risiko_Fonden_2023-03-08",
    },
    {
      headline: "Investeringsprofil Lav Risiko Fonden",
      filnavn: "Investeringskoncept_Lav_Risiko_Fonden_2023-03-08",
    },
    {
      headline: "Investeringsprofil Klima Høj Risiko Fonden",
      filnavn: "Investeringskoncept_Klima_Hoej_Risiko_Fonden_2023-03-08",
    },
    {
      headline: "Investeringsprofil Klima Lav Risiko Fonden",
      filnavn: "Investeringskoncept_Klima_Lav_Risiko_Fonden_2023-03-08",
    },
  ];
  const panes = [
    {
      menuItem: {
        as: NavLink,
        to: appRoutes.INVESTERING_AFKAST,
        exact: true,
        icon: "line chart",
        content: tablet ? null : "Investering og afkast",
        key: "invafkast",
      },
      pane: (
        <Route
          path={appRoutes.INVESTERING_AFKAST}
          key={appRoutes.INVESTERING_AFKAST}
          exact
          render={() => (
            <Tab.Pane>
              <Grid>
                <Grid.Row columns={tablet ? 1 : 2}>
                  <Grid.Column>
                    <h4>Hvordan bliver mine penge investeret?</h4>
                    <p>
                      Når du køber en Letsikring af indtægt ved pension, bliver
                      din opsparing investeret i markedsrente, dvs. i en
                      blanding af aktier, obligationer, ejendomme og alternative
                      investeringer. Alle investeringer foregår via to fonde:
                      Lav risiko fonden og Høj risiko fonden.
                    </p>
                    <p>
                      Alt efter din risiko er fordelingen mellem Lav risiko
                      fonden og Høj risiko fonden forskellig. Potentialet for
                      afkast hænger sammen med risiko: jo højere risiko, jo
                      større potentiale for højt afkast og omvendt.{" "}
                    </p>
                    <h4>
                      Du kan vælge mellem to forskellige investeringskoncepter
                    </h4>

                    <ul>
                      <li>
                        <b>
                          Investeringskoncept med fire investeringsprofiler: A,
                          B, C og D
                        </b>
                      </li>
                      <div>
                        <p>
                          Investeringsprofilerne A, B, C og D har forskellige
                          potentialer for et godt afkast og forskellige grader
                          af risiko knyttet til sig. Profil A har den laveste
                          risiko og det mindste potentiale for et højt afkast.
                          Profil D har den bedste mulighed for et stort afkast,
                          men også den højeste risiko. Du skal altså tage
                          stilling til, hvor risikovillig du er.
                        </p>
                        <p>
                          For alle fire investeringsprofiler gælder det, at PFA
                          løbende tilpasser risikoen i takt med, at du nærmer
                          dig pensionsalderen.
                        </p>
                      </div>
                      <br />
                      <li>
                        <b>Investeringskoncept Valgfri</b>
                      </li>
                      <div>
                        <p>
                          Med investeringskonceptet Valgfri tager du selv
                          ansvaret for den løbende fastlæggelse af risikoen på
                          din pensionsopsparing. Du vælger selv fordelingen
                          mellem Lav risiko fonden og Høj risiko fonden.
                        </p>
                        <p>
                          Modsat investeringsprofil A-D bliver risikoen ikke
                          automatisk nedtrappet, når du nærmer dig
                          pensionsalderen. PFA anbefaler, at du løbende
                          tilpasser risikoen i takt med, at du nærmer dig
                          pensionsalderen.
                        </p>
                        <p>
                          Valgfri er til dig, som har indsigt og interesse i
                          investering, og som selv vil sørge for løbende at
                          justere og tilpasse risiko fx i forhold til
                          pensioneringstidspunkt.
                        </p>
                      </div>
                    </ul>
                  </Grid.Column>
                  <Grid.Column>
                    <h4>PFA KundeKapital</h4>
                    <p>
                      Du kan vælge, at en del af de løbende indbetalinger,
                      indskud og overførsler fra andre leverandører til din
                      opsparing skal gå til PFA KundeKapital. For PFA
                      KundeKapital kan du ikke vælge, hvordan din opsparing
                      bliver investeret.
                    </p>
                    <p>Du kan læse mere under fanen ”PFA KundeKapital”.</p>

                    <h4>Se dit afkast</h4>
                    <p>
                      Har du en pensionsopsparing via Letpension, kan du se dit
                      afkast i Letportalen enten via "Log ind" øverst på siden
                      her eller via din netbank.
                    </p>
                    <p>
                      <a
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="/dokumenter/afkast"
                      >
                        Se PFA's investeringsafkast.
                      </a>
                    </p>

                    <h4>Risikomærkning</h4>
                    <p>
                      For at gøre det nemmere for dig at forstå og sammenligne
                      risikoen på din pensionsopsparing har F&P, som er
                      brancheorganisation for forsikrings- og pensionsselskaber
                      i Danmark, lavet et værktøj. Med dette værktøj kan du se
                      risikoen i dit eget pensionsprodukt og sammenligne den med
                      gennemsnittet af risikoen for pensionsprodukter i
                      markedsrente på tværs af pensionsselskaber.
                    </p>
                    <p>
                      <a
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.faktaompension.dk/risiko"
                      >
                        Gå til F&P’s værktøj Min Pensionsrisiko
                      </a>
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Tab.Pane>
          )}
        />
      ),
    },
    {
      menuItem: {
        as: NavLink,
        to: appRoutes.INVESTERING_PROFILER,
        exact: true,
        icon: "pie chart",
        content: tablet ? null : "Investeringsprofiler",
        key: "profiler",
      },
      pane: (
        <Route
          path={appRoutes.INVESTERING_PROFILER}
          key={appRoutes.INVESTERING_PROFILER}
          exact
          render={() => (
            <Tab.Pane>
              <Grid>
                <Grid.Row columns={tablet ? 1 : 2}>
                  <Grid.Column>
                    <h4>Investeringsprofil A-D</h4>
                    <p>
                      Forskellen på de fire investeringsprofiler er først og
                      fremmest, hvor stor en andel af din opsparing, der bliver
                      investeret i aktiver med højere risiko. Du skal altså tage
                      stilling til, hvor risikovillig du er.
                    </p>
                    <p>
                      For investeringsprofilerne A-D gælder, at det tilsigtede
                      risikoniveau, du vælger, bestemmer fordelingen mellem Høj
                      risiko fonden og Lav risiko fonden.
                    </p>
                    <p>
                      I alle fire profiler er der indbygget en sikkerhed.
                      Sikkerheden består i, at risikoen i investeringen gradvist
                      bliver nedtrappet, når du nærmer dig pensionsalderen – jo
                      tættere du er på din pension, jo mere sikkert investeres
                      dine penge. Formålet er at sikre dine penge bedre, når du
                      nærmer dig pensionsalderen.
                    </p>
                    <h4>Investeringsprofil A - lav risiko</h4>
                    <p>
                      <em>Meget lav andel af opsparing i Høj risiko fonden</em>
                      <br />
                      25 % af din opsparing bliver primært investeret i aktier i
                      Høj risiko fonden. 75 % bliver primært investeret i
                      obligationer i Lav risiko fonden. Din andel af
                      investeringer i Høj risiko fonden trappes gradvist ned, så
                      den er ca. 10 %, når du går på pension.
                    </p>
                    <p>
                      Sammensætningen af investeringerne i profil A kan
                      sammenlignes med en traditionel pensionsopsparing til
                      gennemsnitsrente.
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <h4>Investeringsprofil B - lav risiko</h4>
                    <p>
                      <em>Lav andel af opsparing i Høj risiko fonden</em>
                      <br />
                      50 % af din opsparing bliver primært investeret i aktier i
                      Høj risiko fonden. 50 % bliver primært investeret i
                      obligationer i Lav risiko fonden. Din andel af
                      investeringer i Høj risiko fonden trappes gradvist ned, så
                      den er ca. 20 %, når du går på pension.
                    </p>
                    <h4>Investeringsprofil C - mellem risiko</h4>
                    <p>
                      <em>Mellem andel af opsparing i Høj risiko fonden</em>
                      <br />
                      75 % af din opsparing bliver primært investeret i aktier i
                      Høj risiko fonden. 25 % bliver primært investeret i
                      obligationer i Lav risiko fonden. Din andel af
                      investeringer i Høj risiko fonden trappes gradvist ned, så
                      den er ca. 30 %, når du går på pension.
                    </p>
                    <h4>Investeringsprofil D - høj risiko</h4>
                    <p>
                      <em>Høj andel af opsparing i Høj risiko fonden</em>
                      <br />
                      100 % af din opsparing bliver primært investeret i aktier
                      i Høj risiko fonden. Din andel af investeringer i Høj
                      risiko fonden trappes gradvist ned, så den er ca. 40 %,
                      når du går på pension.
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Tab.Pane>
          )}
        />
      ),
    },
    {
      menuItem: {
        as: NavLink,
        to: appRoutes.INVESTERING_FONDE,
        exact: true,
        icon: "bar chart",
        content: tablet ? null : "Investeringsfonde",
        key: "invfonde",
      },
      pane: (
        <Route
          path={appRoutes.INVESTERING_FONDE}
          key={appRoutes.INVESTERING_FONDE}
          exact
          render={() => (
            <Tab.Pane>
              <Grid>
                <Grid.Row columns={tablet ? 1 : 2}>
                  <Grid.Column>
                    <h4>
                      Hvordan er de to investeringsfonde Høj risiko og Lav
                      risiko bygget op?
                    </h4>
                    <p>
                      Har du en Letsikring af indtægt ved pension, bliver din
                      opsparing investeret via Høj risiko fonden og Lav risiko
                      fonden. For investeringsprofilerne A-D gælder det, at det
                      tilsigtede risikoniveau, du vælger, bestemmer fordelingen
                      mellem Høj risiko fonden og Lav risiko fonden.
                    </p>
                    <p>
                      I begge fonde investeres der i aktier, obligationer,
                      ejendomme og alternative investeringer, men fordelingen er
                      forskellig. For begge fonde gælder det, at investeringen
                      sker bredt på tværs af sektorer og geografi for at sprede
                      risikoen mest muligt, og at PFA løbende tilpasser fonden i
                      forhold til markedssituationen.
                    </p>

                    <h4>Høj risiko fonden</h4>
                    <p>
                      Høj risiko fonden har en markant overvægt af aktier og har
                      derudover investeringer med lignende risiko som aktier,
                      såsom alternative investeringer eller ejendomme.{" "}
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <h4>Lav risiko fonden</h4>
                    <p>
                      Lav risiko fonden har en markant overvægt af obligationer,
                      og har derudover alternative investeringer og ejendomme.{" "}
                    </p>
                    <p>
                      Fordelingen i aktivklasserne fordeles ud fra forsikring-
                      og pensions standarder i begge fonde.
                    </p>

                    <h4>Beholdningslister</h4>
                    <p>
                      <a
                        target="_blank"
                        className="link"
                        rel="noopener noreferrer"
                        href="/dokumenter/aktier"
                      >
                        Aktieliste (PDF)
                      </a>
                      <br />
                      <a
                        target="_blank"
                        className="link"
                        rel="noopener noreferrer"
                        href="/dokumenter/obligationer"
                      >
                        Obligationsliste (PDF)
                      </a>
                      <br />
                      <a
                        target="_blank"
                        className="link"
                        rel="noopener noreferrer"
                        href="/dokumenter/statsobligationer"
                      >
                        Landeliste over statsobligationer (PDF)
                      </a>
                      <br />
                      <a
                        target="_blank"
                        className="link"
                        rel="noopener noreferrer"
                        href="/dokumenter/alternativeinvesteringer"
                      >
                        Liste over alternative investeringer og ejendomme (PDF)
                      </a>
                      <br />
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Tab.Pane>
          )}
        />
      ),
    },
    {
      menuItem: {
        as: NavLink,
        to: appRoutes.INVESTERING_KUNDEKAPITAL,
        exact: true,
        icon: "users",
        content: tablet ? null : "PFA KundeKapital",
        key: "kundekapital",
      },
      pane: (
        <Route
          path={appRoutes.INVESTERING_KUNDEKAPITAL}
          key={appRoutes.INVESTERING_KUNDEKAPITAL}
          exact
          render={() => (
            <Tab.Pane>
              <Grid>
                <Grid.Row columns={tablet ? 1 : 2}>
                  <Grid.Column>
                    <h4>Sådan fungerer PFA KundeKapital</h4>
                    <p>
                      Når du køber en Letsikring af indtægt ved pension, vil den
                      blive oprettet i PFA Pension. Du kan vælge, at PFA
                      KundeKapital skal være en del af din pensionsordning.
                    </p>
                    <p>
                      PFA KundeKapital er ansvarlig kapital i PFA Pension på
                      linje med egenkapitalen og kan sammenlignes med en
                      investering i PFA Pension. PFA KundeKapital får mindst
                      samme afkast som egenkapitalen, men kan også være
                      forbundet med risiko. PFA KundeKapital er nemlig en del af
                      PFA Pensions kapitalgrundlag og skal derfor – sammen med
                      egenkapitalen – dække, hvis PFA Pension lider tab.
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <p>
                      Det betyder, at PFA KundeKapital kan blive mindre ved
                      negativt afkast og i yderste konsekvens tabes helt. Du kan
                      ikke vælge, hvordan din opsparing i PFA KundeKapital
                      bliver investeret og du kan ikke sikre størrelsen af
                      udbetaling fra PFA KundeKapital. PFA KundeKapital bliver
                      udbetalt sammen med udbetalingerne fra din Letsikring af
                      indtægt ved pension.
                    </p>
                    <p>
                      <a
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://pfa.dk/erhverv/hvorfor-pfa/pfa-kundekapital/"
                      >
                        Læs mere om PFA KundeKapital.
                      </a>
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Tab.Pane>
          )}
        />
      ),
    },
    {
      menuItem: {
        as: NavLink,
        to: appRoutes.INVESTERING_KLIMA,
        exact: true,
        icon: "envira",
        content: tablet ? null : "Klima",
        key: "klima",
      },
      pane: (
        <Route
          path={appRoutes.INVESTERING_KLIMA}
          key={appRoutes.INVESTERING_KLIMA}
          exact
          render={() => (
            <Tab.Pane>
              <Grid>
                <Grid.Row columns={tablet ? 1 : 2}>
                  <Grid.Column>
                    <h4>Klimavenlig investering</h4>
                    <p>
                      Når PFA investerer, er det altid med tanke på
                      ansvarlighed, bæredygtighed og med udgangspunkt i
                      Paris-aftalen og FN’s Verdensmål efter principperne om
                      ansvarlige investeringer.{" "}
                    </p>
                    <p>
                      Siden 2021 har vi tilbudt en opsparingsmulighed, der har
                      fokus på klima og CO<sub>2</sub>. Det betyder, at du kan
                      mindske CO<sub>2</sub>-aftrykket med din pension. Du
                      vælger selv, hvor stor en andel af din opsparing, der skal
                      være ekstra klimavenlig.
                    </p>
                    <p>
                      De klimavenlige investeringer har samme risikoniveau og
                      forventning til afkast på langt sigt som din nuværende
                      opsparing. Dog vil afkastet udvikle sig forskelligt, fordi
                      produkterne er investeret forskelligt.
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <p>
                      Mange af investeringerne med ekstra fokus på klima indgår
                      i de øvrige investeringer, men ikke nødvendigvis omvendt.
                      Det skyldes de ekstra høje krav, som kun få selskaber kan
                      leve op til. At der investeres i færre selskaber, kan give
                      større udsving i afkastet især på den korte bane. På den
                      lange bane betyder det forventeligt mindre, og pension er
                      en langsigtet opsparing.
                    </p>
                    <p>
                      <a className="link" href="/klima">
                        Læs mere om ekstra klimavenlige investeringer.
                      </a>
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Tab.Pane>
          )}
        />
      ),
    },
    {
      menuItem: {
        as: NavLink,
        to: appRoutes.INVESTERING_BAEREDYGTIGHED,
        exact: true,
        icon: "recycle",
        content: tablet ? null : "Bæredygtighedsrelaterede oplysninger",
        key: "baeredygtighed",
      },
      pane: (
        <Route
          path={appRoutes.INVESTERING_BAEREDYGTIGHED}
          key={appRoutes.INVESTERING_BAEREDYGTIGHED}
          exact
          render={() => (
            <Tab.Pane>
              <Grid>
                <Grid.Row columns={tablet ? 1 : 2}>
                  <Grid.Column>
                    <h4>Investering og bæredygtighed</h4>
                    <p>
                      EU har som del af arbejdet med bæredygtig økonomi vedtaget
                      rammer for, hvordan udbydere af finansielle produkter skal
                      klassificere, dokumentere, rådgive og kommunikere om
                      bæredygtighed i forhold sine finansielle produkter,
                      herunder pensionsprodukter. Disse rammer er nærmere
                      beskrevet i EU’s disclosureforordning.{" "}
                    </p>
                    <p>
                      Derfor har PFA lavet en bæredygtighedsbeskrivelse for hver
                      type af investeringsprofil og investeringskoncept. Du kan
                      se dem på siden her.
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <h4>Letsikring af indtægt ved pension</h4>
                    <p>
                      {invfiler.map((x, i) => {
                        return (
                          <span key={i}>
                            {x.headline}
                            <br />
                            <a
                              target="_blank"
                              className="link"
                              rel="noopener noreferrer"
                              href={
                                "/dokumenter/Praekontrakt_info_" + x.filnavn
                              }
                            >
                              Prækontraktuel information
                            </a>
                            <br />
                            <a
                              target="_blank"
                              className="link"
                              rel="noopener noreferrer"
                              href={"/dokumenter/" + x.filnavn}
                            >
                              Bæredygtighedsrelaterede oplysninger
                            </a>
                            <br />
                            <a
                              target="_blank"
                              className="link"
                              rel="noopener noreferrer"
                              href={"/dokumenter/Sammenfatning_" + x.filnavn}
                            >
                              Sammenfatning - Bæredygtighedsrelaterede
                              oplysninger
                            </a>
                            <br />
                            <br />
                          </span>
                        );
                      })}
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Tab.Pane>
          )}
        />
      ),
    },
  ];

  return (
    <div>
      <Header as="h2" textAlign="center">
        Sådan bliver dine penge investeret
      </Header>
      <Divider hidden />
      <p>
        Letpension er pensions- og forsikringsformidler, som formidler pensions-
        og forsikringsløsninger for PFA Pension.
      </p>
      <p>
        PFA leverer pensions- og forsikringsprodukterne, herunder er det PFA,
        der står for investeringen af de opsparede penge.
      </p>

      <Tab renderActiveOnly={false} activeIndex={-1} panes={panes} />
      <Divider section hidden />

      <Grid centered stackable columns={2}>
        <Grid.Row>
          <CallForAction
            icon={FindPengeinstitut}
            color="#e49d3b"
            text="Her får du Letsikringer"
            link={appRoutes.BLIV_KUNDE}
          />
          <CallForAction
            icon={TjekDinPension}
            color="#b9c35d"
            text="Tjek din pension"
            link={appRoutes.TJEK_DIN_PENSION}
          />
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default withSizes(mapSizesToProps)(Investering);
