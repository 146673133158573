export const formatNumber = (number, decimals) => {
  if (number == null)
    return null;

  return number.toLocaleString('da-DK', {minimumFractionDigits: decimals || 0, maximumFractionDigits: decimals || 0});
};

export const formatDate = (dateString) => {
    if (dateString == null)
        return null;

    const date = new Date(dateString);
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };

    return date.toLocaleDateString("da-DK", options);
};


