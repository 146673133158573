import React from "react";
import { Container, Divider, Grid, Segment, Table } from "semantic-ui-react";
import IkonNounMoney from "../images/IkonNounMoney.svg";
import IkonNounPeople from "../images/IkonNounPeople.svg";
import IkonParaplySort from "../images/IkonParaplySort.svg";
import "./Selvstaendige.css"
import AutoAccordion from "./common/AutoAccordion";
import { Link } from "react-router-dom";
import HeaderImage from "./common/HeaderImage";
import Forsidebillede from "../images/selvstaendige_header.jpg";

const Selvstaendige = props => (
    <div style={{ backgroundColor: 'white' }}>
        <HeaderImage imageSrc={Forsidebillede} />
        <Divider hidden />
        <Container text>
            <Segment basic>
                <h2 className="trompet">Tryghed og frihed til dig og din familie</h2>
                <h2 className="ui header" style={{ paddingTop: 0 }}>Letsikring til selvstændige</h2>
            </Segment>
        </Container>

        <Container text>

            <Segment basic>
                <p className="manchet">Er du selvstændig, sparer du sandsynligvis mindre op til pension end dine venner
                    og bekendte, der er lønmodtagere. Du er sandsynligvis heller ikke nær så godt sikret i tilfælde af
                    sygdom eller dødsfald.</p>

                <p>Måske skyldes det, at du som selvstændig ikke automatisk har en pensionsordning som de fleste
                    lønmodtagere. Måske skyldes det, at du har mulighed for at spare op i din virksomhed. Uanset årsag
                    er pension
                    ikke mindre vigtig, fordi du er selvstændig &ndash; måske nærmere tværtimod.</p>
                <h3>Kom nemt i gang</h3>
                <p>For at det skal være nemt for dig som selvstændig, tilbyder Letpension i samarbejde med PFA en samlet
                    pensionsordning med både opsparing og forsikringer. Vi kalder den Letsikring til
                    selvstændige.</p>
                <p>Din rådgiver i dit pengeinstitut hjælper dig med at få overblik og komme i gang &ndash; så kan du
                    koncentrere dig om at drive din virksomhed.</p>

                <h3>Stor fleksibilitet</h3>
                <p>Letsikring til selvstændige er fleksibel, og du har rig mulighed for at tilpasse den, så den passer
                    til dine behov og ønsker:</p>
                <ul>
                    <li>Vælg, hvor store dine dækninger skal være i tilfælde af sygdom og død.</li>
                    <li>Vælg mellem forskellige <Link to="/produktet/investering" className="link">risikoprofiler
                        for din
                        opsparing.</Link></li>
                    <li>Vælg, om du vil placere en del af din opsparing i <Link to="/klima" className="link">ekstra
                        klimavenlige
                        investeringer.</Link></li>
                    <li>Tilbyd, at din medarbejdende ægtefælle og dine ansatte også kan oprette ordningen (op til 2
                        personer udover dig).
                    </li>
                </ul>

                <br />
                <Table color="teal" inverted celled stackable>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell><b>Opsparing</b><br />(min. 500 kr./mdr. til Letsikring af indtægt ved
                                pension</Table.Cell>
                            <Table.Cell>Letsikring af indtægt ved pension</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Investering</b></Table.Cell>
                            <Table.Cell>Forskellige investeringskoncepter og risikoprofil</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Letsikring ved død (LTS)</b></Table.Cell>
                            <Table.Cell>300.000 - 3.000.000 kr.</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Letsikring ved sygdom (LTS)</b></Table.Cell>
                            <Table.Cell>150.000 - 1.000.000 kr.</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Letsikring ved kritisk sygdom (LTS)</b></Table.Cell>
                            <Table.Cell>150.000 - 750.000 kr.</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Præmiefritagelse (LTS)</b></Table.Cell>
                            <Table.Cell>Ja</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Letsikring af barn ved død (LTS)</b></Table.Cell>
                            <Table.Cell>Tilvalg 35.000 - 75.000 kr.</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><b>Letsikring af helbred m/u børn (LTS)</b></Table.Cell>
                            <Table.Cell>Tilvalg</Table.Cell>
                        </Table.Row>

                    </Table.Body>
                </Table>
                <p style={{ fontStyle: 'italic', fontSize: '14px' }}>For at du kan oprette Letsikring til selvstændige, skal du kunne afgive tilfredsstillende helbredsoplysninger.</p>

                <h3>Få glæde af de mange fordele</h3>
                <p>Som selvstændig kan du spare op i din virksomhed, men det kan give et godt sikkerhedsnet at supplere
                    med en pensionsordning. Der kan ske meget med din virksomhed, og du kan ikke være sikker på, at den
                    bliver den pensionsopsparing, du ønsker.</p>
                <p>Suppler opsparing i din virksomhed med en pensionsordning, så opnår du en lang række fordele.</p>
                <Divider hidden />

                <Grid centered stackable columns={2}>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="fordele-ikon" style={{ backgroundImage: `url(${IkonParaplySort})` }}></div>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <h4>Tryghed og færre bekymringer</h4>
                            <ul>
                                <li>Personlig pensionsrådgivning i dit pengeinstitut</li>
                                <li>Pension med livsvarige udbetalinger</li>
                                <li>Forsikringer til attraktive priser</li>
                                <li>Et stærkt sundhedskoncept med sundhedsforsikring og adgang til HealthGuides, som kan
                                    rådgive
                                    og hjælpe dig ved langtidssygefravær
                                </li>
                                <li>Beskyttelse af opsparing - pensionsopsparing er som udgangspunktkreditorbeskyttet fx
                                    i tilfælde
                                    af konkurs
                                </li>
                            </ul>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="fordele-ikon" style={{ backgroundImage: `url(${IkonNounMoney})` }}></div>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <h4>Økonomi og skattefordele</h4>
                            <ul>
                                <li>Mulighed for at udnytte de særlige fradragsmuligheder for selvstændige - spar ekstra
                                    op i gode
                                    år med fuldt fradrag
                                </li>
                                <li>Lavere beskatning af afkastet i din opsparing - 15,3 %</li>
                                <li>Skatteoptimering ved udbetaling ift. opsparing i virksomhed</li>
                                <li>Bedre likviditet som pensionist</li>
                            </ul>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="fordele-ikon" style={{ backgroundImage: `url(${IkonNounPeople})` }}></div>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <h4>Et attraktivt medarbejdergode</h4>
                            <ul>
                                <li>Tiltræk, og fasthold kvalificerede medarbejdere</li>
                                <li>Få professionel hjælp til forbygge eller forkorte en lang sygemelding</li>
                            </ul>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Divider hidden />

                <h3>Læs om dækningerne</h3>

                <AutoAccordion activeIndex={-1}>
                    <AutoAccordion.Item title="Letsikring af indtægt ved pension (LTS)">
                        Spar op i en pensionsordning, hvor du får en udbetaling, når du går på pension, og så længe du
                        lever - det giver en økonomisk tryghed. Du kan indbetale, lige så meget du vil, og som
                        selvstændige har du særlige muligheder for fradrag. Opsparingen bliver investeret i et
                        markedsrenteprodukt, hvor du kan vælge mellem forskellige investeringskoncepter og
                        risikoprofiler. Du har selv hele investeringsrisikoen på opsparingen, også efter du er gået på
                        pension. Efter du er gået på pension, afhænger udbetalingernes størrelse derudover af
                        levetidsudviklingen.</AutoAccordion.Item>
                    <AutoAccordion.Item title="Sikring af opsparing (LTS)">Du kan vælge at sikre din opsparing, så
                        opsparingen kommer dine efterladte til gode, hvis du dør, inden du går på pension. Du kan også
                        vælge at sikre dine efterladte i udbetalingsperioden, så de får en udbetaling i en fastsat
                        periode, hvis du dør, efter du er gået på pension.</AutoAccordion.Item>
                    <AutoAccordion.Item title="Letsikring ved sygdom (LTS)">
                        Få en løbende udbetaling, hvis du bliver alvorligt syg i længere tid og mister din
                        indtjening. Det giver ro til at komme ovenpå igen - måske med en afløser, mens du er syg.
                    </AutoAccordion.Item>
                    <AutoAccordion.Item title="Letsikring ved kritisk sygdom (LTS)">
                        Få et økonomisk pusterum, hvis du bliver ramt af en kritisk sygdom, fx visse former for
                        kræft. Brug beløbet til ekstra pleje, en ferie, ændringer i hjemmet eller orlov til din
                        partner i sygdomsperioden.
                    </AutoAccordion.Item>
                    <AutoAccordion.Item
                        title="Præmiefritagelse (LTS)">Sikrer at PFA overtager betalingen til din opsparing og dine
                        forsikringer på nær Letsiking af helbred (LTS), hvis du bliver syg og mister din
                        indtjening.</AutoAccordion.Item>
                    <AutoAccordion.Item title="Letsikring af helbred (LTS)">
                        Få hurtig behandling eller undersøgelse på hospitaler, klinikker, hos speciallæger mv. -
                        typisk behandling inden for 1-10 hverdage.
                    </AutoAccordion.Item>
                    <AutoAccordion.Item title="Earlycare">
                        Få hjælp fra første dag, hvis du får en sygemelding på 14 dage eller mere. Health Guides
                        hjælper og rådgiver dig, så du får sammensat et forløb, der er tilpasset dig og din
                        situation.
                    </AutoAccordion.Item>
                    <AutoAccordion.Item title="Letsikring ved død (LTS)">
                        Sikr dine nærmeste, når livet skal gå videre uden dig. Det kan give tryghed at vide, hvis
                        familien har råd til at blive boende i hjemmet og ikke er tvunget til at skulle flytte.
                    </AutoAccordion.Item>
                    <AutoAccordion.Item title="Letsikring af barn ved død (LTS)">Sørg for at dine børn får en løbende
                        udbetaling til de fylder 21 år, hvis du mister livet inden du går på
                        pension. </AutoAccordion.Item>
                </AutoAccordion>
            </Segment>

        </Container>
    </div>
);

export default Selvstaendige;
