import React from "react";
import {Container, Divider, Grid, Header} from "semantic-ui-react";
import LetsikringAfIndtagtVedPensionImg from "../../images/letsikringafindtaegtvedpension.jpg";
import LetsikringAfBarnVedDoed from "../../images/letsikringafbarnveddoed.jpg";
import LetsikringVedDoed from "../../images/letsikringveddoed.jpg";
import ImageLink from "../common/ImageLink";
import LetsikringVedSygdomImg from "../../images/letsikringvedsygdom.jpg";
import appRoutes from "../../routes/appRoutes";
import {Link} from "react-router-dom";


const LetsikringVedKritiskSygdom = props => (
    <div>
      <Header as="h2" textAlign="center">Letsikring ved kritisk sygdom</Header>
      <Divider hidden/>
      <Container text>
      {/*<Container text>*/}
      {/*  <Embed id="316497285" source="vimeo" active={true} autoplay={false}/>*/}
      {/*</Container>*/}
      <Divider hidden/>
      <p>Med en Letsikring ved kritisk sygdom kan du sikre dig et økonomisk råderum, hvis du bliver alvorligt syg.</p>
      <p>En Letsikring ved kritisk sygdom sikrer dig et skattefrit beløb, hvis du bliver ramt af en alvorlig sygdom som for eksempel kræft eller en hjerneblødning.</p>
      <p>Du kan bruge pengene, som du vil - for eksempel til at ændre dit hjem, så det passer til dine nye behov, eller tage på rekreation, hvis du trænger til
        det.</p>
          <p><Link to="/blivkunde" className="link">Se hvilke pengeinstitutter, der tilbyder Letsikringer</Link></p>
      </Container>
      <Divider section hidden/>

      <Grid centered stackable columns={2}>
        <Grid.Row>
          <ImageLink imageSrc={LetsikringVedSygdomImg} text="Letsikring ved sygdom" link={appRoutes.LETSIKRING_VED_SYGDOM}/>
          <ImageLink imageSrc={LetsikringAfIndtagtVedPensionImg} text="Letsikring af indtægt ved pension" link={appRoutes.LETSIKRING_AF_INDTAEGT_VED_PENSION}/>
        </Grid.Row>
        <Grid.Row>
          <ImageLink imageSrc={LetsikringAfBarnVedDoed} text="Letsikring af barn" link={appRoutes.LETSIKRING_AF_BARN_VED_DOED}/>
          <ImageLink imageSrc={LetsikringVedDoed} text="Letsikring ved død" link={appRoutes.LETSIKRING_VED_DOED}/>
        </Grid.Row>
      </Grid>

    </div>
);

export default LetsikringVedKritiskSygdom;
