import React from "react";
import {Button, Container, Divider, Grid, Header} from "semantic-ui-react";
import BrugDinForsikring from "../../images/IkonBrugDinForsikring.svg";
import IkonNounCoins from "../../images/IkonNounCoins.svg";
import appRoutes from "../../routes/appRoutes";
import CallForAction from "../common/CallForAction";

const TjekDinPension = props => (
    <div>
        <Header as="h2" textAlign="center">Tjek din pension</Header>
        <Divider hidden/>
        <Container text>

            <p>Har du pension eller forsikringer via Letpension, kan du se dine aftaler i Letportalen. Du kan fx se, hvor
                stor din opsparing er, hvad du betaler i
                omkostninger og hvilket afkast, du har fået.
            </p>
            <p>Har du spørgsmål eller brug for rådgivning? Så skal du kontakte din rådgiver i dit lokale pengeinstitut.
            </p>
            <p>Du skal også kontakte din rådgiver, hvis du har behov for at justere din aftale. Du kan nemlig løbende
                tilpasse din aftale, når der sker ændringer i
                dit liv.
            </p>

            <Button color="teal" as="a" href="https://www.letportal.dk/" target="blank">Tjek din pension</Button>
        </Container>

        <Divider hidden/>
        <Divider hidden/>


        <Divider hidden/>
        <Divider hidden/>

        <Grid centered stackable columns={3}>
            <Grid.Row>
                <CallForAction icon={BrugDinForsikring} color="#b9c35d" text="Brug din forsikring"
                               link={appRoutes.BRUG_DIN_FORSIKRING}/>
                <CallForAction icon={IkonNounCoins} color="#6bb8bc" text="Omkostninger"
                               link={appRoutes.OMKOSTNINGER}/>
            </Grid.Row>
        </Grid>

    </div>
);

export default TjekDinPension;
