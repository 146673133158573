import React from "react";
import {Container, Divider, Grid, Header, Responsive, Tab} from "semantic-ui-react";
import FindPengeinstitut from "../images/IkonHerfaarDuLetsikringer.svg";
import TjekDinPension from "../images/IkonTjekDinPension.svg";
import AutoAccordion from "./common/AutoAccordion";
import {Link} from "react-router-dom";
import withSizes from "react-sizes";
import appRoutes from "../routes/appRoutes";
import CallForAction from "./common/CallForAction";

const mapSizesToProps = ({width}) => ({
    tablet: width < Responsive.onlyTablet.minWidth,
});

const Faq = props => {
    const {tablet} = props;

    return (
        <div>
            <Header as="h2" textAlign="center">Ofte stillede spørgsmål</Header>
            <Divider hidden/>
            <Container text>

                <p>Her på siden kan du finde svar på hyppige spørgsmål.
                </p>
                <p>Finder du ikke svar på dit spørgsmål, kan du <b>kontakte din rådgiver i det pengeinstitut</b>, hvor
                    du har oprettet din pension eller forsikring.</p>

                <Divider section hidden/>
                <Tab
                    panes={
                        [
                            {
                                menuItem: {icon: "home", content: (tablet ? null : "Generelt"), key: "generelt"},
                                render: () =>
                                    <Tab.Pane>
                                        <AutoAccordion activeIndex={-1}>
                                            <AutoAccordion.Item title="Hvor kan jeg se mine aftaler?">
                                                Tjek din pension og dine forsikringer i Letportalen på <a
                                                className="link" href="http://www.letportal.dk" target="_blank"
                                                rel="noopener noreferrer">www.letportal.dk</a>.
                                            </AutoAccordion.Item>
                                            <AutoAccordion.Item title="Hvorfor er jeg kunde i PFA Pension?">Du er kunde
                                                i PFA Pension, fordi Letpension er pension- og forsikringsformidler, der
                                                formidler PFA-produkter.
                                            </AutoAccordion.Item>
                                            <AutoAccordion.Item title="Hvor kan jeg få rådgivning?">
                                                Du kan få rådgivning hos din personlige rådgiver i dit lokale
                                                pengeinstitut.
                                            </AutoAccordion.Item>
                                        </AutoAccordion>
                                    </Tab.Pane>
                            },
                            {
                                menuItem: {icon: "stethoscope", content: (tablet ? null : "Helbred"), key: "helbred"},
                                render: () =>
                                    <Tab.Pane>
                                        <AutoAccordion activeIndex={-1}>
                                            <AutoAccordion.Item
                                                title="Jeg kan ikke logge ind i PFA's elektroniske helbredsspørgeskema Lethelbred. Hvad skal jeg gøre?">Hvis
                                                du har problemer med at logge ind i Lethelbred, skal du kontakte din
                                                rådgiver i dit pengeinstitut.
                                            </AutoAccordion.Item>
                                            <AutoAccordion.Item
                                                title="Jeg har problemer med at svare på spørgsmål om mit helbred. Hvad skal jeg gøre?">
                                                Er du tvivl om, hvordan du svarer på spørgsmålene i Lethelbred, kan du
                                                få vejledning ved at ringe til PFA's helbredskonsulenter på
                                                tlf. 7080 5500.
                                            </AutoAccordion.Item>
                                            <AutoAccordion.Item
                                                title="Jeg har fået afslag på at oprette en forsikring. Hvorfor og er der noget, jeg kan gøre?">
                                                Hvis du har fået afslag og har brug for at tale med nogen om det, kan du
                                                ringe til PFA's helbredskonsulenter på tlf. 7080 5500. De
                                                kan
                                                uddybe, hvorfor du har fået afslag.
                                            </AutoAccordion.Item>
                                        </AutoAccordion>
                                    </Tab.Pane>
                            },
                            {
                                menuItem: {icon: "user", content: (tablet ? null : "Sygdom og død"), key: "sygdom"},
                                render: () =>
                                    <Tab.Pane>
                                        <AutoAccordion activeIndex={-1}>
                                            <AutoAccordion.Item title="Hvor tit skal jeg betale for mine forsikringer?">
                                                Letsikring ved død, Letsikring af Lån og kredit, Letsikring af barn ved
                                                død og Letsikring ved kritisk sygdom betales alle én gang
                                                om
                                                året i januar. Letsikring ved sygdom betales hver måned.
                                            </AutoAccordion.Item>
                                            <AutoAccordion.Item title="Hvornår skal jeg betale for mine forsikringer?">
                                                Letsikring ved død, Letsikring af Lån og kredit, Letsikring af barn ved
                                                død og Letsikring af kritisk sygdom betales alle i
                                                begyndelsen
                                                af januar. Letsikring ved sygdom betales i starten af hver måned.
                                            </AutoAccordion.Item>
                                            <AutoAccordion.Item title="Hvad koster en forsikring?">
                                                Prisen afhænger af størrelsen på den dækning, du ønsker og af dit
                                                helbred. Prisen for en Letsikring ved sygdom afhænger også af
                                                din
                                                alder og af dit erhverv. Dit pengeinstitut kan hjælpe dig med den
                                                præcise pris, når du har svaret på spørgsmål om dit helbred og
                                                dit
                                                erhverv via PFA's elektroniske helbredsspørgeskema Lethelbred.
                                            </AutoAccordion.Item>
                                            <AutoAccordion.Item
                                                title="Jeg har en Letsikring ved sygdom og er blevet syg. Hvad gør jeg?">
                                                Hvis du har en Letsikring ved sygdom og er blevet syg eller kommet ud
                                                for en ulykke, skal du kontakte PFAs skadecenter på tlf.
                                                7080 5500.
                                            </AutoAccordion.Item>
                                            <AutoAccordion.Item title="Hvordan anmelder jeg et dødsfald?">Som
                                                udgangspunkt behøver du ikke anmelde et dødsfald, da PFA får direkte
                                                besked fra CPR-registret. Dog får PFA ikke besked, hvis afdøde ikke er
                                                tilmeldt CPR-registret eller har
                                                adresse i
                                                udlandet. I så fald - eller hvis du er i tvivl - kan du kontakte PFAs
                                                udbetalingsafdeling på tlf. 7080 5500.
                                            </AutoAccordion.Item>
                                        </AutoAccordion>
                                    </Tab.Pane>
                            },
                            {
                                menuItem: {icon: "envelope", content: (tablet ? null : "Pension"), key: "pension"},
                                render: () =>
                                    <Tab.Pane>
                                        <AutoAccordion activeIndex={-1}>
                                            <AutoAccordion.Item title="Hvad får jeg i afkast på min pension?">
                                                Afkastet på din pension følger PFAs to investeringskoncepter: Ét, hvor
                                                du vælger mellem fire investeringsprofiler og
                                                ét,
                                                hvor du selv vælger fordelingen i fondene. Dit konkrete afkast kan du se
                                                ved at logge ind på Letportalen via "Log ind" i øverste
                                                højre
                                                hjørne her på siden, via din netbank og via www.letportal.dk. Du kan
                                                også se mere information om afkastet under <Link
                                                to={appRoutes.INVESTERING}> Investering </Link>.
                                            </AutoAccordion.Item>
                                        </AutoAccordion>
                                    </Tab.Pane>
                            },
                        ]
                    }
                />
            </Container>
            <Divider section hidden/>

            <Grid centered stackable columns={2}>
                <Grid.Row>
                    <CallForAction icon={FindPengeinstitut} color="#e49d3b" text="Her får du Letsikringer"
                                   link={appRoutes.BLIV_KUNDE}/>
                    <CallForAction icon={TjekDinPension} color="#b9c35d" text="Tjek din pension"
                                   link={appRoutes.TJEK_DIN_PENSION}/>
                </Grid.Row>
            </Grid>

        </div>
    );
}

export default withSizes(mapSizesToProps)(Faq);
