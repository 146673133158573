import React from "react";
import { Container, Button, Divider, Grid, Header} from "semantic-ui-react";
import FindPengeinstitut from "../../images/IkonHerfaarDuLetsikringer.svg";
import TjekDinPension from "../../images/IkonTjekDinPension.svg";
import appRoutes from "../../routes/appRoutes";
import CallForAction from "../common/CallForAction";

const Begunstigelse = props => (
    <div>
        <Container text>
            <Header as="h2" textAlign="center">Hvem skal have pengene, hvis du ikke skal?</Header>
            <Divider hidden/>

            <p>Når du opretter en livsforsikring, beslutter du, hvem du vil sikre med livsforsikringen. På samme måde er
                det vigtigt, at du tager stilling til, hvem
                der skal have din pensionsopsparing, hvis du dør, før du selv får den udbetalt.</p>
            <p>I lovgivningen er der regler for, hvem man kan vælge som modtager(e) af udbetalingen, det vil sige, hvem
                man kan vælge som begunstiget.</p>
            <p>Hvis du ønsker rådgivning om begunstigelse, skal du kontakte din rådgiver i dit pengeinstitut.</p>
            <p>Hvis du ønsker at foretage ændringer til din begunstigelse kan du gøre det digitalt via PFA, som er pensionsselskabet bag Letpension, eller ved at kontakte
                din rådgiver i dit pengeinstitut.</p>
            <Divider hidden />
            <Button color="teal" as="a" href="https://mit.pfa.dk/" target="blank">Foretag ændringer til din begunstigelse via PFA</Button>
            <Divider hidden />
        </Container>
        <Divider hidden/>
        <Divider hidden/>

        <Grid centered stackable columns={2}>
            <Grid.Row>
                <CallForAction icon={FindPengeinstitut} color="#e49d3b" text="Her får du Letsikringer"
                               link={appRoutes.BLIV_KUNDE}/>
                <CallForAction icon={TjekDinPension} color="#b9c35d" text="Tjek din pension"
                               link={appRoutes.TJEK_DIN_PENSION}/>
            </Grid.Row>
        </Grid>

        <Divider hidden/>
        <Divider hidden/>

    </div>
);

export default Begunstigelse;
