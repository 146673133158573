import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Button, Icon, Image, Menu, Popup, Responsive, Visibility} from "semantic-ui-react"
import PropTypes from "prop-types";
import "./DesktopContainer.css";
import logo from "../../images/letpension-logo.svg";
import logoSmall from "../../images/letpension-logo-small.svg";
import menuItems from "../../routes/menuItems";
import appRoutes from "../../routes/appRoutes";

const DesktopContainer = props => {
    const {children} = props;

    const [fixed, setFixed] = useState(false);
    const [windowWidth, setWindowWidth] = useState(0);

    const hideFixedMenu = () => setFixed(false);
    const showFixedMenu = () => setFixed(true);
    const onResize = (event, data) => {
        setWindowWidth(data.width);
    };

    return (
        <Responsive
            minWidth={1174}
            onUpdate={onResize}
            fireOnMount={true}>
            <Visibility
                once={false}
                onBottomPassed={showFixedMenu}
                onBottomPassedReverse={hideFixedMenu}
            >
                <Menu
                    className="TopMenu"
                    borderless
                    fixed={"top"}
                    style={{height: fixed ? 75 : 100}}
                >
                    {/*<Container>*/}
                    <Menu.Item position="left" as={Link} to={appRoutes.ROOT}>
                        <Image src={logo}
                               hidden={windowWidth < 1368}
                               width={fixed ? 180 : 250}
                               height={fixed ? 24 : 50}
                               style={{marginLeft: 50}}
                               className="Easing"
                        />
                        <Image src={logoSmall}
                               hidden={windowWidth >= 1368}
                               width={fixed ? 50 : 90}
                               height={fixed ? 50 : 90}
                               style={{marginLeft: 50}}
                               className="Easing"
                        />

                    </Menu.Item>

                    {renderMenu()}

                    <Menu.Item position="right" as={Link} to={appRoutes.FAQ}>
                        <span>Hjælp</span>
                    </Menu.Item>
                    <Menu.Item>
                        <Popup
                            trigger={<Button as="a" color="teal" style={{marginRight: 10}}>Log ind</Button>}
                            inverted
                            className="MenuPopup"
                            position="bottom right"
                            offset="0, 28px"
                            on='click'>
                            <Menu inverted vertical borderless color="teal">
                                <Menu.Item as="a" href="https://www.letportal.dk" target="_blank"
                                           style={{fontWeight: 600, zIndex: 2000}}
                                           rel="noopener noreferrer">
                                    Log ind som kunde &nbsp;<Icon size="small" name="arrow right"/>
                                </Menu.Item>
                                <Menu.Item as="a" href="https://www.letguide.dk" target="_blank"
                                           style={{fontWeight: 600, zIndex: 2000}}
                                           rel="noopener noreferrer">
                                    Log ind som rådgiver &nbsp;<Icon size="small" name="arrow right"/>
                                </Menu.Item>
                            </Menu>
                        </Popup>
                    </Menu.Item>
                    {/*</Container>*/}
                </Menu>
            </Visibility>
            <div style={{height: 60}}/>
            {children}
        </Responsive>
    )
}

const renderMenu = () => {
    return menuItems.map((item, itemKey) => {

        const mainItem = <Menu.Item as={Link} to={item.link} key={itemKey}>{item.text}</Menu.Item>;

        if (item.children && item.children.length > 0) {
            return <Popup
                trigger={mainItem}
                position="bottom left"
                className="MenuPopup"
                flowing
                wide
                hoverable
                inverted
                style={{width: 350}}
                key={itemKey}>
                <Menu vertical inverted borderless color="teal" style={{width: '100%'}}>
                    {
                        item.children.map((childItem, childItemKey) =>
                            <Menu.Item as={Link} to={childItem.link} key={childItemKey}
                                       style={{
                                           fontWeight: 'bold',
                                           width: '100%',
                                           whiteSpace: 'nowrap',
                                           zIndex: 2000
                                       }}>{childItem.text}</Menu.Item>
                        )
                    }
                </Menu>
            </Popup>
        } else {
            return mainItem;
        }
    });
}

DesktopContainer.propTypes = {
    children: PropTypes.node,
};

export default DesktopContainer;
