import React from "react";
import {Button, Container, Divider, Grid, Header} from "semantic-ui-react";
import FindPengeinstitut from "../../images/IkonHerfaarDuLetsikringer.svg";
import TjekDinPension from "../../images/IkonTjekDinPension.svg";
import appRoutes from "../../routes/appRoutes";
import CallForAction from "../common/CallForAction";

const Helbredsoplysninger = props => (
    <div>
        <Header as="h2" textAlign="center">Helbreds&shy;oplysninger</Header>
        <Divider hidden/>
        <Container text>

            <p>Din rådgiver i dit lokale pengeinstitut vil ofte bede dig om at svare på en række spørgsmål om dit
                helbred inden et møde om pension og forsikring.
            </p>
            <p>På den måde ved din rådgiver allerede på mødet, hvilke pensions- og forsikringsløsninger, der er mulige.
            </p>
            <p>Du svarer på spørgsmålene i en elektronisk helbredsportal. Din rådgiver må ikke hjælpe dig eller vide,
                hvad du svarer. Det er fortrolig information,
                som udelukkende du og PFA - livsforsikringsselskabet, som Letpension formidler pensions og forsikring
                for - kender. Din rådgiver får kun at vide, hvilke
                løsninger der er mulige.
            </p>
            <p>Har du brug for hjælp til at besvare spørgsmålene, eller har du spørgsmål til resultatet af dine svar,
                kan du kontakte PFA Helbredssupport på 7080 5500.</p>
            <p>Inden du kan svare på spørgsmål om dit helbred og erhverv, skal din rådgiver give dig adgang. </p>
            <Button color="teal" as="a" href="https://www.letportal.dk/" target="blank">Svar på spørgsmål om helbred og erhverv</Button>

        </Container>
        <Divider hidden/>
        <Divider hidden/>

        <Grid centered stackable columns={2}>
            <Grid.Row>
                <CallForAction icon={FindPengeinstitut} color="#e49d3b" text="Her får du Letsikringer"
                               link={appRoutes.BLIV_KUNDE}/>
                <CallForAction icon={TjekDinPension} color="#b9c35d" text="Tjek din pension"
                               link={appRoutes.TJEK_DIN_PENSION}/>
            </Grid.Row>
        </Grid>

        <Divider hidden/>
        <Divider hidden/>

    </div>
);

export default Helbredsoplysninger;
