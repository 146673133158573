import React from "react";
import {Container, Divider, Grid, Header} from "semantic-ui-react";
import LetsikringAfBarnVedDoed from "../../images/letsikringafbarnveddoed.jpg";
import LetsikringVedDoed from "../../images/letsikringveddoed.jpg";
import ImageLink from "../common/ImageLink";
import LetsikringVedSygdomImg from "../../images/letsikringvedsygdom.jpg";
import LetsikringVedKritiskSygdomImg from "../../images/letsikringvedkritisksygdom.jpg";
import appRoutes from "../../routes/appRoutes";
import {Link} from "react-router-dom";

const LetsikringAfIndtaegtVedPension = props => (
    <div>
        <Container text>
        <Header as="h2" textAlign="center">Letsikring af indtægt ved pension</Header>
        <Divider hidden/>

            {/*<Container text>*/}
            {/*  <Embed id="316497615" source="vimeo" active={true} autoplay={false}/>*/}
            {/*</Container>*/}
            <Divider hidden/>
            <p>Med en Letsikring af indtægt ved pension kan du sikre, at dine penge kommer dig og dine nærmeste til
                gode.</p>
            <p>En Letsikring af indtægt ved pension er det, nogle pensionsselskaber kalder en livrente. Den sikrer, at
                du får udbetalt penge, så længe du lever.</p>
            <p>Du kan vælge, at din ægtefælle eller samlever skal overtage udbetalingen, hvis du dør i perioden med
                udbetaling. Du kan også sikre, at din opsparing
                bliver udbetalt til dine efterladte, hvis du dør, før din udbetaling begynder.</p>
            <p>Hvis du gerne vil vide, hvilke omkostninger du betaler for at have en Letsikring af indtægt ved pension,
                kan du læse mere under ”Omkostninger”.</p>
            <p><Link to="/blivkunde" className="link">Se hvilke pengeinstitutter, der tilbyder Letsikringer</Link></p>
        </Container>
        <Divider section hidden/>

        <Grid centered stackable columns={3}>
            <Grid.Row>
                <ImageLink imageSrc={LetsikringVedSygdomImg} text="Letsikring ved sygdom"
                           link={appRoutes.LETSIKRING_VED_SYGDOM}/>
                <ImageLink imageSrc={LetsikringVedKritiskSygdomImg} text="Letsikring ved kritisk sygdom"
                           link={appRoutes.LETSIKRING_VED_KRITISK_SYGDOM}/>
            </Grid.Row>
            <Grid.Row>
                <ImageLink imageSrc={LetsikringAfBarnVedDoed} text="Letsikring af barn"
                           link={appRoutes.LETSIKRING_AF_BARN_VED_DOED}/>
                <ImageLink imageSrc={LetsikringVedDoed} text="Letsikring ved død" link={appRoutes.LETSIKRING_VED_DOED}/>
            </Grid.Row>
        </Grid>

    </div>
);

export default LetsikringAfIndtaegtVedPension;
