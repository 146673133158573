import React from "react";
import {Button, Container, Divider, Segment} from "semantic-ui-react";
import HeaderImage from "./common/HeaderImage";
import Forsidebillede from "../images/camilla-schjoelin-poulsen-navn.png";

const BegunstigelseLanding = props => (
    <div style={{backgroundColor: 'white'}}>
        <HeaderImage imageSrc={Forsidebillede}/>

        <Container text>
            <Segment basic>
                <p>Oktober 2022</p>
                {/*<h2 className="trompet">Tryghed og frihed til dig og din familie</h2>*/}
                <h2 className="ui header" style={{paddingTop:0}}>Du risikerer, at pensionen ender i de forkerte hænder</h2>
            </Segment>
        </Container>

        <Container text>

            <Segment basic>
                <p className="manchet">Mange danskere tager ikke stilling til, hvem der skal have udbetalingerne fra deres pensionsopsparing, hvis de dør før tid. Og så risikerer man, at udbetalingen lander i de forkerte hænder.</p>

                <p>Hvert år er der cirka 9.000 danskere, der dør inden de bliver pensioneret og dermed ikke selv får glæde af deres pensionsopsparing. Omkring 9 milliarder kr. af danskernes pension bliver hvert år udbetalt til de efterladte.</p>
                <p>Nye tal fra PFA viser, at kun 6,8 pct. af kunderne aktivt har valgt at sætte navn på den eller de personer, der skal arve deres pension. Det kan skyldes, at standardløsningen med ”nærmeste pårørende” passer perfekt til ens situation. Men det kan også være fordi, at man ikke har fået taget stilling, og i så fald risikerer man, at pensionspengene havner hos andre end dem, det var tiltænkt.</p>

                <h3>Nye regler sætter samlever før børn</h3>
                <p>Reglerne er nemlig sådan, at hvis man fx har børn fra et tidligere forhold og har fået en ny ægtefælle eller partner, så er det den nye ægtefælle eller partner, som arver hele pensionen – ikke ens børn.</p>
                <p>Ændringen trådte i kraft ved forsikringsaftaleloven i 2008, hvor det nye var, at en partner eller samlever, som man har boet sammen med i minimum to år, blev inkluderet som arvtager ved dødsfald. Tidligere var det udelukkende ægtefælle og børn.</p>

                <p>”Når cirka halvdelen af alle ægteskaber i Danmark ender med skilsmisse og en endnu højere andel af alle parhold opløses, skaber det forudsætninger for stridigheder, der ellers kunne være undgået. Arvereglerne for pension er anderledes strikket sammen end på andre områder. Derfor kan det være rigtig godt givet ud at bruge 10 minutter på at tage stilling til, hvem man ønsker, der skal arve ens pension, hvis man går bort,” forklarer Camilla Schjølin Poulsen, privatøkonom i PFA, og tilføjer:</p>

                <Divider hidden/>
                <p className="citat"><span className="citat-start">"</span>Tager man børn med sig ind i det nye ægteskab eller parhold, risikerer man altså, at hele ens pension havner hos den nye ægtefælle eller partner, uden at ens børn arver noget. Og det er altså for sent at gøre noget ved fordelingen, når man først er borte.</p>
                <Divider hidden/>

                <h3>Gamle regler kan give det omvendte problem</h3>
                <p>Omvendt står det ifølge PFA’s privatøkonom til, hvis man har en pensionsordning, der er oprettet før 2008. For så er det i stedet samleveren – og ikke børnene -, der risikerer at blive forbigået.</p>

                <p>”Reglerne blev lavet om for at sidestille samlevere og ægtefæller, da flere og flere i dag bor sammen uden at være gift. På den måde ønskede man at undgå situationer, hvor samlevere efterlades uden økonomisk hjælp efter mange års ægteskabslignende samliv. Men den risiko eksisterer altså stadig, hvis man har en ordning, der er oprettet før 2008, hvor de nye regler trådte i kraft. Så uanset hvornår man fik oprettet sin ordning, er det en god ide at tjekke, om det er de rigtige, der står til at få udbetalingen, siger Camilla Schjølin Poulsen.</p>

                <h3>Få rådgivning i dit pengeinstitut</h3>
                <p>På mitpfa.dk kan du med få klik vælge, hvem der kan arve din pension.</p>

                <Button color="teal" as="a" href="https://mit.pfa.dk/" target="blank">Vælg din begunstigelse</Button>
                <Divider hidden/>


                <p>Hvis du ønsker rådgivning om pension og begunstigelse, skal du kontakte din rådgiver i dit pengeinstitut.</p>
                </Segment>

            <Segment basic style={{backgroundColor: '#f6f0e7', color:'black'}}>
                <h4>Fakta om nærmeste pårørende</h4>
                <p>Medmindre man selv aktiv vælger noget andet, så vil udbetaling ske til nærmeste pårørende, som er defineret nedenfor </p>

                <h5>Nærmeste pårørende på pensioner oprettet efter 2008</h5>
                <ol>
                    <li>Din ægtefælle eller registrerede partner</li>
                    <li>Din samlever, hvis I bor sammen i et ægteskabslignende forhold og har gjort det i mindst to år - eller hvis I venter, har eller har haft barn sammens</li>
                    <li>Dine børn, børnebørn mv. (dine livsarvinger)</li>
                    <li>Dine arvinger i henhold til testamente eller arveloven</li>
                </ol>

                <p>NB. Den prioriterede rækkefølge skal forstås sådan, at hvis man både efterlader sig ægtefælle og børn, så arver ægtefællen hele opsparingen, idet de står først i rækken.</p>

                <h5>Nærmeste pårørende på pensioner oprettet før 2008</h5>
                <ul>
                    <li>Din ægtefælle eller registrerede partner</li>
                    <li>Dine børn, børnebørn mv. (dine livsarvinger)</li>
                    <li>Dine arvinger i henhold til testamente eller arveloven</li>
                </ul>

            </Segment>
            <Segment>
                <p>Letpension formidler pensions- og forsikringsløsninger til en lang række pengeinstitutter, og det er PFA Pension, som er pensionsselskabet bag Letpension. Det er derfor PFA Pension, der skal have oplysninger om, hvem du ønsker at begunstige. </p>
                <p>Hvis du ønsker rådgivning om begunstigelse, skal du kontakte din rådgiver i dit pengeinstitut.</p>


            </Segment>

        </Container>
    </div>
);

export default BegunstigelseLanding;
