import {
    HENT_NYHEDER,
    MODTAG_NYHEDER,
    INVALIDER_NYHEDER,
} from '../actions/nyheder.js'


export default function nyheder(
    state = {
        indlaeser: false,
        indlaest: false,
        invalideret: false,
        fejlmeddelelse: null,
        nyheder: null
    },
    action
) {
    switch (action.type) {
        case HENT_NYHEDER:
            return Object.assign({}, state, {
                indlaeser: true,
                indlaest: false,
                invalideret: false,
                fejlmeddelelse: null,
                nyheder: null,
            })
        case MODTAG_NYHEDER:
            return Object.assign({}, state, {
                indlaeser: false,
                indlaest: true,
                invalideret: false,
                fejlmeddelelse: action.fejlmeddelelse,
                nyheder: action.nyheder
            })
        case INVALIDER_NYHEDER:
            return Object.assign({}, state, {
                indlaeser: false,
                indlaest: false,
                fejlmeddelelse: null,
                invalideret: true,
                nyheder: null
            })
        default:
            return state;
    }
}


