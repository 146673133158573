import React from "react";
import {Container, Grid, Segment} from "semantic-ui-react";

const Footer = () => (
    <Segment vertical style={{backgroundColor:"#f6f0e7", padding: "25px 5px", marginTop:40}}>
      <Container>
        <Grid>
          {/*<Grid.Row>*/}
          {/*  <Grid columns={4} divided stackable>*/}
          {/*    <Grid.Row>*/}
          {/*      {menuItems.map((item, itemKey) => {*/}
          {/*            if (item.children && item.children.length > 0) {*/}
          {/*              return <FooterColumn headerText={item.text} linkTo={item.link} key={itemKey}>*/}
          {/*                {*/}
          {/*                  item.children.map((childItem, childItemKey) =>*/}
          {/*                      <FooterListItem linkTo={childItem.link} key={childItemKey}>{childItem.text}</FooterListItem>*/}
          {/*                  )*/}
          {/*                }*/}
          {/*              </FooterColumn>*/}
          {/*            }else{*/}
          {/*              return <FooterColumn headerText={item.text} linkTo={item.link} key={itemKey}/>;*/}
          {/*            }*/}
          {/*          }*/}
          {/*      )}*/}
          {/*    </Grid.Row>*/}
          {/*  </Grid>*/}
          {/*</Grid.Row>*/}
          <Grid.Row centered>
            <p>
              Letpension forsikringsformidling A/S  &#183;  Sundkrogsgade 4  &#183;  2100 København Ø  &#183;  CVR-nr 2940 1675<br/>Tel. 7070 2244 &#183; <a style={{color:'black'}} href="mailto:letpension@letpension.dk">letpension@letpension.dk</a>
            </p>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
);

export default Footer;
