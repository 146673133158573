import React, {useState} from "react";
import {Accordion, Container, Icon, Image, Menu, Responsive, Sidebar} from "semantic-ui-react"
import PropTypes from "prop-types";
import "./MobileContainer.css";
import logo from "../../images/letpension-logo.svg"
import {Link} from "react-router-dom";
import menuItems from "../../routes/menuItems";
import appRoutes from "../../routes/appRoutes";


const MobileContainer = props => {
  const {children} = props;

  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [sidebarHiding, setSidebarHiding] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleSidebarHide = () => {
    setSidebarOpened(false);
    setSidebarHiding(true);
  };

  const handleSidebarHidden = () => {
    setSidebarOpened(false);
    setSidebarHiding(false);
  };

  const handleToggle = () => {
    if (sidebarHiding === false) {
      setSidebarOpened(!sidebarOpened);
    }
  };

  const handleClick = (e, titleProps) => {
    const {index} = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  const renderMenu = (handleClick) => {
    return menuItems.map((item, index) => {

      if (item.children && item.children.length > 0) {
        return <Menu.Item to={item.link} key={index}>
          <Accordion.Title
              active={activeIndex === index}
              content={item.text}
              index={index}
              onClick={handleClick}
              style={{width: '100%'}}
          />
          <Accordion.Content active={activeIndex === index} style={{width: '100%'}}>
            <Menu vertical inverted color="teal" style={{width: 400}} >
              <Menu.Item style={{width: '100%'}} as={Link} to={item.link} key={index} onClick={handleSidebarHide}>{item.text}</Menu.Item>
              {
                item.children.map((childItem, childItemKey) =>
                    <Menu.Item style={{width: '100%'}} as={Link} to={childItem.link} key={childItemKey} onClick={handleSidebarHide}>{childItem.text}</Menu.Item>
                )
              }
            </Menu>
          </Accordion.Content>
        </Menu.Item>;

      } else {
        return <Menu.Item as={Link} to={item.link} style={{color: 'white'}} key={index} onClick={handleSidebarHide}>{item.text}</Menu.Item>;
      }
    });
  }

  return (
      <Responsive
          maxWidth={1173}
      >
        <Menu
            className="TopMenu"
            borderless
            fixed={"top"}
        >
          <Container>
            <Image src={logo}
                   width={200}
                   height={50}
                   style={{marginTop: 16, marginLeft:25}}
                   as={Link} to={appRoutes.ROOT}/>
            <Menu.Item position="right" onClick={handleToggle}>
              <Icon name="sidebar" size="large"/>
            </Menu.Item>

          </Container>
        </Menu>
        <Sidebar
            as={Menu}
            direction="right"
            inverted
            color="teal"
            width="wide"
            vertical
            borderless
            animation="overlay"
            onHide={handleSidebarHide}
            onHidden={handleSidebarHidden}
            visible={sidebarOpened}
        >
          <div style={{height: 60}}/>
          <Accordion as={Menu} inverted vertical color="teal" style={{width: 500}}>
            {renderMenu(handleClick)}
            <Menu.Item as={Link} to={appRoutes.FAQ} style={{color: 'white'}} key={9999} onClick={handleSidebarHide}>
              Hjælp
            </Menu.Item>
            <Menu.Item as="a" href="https://www.letportal.dk" target="_blank"
                       style={{fontWeight: 600, zIndex: 2000, marginTop:60}}
                       rel="noopener noreferrer">
              Log ind som kunde
            </Menu.Item>
            <Menu.Item as="a" href="https://www.letguide.dk" target="_blank"
                       style={{fontWeight: 600, zIndex: 2000}}
                       rel="noopener noreferrer">
              Log ind som rådgiver
            </Menu.Item>

          </Accordion>
        </Sidebar>
        <Sidebar.Pusher>
          <div style={{height: 30}}/>
          {children}
        </Sidebar.Pusher>
      </Responsive>
  )

}

MobileContainer.propTypes = {
  children: PropTypes.node,
};

export default MobileContainer;
