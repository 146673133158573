import React from "react";
import {Container, Divider, Grid, Header} from "semantic-ui-react";
import LetsikringVedKritiskSygdomImg from "../../images/letsikringvedkritisksygdom.jpg";
import LetsikringAfIndtagtVedPensionImg from "../../images/letsikringafindtaegtvedpension.jpg";
import LetsikringAfBarnVedDoed from "../../images/letsikringafbarnveddoed.jpg";
import LetsikringVedDoed from "../../images/letsikringveddoed.jpg";
import ImageLink from "../common/ImageLink";
import appRoutes from "../../routes/appRoutes";
import {Link} from "react-router-dom";


const LetsikringAfHelbred = props => (
    <div>
        <Header as="h2" textAlign="center">Letsikring af helbred</Header>
        <Divider hidden/>
        <Container text>

            {/*<Container text style={{textAlign: "center"}}>*/}
            {/*        <Image src={LetsikringAfHelbredImg} style={{width:'auto'}}/>*/}
            {/*</Container>*/}
            <Divider hidden/>
            <p>Med en Letsikring af helbred får du adgang til hurtig undersøgelse og behandling, hvis du får smerter,
                bliver
                syg eller kommer ud for en skade.</p>
            <p>
                Du får bl.a. adgang til:
                <ul>
                    <li>hurtig undersøgelse, behandling og operation på privathospitaler eller hos speciallæge</li>
                    <li>behandlinger hos fysioterapeut, osteopat, kiropraktor, psykolog, psykiater, akupunktør og
                        zoneterapeut
                    </li>
                    <li>behandling af ludomani og misbrug</li>
                    <li>og meget mere.</li>
                </ul>
            </p>
            <p>Du har mulighed for at forsikre dine og din ægtefælles/samlevers børn, frem til de fylder 24 år.</p>
            <p><Link to="/blivkunde" className="link">Se hvilke pengeinstitutter, der tilbyder Letsikringer</Link></p>
        </Container>
        <Divider section hidden/>

        <Grid centered stackable columns={2}>
            <Grid.Row>
                <ImageLink imageSrc={LetsikringVedKritiskSygdomImg} text="Letsikring ved kritisk sygdom"
                           link={appRoutes.LETSIKRING_VED_KRITISK_SYGDOM}/>
                <ImageLink imageSrc={LetsikringAfIndtagtVedPensionImg} text="Letsikring af indtægt ved pension"
                           link={appRoutes.LETSIKRING_AF_INDTAEGT_VED_PENSION}/>
            </Grid.Row>
            <Grid.Row>
                <ImageLink imageSrc={LetsikringAfBarnVedDoed} text="Letsikring af barn"
                           link={appRoutes.LETSIKRING_AF_BARN_VED_DOED}/>
                <ImageLink imageSrc={LetsikringVedDoed} text="Letsikring ved død" link={appRoutes.LETSIKRING_VED_DOED}/>
            </Grid.Row>
        </Grid>

    </div>
);

export default LetsikringAfHelbred;
