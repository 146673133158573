import React from "react";
import {Segment} from "semantic-ui-react";
import PropTypes from "prop-types";

const MiniBox = props => (
    <Segment color="teal" size="mini">
      {props.children}
    </Segment>
);

MiniBox.propTypes = {
  children: PropTypes.node.isRequired
};


export default MiniBox;
