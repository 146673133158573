import ApiUtils from '../../util/ApiUtils.js'
import AppConfig from '../../app-config';
// import mockNyheder from '../../data/nyheder.mock.json';

export const HENT_NYHEDER = 'HENT_NYHEDER';
export const MODTAG_NYHEDER = 'MODTAG_NYHEDER';
export const INVALIDER_NYHEDER = 'INVALIDER_NYHEDER';


export function hentNyheder() {
    return {
        type: HENT_NYHEDER
    }
}

function modtagNyheder(state, json, fejl) {
    return {
        type: MODTAG_NYHEDER,
        nyheder: json,
        receivedAt: Date.now(),
        fejlmeddelelse: fejl
    }
}


export function indlaesNyheder() {

    return (dispatch, getState) => {

        dispatch(hentNyheder())
        let url = AppConfig.apiRoot + 'nyheder';

        // dispatch(modtagNyheder(getState(), mockNyheder), null);

        return fetch(url,
            {
                method: 'GET'
            },
        )
            .then(ApiUtils.checkStatus)
            .then(response =>
                    response.json()
                        .then(json => {
                            dispatch(modtagNyheder(getState(), json), null)
                        })
                ,error => {
                    dispatch(modtagNyheder(getState(), null, 'Fejl ved indlæsning af nyheder'))
                })
    }
}


