import React from "react";
import {Card, Divider, Header, Image, Loader} from "semantic-ui-react";
import {useDispatch, useSelector} from 'react-redux';
import {indlaesMedarbejdere} from '../../redux/actions/medarbejdere.js';

const Medarbejdere = props => {

  const data = useSelector(state => state.medarbejdere);
  const dispatch = useDispatch();

  if (!data.indlaest && !data.indlaeser)
    dispatch(indlaesMedarbejdere());


  const renderMedarbejderListe = () => {
    let medarbejderListe = null;
    if (data.medarbejdere && data.medarbejdere.map) {
      medarbejderListe = data.medarbejdere.map(function (MedarbejderModel, index) {
        return (!MedarbejderModel.stilling.toLowerCase().includes('direktør')) &&
            <MedarbejderKasse medarbejder={MedarbejderModel} key={index}/>;
      });
    }
    return medarbejderListe;
  }

  return (
      <div>
        <Header as="h2" textAlign="center">Medarbejdere</Header>
        <Divider hidden/>
        <Loader active={data.indlaeser} style={{top: 200}}/>
        <Card.Group centered>
          {renderMedarbejderListe()}
        </Card.Group>
      </div>
  );
}

const MedarbejderKasse = props => {
  const {medarbejder} = props;
  return (
      <Card style={{width: 250, textAlign: "center"}}>
        <Image src={"data:image/jpeg;base64," + medarbejder.billede} width={250} height={250}/>
        <Card.Content>
          <Card.Header>{medarbejder.navn}</Card.Header>
          <Card.Meta>
            {medarbejder.stilling}<br/>
              <div style={{paddingTop:5}}>{medarbejder.email}</div>
          </Card.Meta>
        </Card.Content>
      </Card>
  );
}

export default Medarbejdere;
