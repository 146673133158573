import React from "react";
import {Container, Divider, Grid, Header} from "semantic-ui-react";
import LetsikringAfIndtagtVedPensionImg from "../../images/letsikringafindtaegtvedpension.jpg";
import LetsikringVedDoed from "../../images/letsikringveddoed.jpg";
import ImageLink from "../common/ImageLink";
import LetsikringVedSygdomImg from "../../images/letsikringvedsygdom.jpg";
import LetsikringVedKritiskSygdomImg from "../../images/letsikringvedkritisksygdom.jpg";
import appRoutes from "../../routes/appRoutes";
import {Link} from "react-router-dom";

const LetsikringAfBarnVedDoed = props => (
    <div>
        <Header as="h2" textAlign="center">Letsikring af barn ved død</Header>
        <Divider hidden/>
        <Container text>

            {/*<Container text>*/}
            {/*  <Embed id="316497712" source="vimeo" active={true} autoplay={false}/>*/}
            {/*</Container>*/}
            <Divider hidden/>
            <p>Med en Letsikring af barn ved død kan du sikre en udbetaling til dit barn, hvis du dør, inden barnet
                fylder 21 år.</p>
            <p>En Letsikring af barn ved død er det, nogle pensionsselskaber kalder en børnerente. Den sikrer, at dit
                barn får udbetalt penge, indtil han eller hun
                fylder 21 år, hvis du dør inden udgangen af det år, hvor du fylder 60 år.</p>
            <p>Du kan oprette Letsikringen både til dine egne børn, til din samlevers børn og til adoptivbørn. Har du
                flere børn, kan du oprette en Letsikring til
                hvert barn.</p>
            <p><Link to="/blivkunde" className="link">Se hvilke pengeinstitutter, der tilbyder Letsikringer</Link></p>
        </Container>
        <Divider section hidden/>

        <Grid centered stackable columns={2}>
            <Grid.Row>
                <ImageLink imageSrc={LetsikringVedKritiskSygdomImg} text="Letsikring ved kritisk sygdom"
                           link={appRoutes.LETSIKRING_VED_KRITISK_SYGDOM}/>
                <ImageLink imageSrc={LetsikringAfIndtagtVedPensionImg} text="Letsikring af indtægt ved pension"
                           link={appRoutes.LETSIKRING_AF_INDTAEGT_VED_PENSION}/>
            </Grid.Row>
            <Grid.Row>
                <ImageLink imageSrc={LetsikringVedSygdomImg} text="Letsikring ved sygdom"
                           link={appRoutes.LETSIKRING_VED_SYGDOM}/>
                <ImageLink imageSrc={LetsikringVedDoed} text="Letsikring ved død" link={appRoutes.LETSIKRING_VED_DOED}/>
            </Grid.Row>
        </Grid>

    </div>
);

export default LetsikringAfBarnVedDoed;
