import PropTypes from "prop-types";
import React from "react";
import DesktopContainer from "./DesktopContainer";
import MobileContainer from "./MobileContainer";

const ResponsiveContainer = ({children}) => (
    <div>
      <DesktopContainer className="desktop">{children}</DesktopContainer>
      <MobileContainer className="mobile">{children}</MobileContainer>
    </div>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

export default ResponsiveContainer;
