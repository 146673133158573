import React from "react";
import {Button, Container, Divider, Grid, Header} from "semantic-ui-react";
import FindPengeinstitut from "../../images/IkonHerfaarDuLetsikringer.svg";
import TjekDinPension from "../../images/IkonBrugDinForsikring.svg";
import appRoutes from "../../routes/appRoutes";
import CallForAction from "../common/CallForAction";

const ForKunder = props => (
    <div>

        <Container text>
            <Header as="h2" textAlign="center">Tal Letpension med din lokale rådgiver</Header>
            <Divider hidden/>
            <p>Er du kunde i et af de pengeinstitutter, der er med i Letpension-samarbejdet, kan du kontakte din
                rådgiver
                for at drøfte pension og forsikring.</p>
            <p>Skifter du pengeinstitut, kan du altid tage din Letsikring med dig, så længe du skifter til et af de
                andre
                pengeinstitutter i
                Letpension-samarbejdet.</p>
            <p>Er du ikke kunde i et af de mange pengeinstitutter og vil du gerne vide mere om, hvad Letpension kan
                tilbyde,
                er du velkommen til at kontakte et
                pengeinstitut i nærheden af dig, der er med i samarbejdet.</p>

            <Button color="teal" as="a" href="https://www.letportal.dk/" target="blank">Tjek din pension</Button>
        </Container>

        <Divider hidden/>
        <Divider hidden/>

        <Grid centered stackable columns={2}>
            <Grid.Row>
                <CallForAction icon={FindPengeinstitut} color="#e49d3b" text="Her får du Letsikringer"
                               link={appRoutes.BLIV_KUNDE}/>
                <CallForAction icon={TjekDinPension} color="#b9c35d" text="Letsikringer"
                               link={appRoutes.PRODUKTET}/>
            </Grid.Row>

            <Divider hidden/>
            <Divider hidden/>

        </Grid>

    </div>
);

export default ForKunder;
