import React from "react";
import {Container, Divider, Grid, Header} from "semantic-ui-react";
import appRoutes from "../../routes/appRoutes";
import {Link} from "react-router-dom";
import CallForAction from "../common/CallForAction";
import IkonNounCheckmark from "../../images/IkonNounCheckmark.svg";
import IkonNounCoins from "../../images/IkonNounCoins.svg";


const LetsikringTilSelvstaendige = props => (
    <div>
        <Header as="h2" textAlign="center">Letsikring til selvstændige</Header>
        <Container text>

            <Divider hidden/>
            <p>Letsikring til selvstændige er en samlet pensionsordning med både opsparing og forsikringer til dig, som er selvstændig. Du får desuden adgang til et stærkt sundhedskoncept, hvor du blandt andet kan få professionel hjælp, hvis du er i risiko for en længere sygemelding. </p>
            <br/>
            <p><b>Stor fleksibilitet – tilpas ordningen efter dine ønsker og behov</b></p>
            <p>Letsikring til selvstændige er fleksibel, og du har rig mulighed for at tilpasse ordningen, så den passer til dine ønsker og behov. Du vælger fx selv, hvor store dine dækninger skal være i tilfælde af sygdom og død. Du kan også vælge mellem forskellige investeringskoncepter og investeringsprofiler, og om du ønsker at investere ekstra klimavenligt.</p>
            <br/>
            <p><b>Tiltræk, og fasthold medarbejdere</b></p>
            <p>Det er ikke kun dig, som kan få glæde af Letsikring til selvstændige. Du kan tilbyde, at en medarbejdende ægtefælle og ansatte også kan oprette ordningen (op til 2 personer udover dig).</p>
            <p>En pensionsordning er et attraktivt medarbejdergode, som både kan være med til at tiltrække og fastholde dygtige medarbejdere.</p>
            <br/>
            <p><b>Få rådgivning om Letsikring til selvstændige i dit pengeinstitut</b></p>
            <p>Du kan få Letsikring til selvstændige i en række af de pengeinstitutter, der er i Letpension-samarbejdet. Kontakt dit pengeinstitut og hør, om de tilbyder Letsikring til selvstændige. </p>
            <p><Link to="/blivkunde" className="link">Se liste over pengeinstitutter i Letpension-samarbejdet</Link></p>
            <p><Link to="/selvstaendige" className="link">Læs mere om Letsikring til selvstændige</Link></p>
        </Container>
        <Divider section hidden/>


        <Grid centered stackable columns={2}>
            <Grid.Row>
                <CallForAction icon={IkonNounCheckmark} color="#b9c35d" text="Forbered dit møde"
                               link={appRoutes.MOEDEFORBEREDELSE}/>
                <CallForAction icon={IkonNounCoins} color="#6bb8bc" text="Omkostninger"
                               link={appRoutes.OMKOSTNINGER}/>

            </Grid.Row>
        </Grid>
    </div>
);

export default LetsikringTilSelvstaendige;
