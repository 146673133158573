import React from "react";
import {Container, Divider, Grid, Segment} from "semantic-ui-react";
import IkonSparegrisSort from "../images/IkonSparegrisSort.svg";
import IkonNounPeople from "../images/IkonNounPeople.svg";
import IkonParaplySort from "../images/IkonParaplySort.svg";
import IkonVaekstSort from "../images/IkonVaekstSort.svg";
import IkonHeart from "../images/IkonNounHeart.svg";
import "./Selvstaendige.css"
import HeaderImage from "./common/HeaderImage";
import Forsidebillede from "../images/pensionsdagen2023.jpg";

const Pensionsdagen = props => (
    <div style={{backgroundColor: 'white'}}>
        <HeaderImage imageSrc={Forsidebillede}/>
        <Container text>
            <Segment basic>
                <p>Oktober 2023</p>
                <h2 className="trompet">FÅ OVERBLIK OVER DIN PENSION</h2>
                <h2 className="ui header" style={{paddingTop: 0}}>Du får en time ekstra, men det tager kun nogle få minutter</h2>
            </Segment>
        </Container>

        <Container text>

            <Segment basic>
                <p className="manchet">I en travl hverdag kan det være svært at finde tid til at få overblik over sin pensionsopsparing. Selv med de bedste intentioner, er der bare meget andet, der presser sig på i dagligdagen. Kender du det?</p>

                <p>Hvis tid er forklaringen, så er det oplagt at bruge Pensionsdagen søndag den 29. oktober 2023 på at få overblik. Det er den dag, sommertiden slutter, og vi får en time foræret. Brug den på at få overblik.</p>
                <p>På PensionsInfo.dk er det nemt og hurtigt – og bare rolig, det behøver ikke tage en time, og der er hjælp at hente.</p>

                <h3>Få hjælp af din rådgiver i dit pengeinstitut</h3>
                <p>Med MitID i hånden og med få klik på computeren kan du på PensionsInfo.dk danne en rapport, som du kan sende til din rådgiver i dit pengeinstitut. Det tager ikke mere end et par minutter. Så kan du efterfølgende sammen med din rådgiver kigge på, om din opsparing rækker til dine ønsker for tilværelsen som pensionist, og om du har de rigtige forsikringsdækninger.</p>
                <p>TIP! Hvis du logger på PensionsInfo fra dit pengeinstitut, kan du sende rapporten direkte til dem.</p>

                <h3>Pension er også forsikring</h3>
                <p>Pension er de penge, du hver måned sparer op, men en pensionsordning indeholder ofte også forsikringsdækninger, fx hvis du mister din erhvervsevne, hvis du bliver syg, eller hvis du dør. Disse dækninger kan du også se på PensionsInfo.dk.</p>

                <h3>Værdien af at begynde tidligt</h3>
                <p>Jo tidligere du begynder at spare op, jo mere vil afkastet arbejde for dig. Selv små beløb vokser sig store over tid på grund af renters rente-effekten, og det kan være med til at sikre, at der er råd til alt det, man drømmer om senere i livet.</p>

                <Divider hidden/>
                <div className="citat">Kan du undvære 1.000 kr. månedligt til en pensionsopsparing, vil du efter 10 år have en opsparing på 225.000 kr., hvoraf 21 % stammer fra det forventede afkast. Venter du i stedet 30 år, så er opsparingen på 1.085.000 kr., og det et nu hele 51 % af opsparingen, der stammer fra det forventede afkast. Renters rente-effekten er bare ret fantastisk over tid.*
                </div>
                <Divider hidden/>

                <p style={{fontSize: 15}}>* Forudsætninger for beregning<br/>
                    Investeret i PFA Plus profil C med over 15 år til pension. Forventet afkast: samfundsforudsætninger 1. halvår 2023 jf. Rådet for Afkastforventninger. Forventet inflation: 2 %. Investeringsomkostninger medregnes. Summen er forventet saldo før skat. Afkastet geninvesteres og beskattes med 15,3 % løbende. Renters rente er regnet ud fra nettoafkastet dvs. efter omkostninger og pensionsafkastskat.</p>

                <h3>Tips til få overblik</h3>

                <Divider hidden/>

                <Grid centered stackable columns={2}>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="fordele-ikon" style={{backgroundImage: `url(${IkonSparegrisSort})`}}></div>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <h4>Indbetaler du nok?</h4>
                            <p>Ønsker du at kunne gå ned i tid, inden du skal på pension? Ønsker du at gå tidligere på pension? Ønsker du at sikre din levestandard, når pensionsalderen bliver en realitet? Hvad end dine planer og drømme er, giver det tryghed at vide, om økonomien rækker. Jo tidligere du får overblik, jo flere handlemuligheder har du.</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="fordele-ikon" style={{backgroundImage: `url(${IkonParaplySort})`}}></div>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <h4>Hvordan er dit sikkerhedsnet?</h4>
                            <p>Har du overblik over dine forsikringer, og ved du, hvornår og hvordan de dækker dig? Pension er også forsikringer, der dækker dig, hvis du bliver syg eller kommer til skade. Disse forsikringer giver en god tryghed, og med et økonomisk sikkerhedsnet i ryggen kan du bruge energien på det, der er vigtigst i hverdagen.</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="fordele-ikon" style={{backgroundImage: `url(${IkonHeart})`}}></div>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <h4>Kender du fordelene af at have en sundhedsforsikring?</h4>
                            <p>Hvis du ikke allerede har en sundhedsforsikring, kan du med fordel oprette en. Med den har du mulighed for at få hjælp til behandling eller udredning, hvis du får gener, bliver syg eller får en skade. Og du kan også få hjælp, hvis du har et barn, som mistrives. </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="fordele-ikon" style={{backgroundImage: `url(${IkonVaekstSort})`}}></div>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <h4>Har du den rette investeringsprofil? </h4>
                            <p>Den rette investeringsprofil er den, der giver ro i maven. Der er altid en risiko ved at investere, fordi markederne går op og ned, og derfor handler det om at mærke efter, hvilken risiko du er villig til at løbe i forhold til det afkast, du har mulighed for at få. Det er dog værd at bemærke, at pensionsopsparing er langsigtet, så vær tro mod din investeringsstrategi fremfor at forsøge at time de ofte uforudsigelige udsving på markederne.</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="fordele-ikon" style={{backgroundImage: `url(${IkonNounPeople})`}}></div>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <h4>Hvem skal have opsparingen, hvis du dør før tid?</h4>
                            <p>Har du taget stilling til, hvem der skal have udbetalingerne fra din pensionsopsparing, hvis du dør før tid? Hvert år dør ca. 9.000 danskere, inden de bliver pensioneret, og dermed får de ikke selv glæde af deres pensionsopsparing. Omkring 9 milliarder kr. bliver hvert år udbetalt til de efterladte. Derfor er det en god ide at tjekke, om det er de rigtige personer, der står til at få pensionen.</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Divider hidden/>

            <Segment basic style={{backgroundColor: '#f6f0e7', color: 'black'}}>
                <h5>Pensionsdagen</h5>
                <p>Den sidste søndag i oktober holder en samlet pensionsbranche kampagnen Pensionsdagen. Kampagnen er siden 2015 blevet holdt den dag, vi går fra sommertid til normaltid og får en ekstra time i døgnet - en time man kan bruge på at tjekke sine pensionsforhold.</p>
                <p>Pensionsdagen sætter fokus på pension, og branchen opfordrer danskerne til at tjekke deres pensioner. Der kan være behov for at justere sin pensionsordning, når ens livssituation ændrer sig, når man fx får børn, bliver gift eller skilt, skifter bolig osv. Og det er der desværre mange, som glemmer. Her er Pensionsdagen en god anledning til at få kigget sin ordning igennem.</p>
            </Segment>

        </Container>
    </div>
);

export default Pensionsdagen;
