import ApiUtils from '../../util/ApiUtils.js'
import AppConfig from '../../app-config';
// import mockSatser from '../../data/satser.mock.json';

export const HENT_SATSER = 'HENT_SATSER';
export const MODTAG_SATSER = 'MODTAG_SATSER';
export const INVALIDER_SATSER = 'INVALIDER_SATSER';


export function hentSatser() {
  return {
    type: HENT_SATSER
  }
}

function modtagSatser(state, json, fejl) {
  return {
    type: MODTAG_SATSER,
    satser: json,
    receivedAt: Date.now(),
    fejlmeddelelse: fejl
  }
}


export function indlaesSatser() {

  return (dispatch, getState) => {

    dispatch(hentSatser());
    let url = AppConfig.apiRoot + 'satser';

    // dispatch(modtagSatser(getState(), mockSatser), null);

    return fetch(url,
        {
          method: 'GET'
        },
    )
        .then(ApiUtils.checkStatus)
        .then(response =>
                response.json()
                    .then(json => {
                      dispatch(modtagSatser(getState(), json), null)
                    })
            , error => {
              dispatch(modtagSatser(getState(), null, 'Fejl ved indlæsning af satser'));
            })
  }
}


