import React from "react";
import {Grid, Image, Segment} from "semantic-ui-react";
import "./ImageLink.css";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const ImageLink = props => {
  if (props.link.startsWith("http"))
    return <Grid.Column style={{maxWidth: 383}}>
      <a href={props.link} target="_blank" rel="noopener noreferrer">
        <Segment className="ImageLink">
          <Image src={props.imageSrc} fluid/>
          <div className="ImageLinkText">{props.text}</div>
        </Segment>
      </a>
    </Grid.Column>
  else
    return <Grid.Column style={{maxWidth: 383}}>
      <Link to={props.link}>
        <Segment className="ImageLink">
          <Image src={props.imageSrc} fluid/>
          <div className="ImageLinkText">{props.text}</div>
        </Segment>
      </Link>
    </Grid.Column>

};

ImageLink.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};


export default ImageLink;
