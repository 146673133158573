import React from "react";
import {Checkbox} from "semantic-ui-react";
import checkedImg from '../../images/checked.png'
import uncheckedImg from '../../images/unchecked.png'

//Hack to detect IE
const isIE = /*@cc_on!@*/false || !!document.documentMode;


const CompatibleCheckbox = (props) => {
  const {name, checked, onChange} = props;

  const onCheckboxClick = () => {
    onChange(null, {
      name,
      checked: !checked,
      type: 'checkbox',
      value: !checked
    })
  }

  if (!isIE)
    return <Checkbox {...props}/>

  let img = uncheckedImg;
  if (checked)
    img = checkedImg;
  return <div style={{cursor: "pointer"}} onClick={onCheckboxClick}><img height={17} width={17} src={img} alt=""/></div>
}

export default CompatibleCheckbox;
