import React from "react";
import Omkostninger from "./Omkostninger";

export function OmkostningerUdvidet() {
  return (
      <Omkostninger maxDepotVardi={99900000} udvidet={true}/>
  )
}

export default OmkostningerUdvidet;

