import ApiUtils from '../../util/ApiUtils.js'
import AppConfig from '../../app-config';
// import mockJobopslag from '../../data/jobopslag.mock.json';


export const HENT_JOBOPSLAG = 'HENT_JOBOPSLAG';
export const MODTAG_JOBOPSLAG = 'MODTAG_JOBOPSLAG';
export const INVALIDER_JOBOPSLAG = 'INVALIDER_JOBOPSLAG';


export function hentJobopslag() {
  return {
    type: HENT_JOBOPSLAG
  }
}

function modtagJobopslag(state, json, fejl) {
  return {
    type: MODTAG_JOBOPSLAG,
    jobopslag: json,
    receivedAt: Date.now(),
    fejlmeddelelse: fejl
  }
}


export function indlaesJobopslag() {

  return (dispatch, getState) => {

    dispatch(hentJobopslag())
    let url = AppConfig.apiRoot + 'jobopslag';

    // dispatch(modtagJobopslag(getState(), mockJobopslag), null);

    return fetch(url,
        {
          method: 'GET'
        },
    )
        .then(ApiUtils.checkStatus)
        .then(response =>
                response.json()
                    .then(json => {
                      dispatch(modtagJobopslag(getState(), json), null)
                    })
            ,error => {
              dispatch(modtagJobopslag(getState(), null, 'Fejl ved indlæsning af jobopslag'))
            })

  }
}


