import {HENT_JOBOPSLAG, INVALIDER_JOBOPSLAG, MODTAG_JOBOPSLAG,} from '../actions/jobopslag.js'


export default function bestyrelse(
    state = {
      indlaeser: false,
      indlaest: false,
      invalideret: false,
      fejlmeddelelse: null,
      jobopslag: null
    },
    action
) {
  switch (action.type) {
    case HENT_JOBOPSLAG:
      return Object.assign({}, state, {
        indlaeser: true,
        indlaest: false,
        invalideret: false,
        fejlmeddelelse: null,
        jobopslag: null,
      })
    case MODTAG_JOBOPSLAG:
      return Object.assign({}, state, {
        indlaeser: false,
        indlaest: true,
        invalideret: false,
        fejlmeddelelse: action.fejlmeddelelse,
        jobopslag: action.jobopslag
      })
    case INVALIDER_JOBOPSLAG:
      return Object.assign({}, state, {
        indlaeser: false,
        indlaest: false,
        fejlmeddelelse: null,
        invalideret: true,
        jobopslag: null
      })
    default:
      return state;
  }
}


