import {HENT_SATSER, INVALIDER_SATSER, MODTAG_SATSER,} from '../actions/satser.js'


export default function satser(
    state = {
      indlaeser: false,
      indlaest: false,
      invalideret: false,
      fejlmeddelelse: null,
      satser: null
    },
    action
)
{
  switch (action.type) {
    case HENT_SATSER:
      return Object.assign({}, state, {
        indlaeser: true,
        indlaest: false,
        invalideret: false,
        fejlmeddelelse: null,
        satser: null,
      });
    case MODTAG_SATSER:
      return Object.assign({}, state, {
        indlaeser: false,
        indlaest: true,
        invalideret: false,
        fejlmeddelelse: action.fejlmeddelelse,
        satser: action.satser
      });
    case INVALIDER_SATSER:
      return Object.assign({}, state, {
        indlaeser: false,
        indlaest: false,
        fejlmeddelelse: null,
        invalideret: true,
        satser: null
      });
    default:
      return state;
  }
}


