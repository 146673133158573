import appRoutes from "./appRoutes";


const menuItems = [
  {
    "text": "Til dig med Letsikringer",
    "link": appRoutes.FOR_KUNDER,
    "children": [
      {
        "text": "Brug din forsikring",
        "link": appRoutes.BRUG_DIN_FORSIKRING
      },
      {
        "text": "Tjek din pension",
        "link": appRoutes.TJEK_DIN_PENSION
      },
      {
        "text": "Tjek din begunstigelse",
        "link": appRoutes.BEGUNSTIGELSE
      },
      {
        "text": "Forbered møde med rådgiver",
        "link": appRoutes.MOEDEFORBEREDELSE
      },
      {
        "text": "Helbredsoplysninger",
        "link": appRoutes.HELBREDSOPLYSNINGER
      },
      {
        "text": "Behandling af personoplysninger",
        "link": appRoutes.BEHANDLING_AF_PERSONOPLYSNINGER
      },
      {
        "text": "Skatteregler",
        "link": appRoutes.SKATTEREGLER
      }
    ]
  },
  {
    "text": "Letsikringer",
    "link": appRoutes.PRODUKTET,
    "children": [
      {
        "text": "Letsikring ved sygdom",
        "link": appRoutes.LETSIKRING_VED_SYGDOM
      },
      {
        "text": "Letsikring ved kritisk sygdom",
        "link": appRoutes.LETSIKRING_VED_KRITISK_SYGDOM
      },
      {
        "text": "Letsikring af helbred",
        "link": appRoutes.LETSIKRING_AF_HELBRED
      },
      {
        "text": "Letsikring af indtægt ved pension",
        "link": appRoutes.LETSIKRING_AF_INDTAEGT_VED_PENSION
      },
      {
        "text": "Letsikring af barn ved død",
        "link": appRoutes.LETSIKRING_AF_BARN_VED_DOED
      },
      {
        "text": "Letsikring ved død",
        "link": appRoutes.LETSIKRING_VED_DOED
      },
      {
        "text": "Letsikring til selvstændige",
        "link": appRoutes.LETSIKRING_TIL_SELVSTAENDIGE
      },
      {
        "text": "Omkostninger",
        "link": appRoutes.OMKOSTNINGER
      },
      {
        "text": "Investering",
        "link": appRoutes.INVESTERING
      }
    ]
  },
  {
    "text": "Her får du Letsikringer",
    "link": appRoutes.BLIV_KUNDE
  },
  {
    "text": "Om Letpension",
    "link": appRoutes.OM_LETPENSION,
    "children": [
      {
        "text": "Medarbejdere",
        "link": appRoutes.MEDARBEJDERE
      },
      {
        "text": "Direktion",
        "link": appRoutes.DIREKTION
      },
      {
        "text": "Bestyrelse",
        "link": appRoutes.BESTYRELSE
      },
      {
        "text": "Job hos Letpension",
        "link": appRoutes.JOBOPSLAG
      },
      // {
      //   "text": "Nyheder",
      //   "link": appRoutes.NYHEDER
      // },
      {
        "text": "Privatliv og cookies",
        "link": appRoutes.PRIVATLIV_OG_COOKIES
      }
    ]
  }
];
export default menuItems;
