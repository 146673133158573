import React from "react";
import Omkostninger from "./Omkostninger";

const OmkostningerStandard = (props) => {
  return (
      <Omkostninger maxDepotVardi={2500001} udvidet={false}/>
  )
};

export default OmkostningerStandard;

