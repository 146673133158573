import React from "react";
import { Container, Divider, Grid, Segment } from "semantic-ui-react";
import IkonGennemgang from "../images/GennemgangAfDinFormue.svg";
import IkonMaalOgOensker from "../images/MaalOgOensker.svg";
import IkonSkraeddersyetPensionsstrategi from "../images/SkraeddersyetPensionsstrategi.svg";
import IkonLoebendeTilpasning from "../images/LoebendeTilpasning.svg";
import "./Selvstaendige.css"
import AutoAccordion from "./common/AutoAccordion";
import HeaderImage from "./common/HeaderImage";
import Forsidebillede from "../images/seniorkoncept_header.jpg";

const Senior = props => (
    <div style={{ backgroundColor: 'white' }}>
        <HeaderImage imageSrc={Forsidebillede} />
        <Divider hidden />
        <Container text>
            <Segment basic>
                <h2 className="trompet">FÅ HJÆLP I DIT PENGEINSTITUT</h2>
                <h2 className="ui header" style={{ paddingTop: 0 }}>Få ro med en skræddersyet pensionsplan</h2>
            </Segment>
        </Container>

        <Container text>

            <Segment basic>
                <p className="manchet">Der er mange ting at tage stilling til, når man skal sige farvel til
                    arbejdsmarkedet og gå på pension. Med et formueoverblik og en pensionsplan har du et godt fundament
                    for gå tiden som pensionist i møde – og et aspekt mindre at bekymre dig om.</p>

                <h3>Dit pengeinstitut kan hjælpe</h3>
                <p>Dit pengeinstitut har stor ekspertise i netop pensionsplanlægning. De kan derfor hjælpe dig med at få
                    overblik, inden du går på pension – hvad enten du skal på delpension, tidlig pension, efterløn eller
                    folkepension. Udover et overblik over din formue får du også en skræddersyet pensionsplan. Det
                    handler om at sikre, at du bruger af dine opsparinger og din formue på den mest fornuftige måde i
                    forhold til dine planer og ønsker. </p>

                <h3>Hvad kan du forvente af en skræddersyet pensionsplan?</h3>

                <Divider hidden />

                <Grid centered stackable columns={2}>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="fordele-ikon" style={{ backgroundImage: `url(${IkonGennemgang})` }}></div>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <h4>En grundig gennemgang af din formue og din nuværende økonomiske situation</h4>
                            <p>Din pensionsøkonomi handler ikke kun om din pensionsopsparing men om hele din formue. Det
                                kan være andre opsparinger, investeringer og friværdi i din bolig. Det hele spiller ind
                                i det samlede regnestykke, og et godt økonomisk overblik gør det lettere for dig at
                                planlægge dit liv efter arbejdet.</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="fordele-ikon" style={{ backgroundImage: `url(${IkonMaalOgOensker})` }}></div>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <h4>Fastlæggelse af dine mål og ønsker for pensionering</h4>
                            <p>Hvad er vigtigt for dig? Hvad vil du bruge friheden til? Vil du på større rejser, eller
                                vil du bosætte dig et andet sted. Måske vil du på højskole eller udforske gamle eller
                                nye hobbyer. Det kan også være du har nogle sundhedsaspekter, du skal tage højde for. Og
                                ønsker du at bruge hele din formue selv, eller ønsker du at noget skal gå videre.
                                Svarene kan være vidt forskellige, det er blot vigtigt at stille spørgsmålene. </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="fordele-ikon" style={{ backgroundImage: `url(${IkonSkraeddersyetPensionsstrategi})` }}></div>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <h4>Udarbejdelse af en skræddersyet pensionsstrategi, der maksimerer dine opsparinger og
                                formue</h4>
                            <p>Det er ikke ligegyldigt, i hvilken rækkefølge du bruger din formue, når du går på
                                pension. Du kan optimere dine penge, så de rækker længere og bedre. Den optimale
                                rækkefølge afhænger af en vurdering af dine behov, dine midlers afkastpotentiale,
                                skatteforhold og samspillet med offentlige ydelser.</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <div className="fordele-ikon" style={{ backgroundImage: `url(${IkonLoebendeTilpasning})` }}></div>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            <h4>Løbende opfølgning og tilpasning af planen, når din situation ændrer sig</h4>
                            <p>Livet er ikke statisk, og derfor er en pensionsplan det heller ikke. Du bør genbesøge din
                                plan hvert eller hvert andet år. På den måde kan du tage justeringer i opløbet og sikre,
                                at de gode beslutninger bliver taget i tide. </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Divider hidden />

                <h3>Hvornår kan du få dine pensioner udbetalt?</h3>
                <p>Hvornår du tidligst kan få udbetalt din pensionsordning, afhænger af, hvornår pensionsordningen er oprettet, og hvornår du er født. Din rådgiver i dit pengeinstitut kan hjælpe dig med at få overblik.</p>

                <h3>Hvem får din pensionsopsparing?</h3>
                <p>Pensionsopsparinger og udbetalinger fra livsforsikringer indgår som udgangspunkt ikke i bodelingen efter en afdød, men tilfalder den begunstigede direkte og uden om dødsboet. Det betyder også, at udbetalingerne som hovedregel er beskyttet mod afdødes kreditorer. </p>
                <p>Du bestemmer selv, hvem der skal være begunstiget. Dit pengeinstitut kan hjælpe dig med at sikre, at alt er, som du ønsker, det skal være. </p>


                <h3>Læs om de forskellige typer af pensionsopsparinger</h3>

                <AutoAccordion activeIndex={-1}>
                    <AutoAccordion.Item title="Aldersopsparing">
                        En aldersopsparing udbetales typisk på én gang, så du får et samlet beløb, men du kan også vælge at få pengene udbetalt i flere portioner. Du skal ikke betale skat eller afgift, når du skal have din opsparing udbetalt og den giver ikke modregning i folkepensionens tillægsbeløb. Efterløn og tidlig pension kan blive modregnet af din aldersopsparing.  </AutoAccordion.Item>
                    <AutoAccordion.Item title="Kapitalpension">En kapitalpension udbetales typisk på én gang, så du får et samlet beløb, men du kan også vælge at få pengene udbetalt i flere portioner. Du betaler en afgift på 40 % til staten, når din kapitalpension bliver udbetalt. Udbetalinger fra en kapitalpension giver ikke modregning i folkepensionens pensionstillæg. Efterløn og tidlig pension kan blive modregnet af din kapitalpension.  </AutoAccordion.Item>
                    <AutoAccordion.Item title="Ratepension">En ratepension giver løbende udbetalinger, der udbetales over mindst 10 år og maksimalt 30 år. Du skal betale indkomstskat af udbetalingerne, men ikke arbejdsmarkedsbidrag. Udbetalinger fra en ratepension kan give modregning i både efterløn, tidligpension og folkepensionens tillægsbeløb. </AutoAccordion.Item>
                    <AutoAccordion.Item title="Livrenter">En livrente er en livsvarig pension, som giver dig en løbende udbetaling resten af livet – lige meget hvor længe du lever. Du skal betale indkomstskat af udbetalingerne, men ikke arbejdsmarkedsbidrag. Selv om du har startet udbetalingerne fra en livsvarig pension, har du mulighed for at stoppe udbetalingerne i en periode og senere genoptage udbetalingerne. Udbetalingerne fra den livsvarige pension bortfalder som udgangspunkt ved din død, medmindre du tilknytter ægtefælle/samlever-dækning eller sikrer dine efterladte. Udbetaling fra livrenter kan give modregning i tidlig pension, efterløn og folkepensionens tillægsbeløb.</AutoAccordion.Item>
                </AutoAccordion>
            </Segment>

        </Container>
    </div>
);

export default Senior;
