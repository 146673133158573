import React from "react";
import {Container, Divider, Grid, Header} from "semantic-ui-react";
import AutoAccordion from "../common/AutoAccordion";
import MiniBox from "../common/MiniBox";
import IkonNounCoins from "../../images/IkonNounCoins.svg";
import TjekDinPension from "../../images/IkonTjekDinPension.svg";
import appRoutes from "../../routes/appRoutes";
import { useSelector } from 'react-redux';
import { formatNumber } from '../../util/formatUtils';
import CallForAction from "../common/CallForAction";

const Skatteregler = props => {

  const satser = useSelector(state => state.satser.satser)

  if (!satser)
    return null;


  let aarstal = satser.indevaerendeAar;
  let ratemax = formatNumber(satser.maksimaltFradragSkat2);
  let opfyldningsfradrag = formatNumber(satser.opfyldningsFradragLivrente);
  let konverteringsgraense = formatNumber(satser.livrenteKonverteringsGraense);
  let skattefritDoedsboBeloebsgraense = formatNumber(satser.doedsboSkattepligtigGraense);
  let tilbagekoebsgebyr = formatNumber(satser.omkUdbetalingOpsparingIUtide);

  return (
      <div>
        <Header as="h2" textAlign="center">Skatteregler</Header>
        <Divider hidden/>
          <Container text>

          <p>Der er forskellige skatteregler for de forskellige måder at spare op og forsikre sig på:
        </p>
        <ul>
          <li> Skattekode 1 – Pensionsordning med løbende udbetaling, bortset fra ophørende livrenter</li>
          <li> Skattekode 2 – Rateforsikring i pensionsøjemed</li>
          <li> Skattekode 3 – Kapitalforsikring i pensionsøjemed</li>
          <li> Skattekode 5 – Forsikring med engangsudbetaling</li>
        </ul>

        <p>Kontakt din rådgiver i dit lokale pengeinstitut, hvis du ønsker rådgivning om de forskellige måder at spare
          op og forsikre sig på.</p>
        <p>Herunder kan du se de gældende regler for de forskellige skattekoder.</p>

        <Divider hidden/>

        <AutoAccordion activeIndex={-1}>
          <AutoAccordion.Item title="Særligt om SKAT og samspillet med din årsopgørelse">
            <p>Dine indbetalinger til skattekode 1, som ikke bliver betalt via en anden pensionsordning, bliver
              indberettet til SKAT. Det betyder dog ikke
              nødvendigvis, at du automatisk får det fradrag for dine indbetalinger, som du skal have. Derfor skal du
              holde øje med din årsopgørelse og
              kontrollere, om du har fået de rigtige fradrag. Nedenfor beskriver vi, hvordan du kan kontrollere din
              årsopgørelse.</p>
            <Header as="h4">Letsikring ved sygdom, Tab af erhvervsevne Basis eller Letsikring af barn ved død, som du
              ikke betaler fra en pensionskonto samt
              Letsikring
              af indtægt ved pension, som du betaler privat</Header>

            <p>PFA indberetter dine indbetalinger til SKAT efter de til enhver tid gældende regler. Det betyder, at din
              skattemappe løbende bliver opdateret.</p>
            <p>Hvis du ønsker at ændre eller tilføje noget til disse oplysninger, kan du inden den 1 maj rette
              oplysningerne tre kalenderår bagud.</p>


            <p>På årsopgørelsen skal dit fradrag fremgå af rubrik 24: <em>Bidrag til privattegnede livsvarige
              pensionsordninger</em>.</p>
            <p>På forskudsopgørelsen skal dit fradrag fremgå af rubrik 436: <em>Bidrag til privattegnede livsvarige
              pensionsordninger</em>.</p>
            <p>Du kan kontrollere og ændre oplysningerne ved at logge på SKAT.dk eller ved at ringe til SKAT på tlf.
              7222 1818.</p>

            <Header as="h4">Overførsel fra ratepension til Letsikring af indtægt ved pension - hvis du har betalt for
              meget ind på din ratepension</Header>
            <p>Hvis du har betalt for meget ind på din ratepension, korrigerer SKAT automatisk din årsopgørelse, så du
              kun får det fradrag, du er berettiget
              til.</p>
            <p>Er din ratepension privat, bliver det samlede fradrag reduceret til {ratemax} kr. ({aarstal}).</p>
            <p>Bliver din ratepension betalt via din arbejdsgiver, bliver din indtægt forhøjet med den betaling, der
              ligger ud over {ratemax} kr. ({aarstal}).</p>
            <p>Du kan rette indbetalingerne ved at få dit pengeinstitut til at overføre beløbet fra din ratepension til
              en Letsikring af indtægt ved pension.
              Overførslen bliver indberettet til SKAT.</p>
            <p>Når overførslen er indberettet til SKAT, skal du rette din årsopgørelse.</p>
            <p>Hvis din ratepension er privat, skal du indtaste det beløb, der er overført fra ratepensionen til din
              Letsikring af indtægt ved pension, i
              årsopgørelsens rubrik 24: <em>Bidrag til privattegnede livsvarige pensionsordninger</em>. På
              forskudsopgørelsen skal du angive beløbet i rubrik
              436: <em>Bidrag til privattegnede livsvarige pensionsordninger</em></p>
            <p>Hvis din ratepension bliver betalt via din arbejdsgiver, skal din indtægt sættes ned. Du kan kontrollere
              indtægten ved at sammenligne indtægten på
              din nye årsopgørelse med indtægten på din første årsopgørelse, men du kan ikke selv rette indtægten.
              Derfor skal du ringe til SKAT på tlf. 7222 1818
              for at få indtægten rettet.</p>

            <Header as="h4">Indbetaling på Letsikring af indtægt ved pension via din arbejdsgiver</Header>
            <p>Din arbejdsgiver indberetter de beløb, som arbejdsgiveren indbetaler for det kalenderår, som beløbene
              vedrører. PFA indberetter betalingerne for
              det kalenderår, hvor beløbene er modtaget i PFA. Du skal ikke foretage dig noget.</p>
            <p>Hvis betalingen sker tæt på årsskiftet, kan PFA's indberetning derfor være i et andet kalenderår end
              arbejdsgiverens. Dermed vil oplysninger i din
              skattemappe ikke stemme med de beløb, som arbejdsgiveren har indberettet. Det har ifølge SKAT ingen
              betydning og du behøver derfor ikke holde øje
              med, om du får dit fradrag, når din indbetaling sker via din arbejdsgiver.</p>
          </AutoAccordion.Item>

          <AutoAccordion.Item
              title="Skattekode 1 - Pensionsordning med løbende udbetaling, bortset fra ophørende livrenter">
            <p>Under skattekode 1 kan du have følgende produkter:</p>
            <ul>
              <li>Letsikring ved sygdom</li>
              <li>Tab af erhvervsevne Basis</li>
              <li>Letsikring af barn ved død</li>
              <li>Letsikring af indtægt ved pension (kan være oprettet både med garantiperiode og/eller dækning af
                ægtefælle/samlever)
              </li>
            </ul>

            <MiniBox>
              <p><strong>Hvordan kan du få fradrag?</strong></p>
              <p><strong>Letsikring ved sygdom, Tab af erhvervsevne Basis og Letsikring af barn ved død betalt via frie
                midler, samt Letsikring af indtægt ved
                pension oprettet privat</strong></p>

              <p>Reglerne for fradrag bliver styret af, hvor længe betalingen løber og hvor længe eventuelle forhøjelser
                af betalingen løber.</p>
              <p>Du får fradrag for din betaling for din Letsikring. PFA indberetter automatisk beløbet til SKAT, og du
                kan tjekke det i rubrik 24 i din
                selvangivelse og i rubrik 436 i din forskudsopgørelse.</p>

              <p>Du kan få fradrag for betalinger til private pensions- og forsikringsordninger på op
                til {opfyldningsfradrag} kr. ({aarstal}) hvert år.
                Beløbsgrænsen på {opfyldningsfradrag} kr. ({aarstal}) bliver kaldt et opfyldningsfradrag. Du har kun ét
                samlet opfyldningsfradrag for alle private
                betalinger til skattekode 1. Overstiger dine samlede betalinger grænsen, kan du trække dine betalinger
                fra efter én af følgende regler:</p>

              <p><strong>1) Betaling i MERE end 10 år</strong></p>
              <p>Hvis du har aftalt at betale i en periode på 10 år eller mere, kan du trække den fulde betaling fra i
                skat hvert år.</p>
              <p><em>Eksempel: Du betaler 120.000 kr. hvert år i 10 år eller mere og kan derfor trække den fulde
                betaling på 120.000 kr. fra i skat hvert år.</em>
              </p>

              <p><strong>2) Betaling i MINDRE end 10 år</strong></p>
              <p>Hvis du har aftalt at betale i en periode, der er kortere end 10 år, skal du fordele dine samlede
                betalinger ligeligt over 10 år for at regne ud,
                hvor meget du kan få fradrag for. Det gælder også eventuelle forhøjelser af betalingen, hvis
                forhøjelserne gælder for en periode på mindre end 10
                år.</p>
              <p><em>Eksempel: Du betaler 80.000 kr. hvert år i fem år. Din samlede aftalte betaling er derfor 400.000
                kr. Når du fordeler dine samlede betalinger
                over 10 år, svarer det til, at du kan trække 1/10, altså 40.000 kr., fra i skat hvert år i 10 år.
                Derudover har du altid mulighed for at fylde op
                til grænsen på {opfyldningsfradrag} kr. ({aarstal}), hvis din betaling er på
                minimum {opfyldningsfradrag} kr. ({aarstal}) om året. I eksemplet her
                kan du altså trække {opfyldningsfradrag} kr. ({aarstal}) fra om året, indtil du har trukket den samlede
                betaling på 400.000 kr. fra i skat.</em>
              </p>

              <p><strong>3) Engangsbetaling</strong></p>
              <p>Hvis du kun betaler til ordningen én gang, kan du trække beløbet fra med 1/10 af betalingen hvert år i
                10 år. Hvis 1/10 af betalingen er mindre
                end {opfyldningsfradrag} kr. ({aarstal}), kan du vælge at trække op til {opfyldningsfradrag} kr.
                ({aarstal}) fra i skat hvert år, indtil du har
                trukket hele betalingen fra i skat. Du kan ikke trække mere fra, end du har betalt.</p>
              <p><em>Eksempel 1: Du betaler et engangsbeløb på 600.000 kr. Du kan derfor trække 1/10, svarende til
                60.000 kr., fra i skat hvert år i 10 år.</em>
              </p>
              <p><em>Eksempel 2: Du betaler et engangsbeløb på 300.000 kr. Da 1/10 af beløbet, altså 30.000 kr., er
                under grænsen på {opfyldningsfradrag} kr.
                ({aarstal}), kan du trække {opfyldningsfradrag} kr. ({aarstal}) fra hvert år, indtil du har trukket den
                samlede betaling på 300.000 kr. fra i
                skat.</em></p>
              <p>Hvis du er selvstændig erhvervsdrivende, bliver grænsen for opfyldningsfradraget ændret
                fra {opfyldningsfradrag} kr. ({aarstal}) til 30 % af din
                virksomheds overskud før renter - dog mindst {opfyldningsfradrag} kr. ({aarstal}) Bemærk, at du som
                selvstændig kan sætte op til {
                  ratemax} kr. ({aarstal}) ind på en ratepension (skattekode 2), samtidig med, at du sætter op til 30 %
                af dit overskud ind på en Letsikring af
                indtægt ved pension (livrente - skattekode 1).</p>

              <p><strong>Letsikring af indtægt ved pension oprettet via arbejdsgiver</strong></p>
              <p>Der er ingen grænse for, hvor meget du kan betale og trække fra i skat, hvis din Letsikring af indtægt
                ved pension er oprettet via din
                arbejdsgiver. Betalingen til din pension via din arbejdsgiver bliver trukket fra i din løn, inden du
                betaler skat og derved får du fradraget med
                det samme.</p>

              <p><strong>Letsikringer betalt via en anden pensionsordning</strong></p>
              <p>Du får IKKE fradrag for betalingerne til din Letsikring, hvis betalingen sker med penge fra en anden
                pensionsordning, hvor du allerede har fået
                fradrag for betalingen. Hvis det er tilfældet, bliver din Letsikring betalt via din ratepension eller
                din kapitalpension i dit pengeinstitut.</p>
            </MiniBox>

            <MiniBox>
              <p><strong>Hvordan bliver udbetalingen beskattet?</strong></p>
              <p>Udbetalinger fra din Letsikring er personlig indkomst, som du skal betale skat af. Du skal ikke betale
                arbejdsmarkedsbidrag af udbetalingerne.
                Løbende udbetaling ved din død, der bliver ændret til en engangsudbetaling, bliver beskattet med en
                afgift til staten på 40 % samt eventuel
                boafgift.</p>

              <p><strong>Særligt for Letsikring af indtægt ved pension</strong></p>
              <p>Hvis den årlige udbetaling fra en Letsikring af indtægt ved pension, der er oprettet via din
                arbejdsgiver, er mindre
                end {konverteringsgraense} kr. ({aarstal}), kan du ændre den løbende udbetaling til en
                engangsudbetaling, som du skal betale 40 % i afgift af til
                staten.</p>
              <p>Hvis udbetalingen fra en garantiperiode på en Letsikring af indtægt ved pension sker til en af følgende
                personer i forbindelse med din død, kan
                de vælge mellem løbende udbetaling eller en engangsudbetaling:</p>
              <ul>
                <li>din ægtefælle/registrerede partner</li>
                <li>din tidligere ægtefælle</li>
                <li>din samlever</li>
                <li>dine børn, samlevers børn eller stedbørn under 24 år</li>
              </ul>
              <p>Sker udbetalingen til andre, herunder børn over 24 år, skal udbetalingen ske som en
                engangsudbetaling.</p>
            </MiniBox>

                    <MiniBox>
                        <p><strong>Uafskrevne fradrag i forbindelse med dødsfald</strong></p>
                        <p>Hvis du har oprettet en Letsikring af indtægt ved pension med fx et stort engangsindskud og
                            dør, før du har fået fuldt fradrag for engangsindskuddet, gælder der særlige regler for
                            udnyttelse af det resterende fradrag.</p>
                        <p><strong>Generelt</strong></p>
                        <p><u>I dødsåret</u></p>
                        <p>I dødsåret er det kun muligt at udnytte fradraget i dødsboet. Fradragsværdien vil her være 50
                            %. Efter udnyttelse af fradraget vil en eventuel negativ indkomst i dødsåret kunne overføres
                            til den efterlevende ægtefælle, men det er altså ikke muligt at overføre selve
                            fradraget.</p>
                        <p>Hvis der ikke er en efterladt ægtefælle og dødsboet ikke er dødsboskattepligtigt, er det ikke
                            muligt at udnytte fradraget. Grænsen for, hvornår dødsboet bliver skattepligtigt er
                            {skattefritDoedsboBeloebsgraense} kr. ({aarstal}).</p>
                        <p><u>Fra året efter dødsåret</u></p>
                        <p>Fra året efter dødsåret kan ægtefællen udnytte fradraget i den resterende periode, indtil
                            fradraget er fuldt afskrevet. Fradragsværdien afhænger af ægtefællens indkomstforhold og kan
                            være op mod ca. 52 %.</p>
                        <p>Alternativt kan ægtefællen anvende det resterende fradrag til at nedsætte afgiften ved
                            konvertering af en eventuel periode for sikring af efterladte jf. nedenfor, dog kun såfremt
                            ægtefællen ikke er indsat som medforsikret.</p>

                        <p><strong>Hvis der ikke er en ægtefælle, men evt. en samlever</strong></p>
                        <p>Samlever kan ikke udnytte fradraget i den resterende periode, da samlevende ikke er
                            sambeskattet.
                            Overvej derfor om der kun skal tilvælges sikring af efterladte, jf. nedenfor.
                            Tilsvarende overvejelse bør gøres, hvis der hverken er ægtefælle eller samlever.
                        </p>
                        <p><strong>Hvis ordningen har startet udbetaling og der kun er sikring af efterladte</strong>
                        </p>
                        <p>Dør du fra et uafskrevet fradrag, og der ikke er en efterladt ægtefælle, kan fradraget som
                            udgangspunkt kun udnyttes på din Letsikring af indtægt ved pension, hvis udbetalingen er
                            startet og det beløb, der bliver udbetalt i forbindelse med dødsfaldet er "sikring af
                            efterladte konverteret til et engangsbeløb". I den situation kan afgiftsgrundlaget for
                            beregning af de 40 % i afgift til staten nedsættes med det uafskrevne beløb efter udnyttelse
                            af fradraget i dødsåret jf. ovenfor.</p>

                        <p><strong>Hvis ordningen ikke har startet udbetaling</strong></p>
                        <p>Det uafskrevne fradrag kan ikke udnyttes i en eventuel udbetaling af sikring af opsparing -
                            heller ikke selvom der betales 40 % af udbetalingen i afgift til staten. Det skyldes, at
                            sikring af opsparing er en separat dødsfaldsdækning. Fradraget for denne del sker under
                            reglerne om rateloft (skattekode 2), mens det uafskrevne fradrag vedrører reglerne for
                            Letsikring af indtægt ved pension (skattekode 1).</p>
                        <p>Der henvises i øvrigt Pensionsbeskatningsloven og Dødsboskatteloven.</p>
                    </MiniBox>

                    <MiniBox>
                        <p><strong>Uafskrevne fradrag i forbindelse med tilbagekøb</strong></p>
                        <p>Har du en Letsikring af indtægt ved pension, som du ønsker at tilbagekøbe - det vil sige
                            ophæve din
                            forsikring før pensionering - er det muligt
                            mod et gebyr til PFA Pension på {tilbagekoebsgebyr} kr. ({aarstal}) og en afgift til staten
                            på 60 %.
                            Hvis saldoen på din Letsikring er indtægt ved
                            pension er under 5.000 kr., opkræver vi ikke gebyret, men du skal stadig betale 60 % i
                            afgift til
                            staten.</p>
                        <p>Bemærk, at der kan være krav om nye helbredsoplysninger ved tilbagekøb.</p>
                        <p>Har du ved tilbagekøbet uafskrevne fradrag, kan denne del udbetales uden afgift. Det vil sige,
                            at hvis
                            du et år har indskudt fx 250.000 kr., som
                            fradrages med 1/10 om året, og du vælger at tilbagekøbe din Letsikring af indtægt ved
                            pension efter 5
                            år, kan du få udbetalt 125.000 kr. uden
                            afgift. For den resterende del skal du betale 60 % i afgift.</p>
                    </MiniBox>


          </AutoAccordion.Item>

          <AutoAccordion.Item title="Skattekode 2 - Rateforsikring i pensionsøjemed">
            <p>Under skattekode 2 kan du have følgende produkter:
            </p>
            <ul>
              <li>Invalidesum Basis</li>
              <li>Letsikring ved død</li>
              <li>Sikring af opsparing</li>
            </ul>
            <MiniBox>
              <p><strong>Hvordan kan du få fradrag?</strong></p>
              <p>Du får IKKE fradrag for betalingerne til din Letsikring, hvis betalingen sker med penge fra en anden
                pensionsordning, hvor du allerede har fået
                fradrag for betalingen. Hvis det er tilfældet, bliver din Letsikring betalt via din ratepension eller
                kapitalpension i dit pengeinstitut.</p>
              <p>Har du en sikring af opsparing, der er skattekode 2 og IKKE betalt via en pensionsordning, kan du få
                fradrag for betalingen for sikringen af
                opsparingen. Du skal indtaste beløbet i rubrik 21 i såvel årsopgørelsen som forskudsopgørelsen.</p>
            </MiniBox>
            <MiniBox>
              <p><strong>Hvordan er de generelle regler for fradrag?</strong></p>
              <p>Du kan hvert år få fradrag for betalinger til skattekode 2 på op til {ratemax} kr. ({aarstal}) efter
                arbejdsmarkedsbidrag. Du skal angive beløbet
                i rubrik 21 i såvel årsopgørelsen som forskudsopgørelsen.</p>
            </MiniBox>
            <MiniBox>
              <p><strong>Hvordan bliver udbetalingen beskattet?</strong></p>
              <p>Udbetalinger fra din Letsikring er personlig indkomst, som modtageren skal betale skat af. Modtageren
                skal ikke betale arbejdsmarkedsbidrag af
                udbetalingerne. Løbende udbetaling, der bliver ændret til en engangsudbetaling, bliver beskattet med en
                afgift til staten på 40 % samt eventuel
                boafgift.</p>
            </MiniBox>
            <p><strong>Særligt for Letsikring ved død</strong></p>
            <p>Hvis udbetalingen fra en Letsikring ved død sker til en af følgende personer, kan de vælge mellem løbende
              udbetaling eller en
              engangsudbetaling:</p>
            <ul>
              <li>din ægtefælle/registrerede partner</li>
              <li>din tidligere ægtefælle</li>
              <li>din samlever</li>
              <li>dine børn, samlevers børn og stedbørn under 24 år</li>
            </ul>
          </AutoAccordion.Item>

          <AutoAccordion.Item title="Skattekode 3 - Kapitalforsikring i pensionsøjemed">
            <p>Under skattekode 3 kan du have følgende produkter:
            </p>
            <ul>
              <li>Letsikring ved død</li>
              <li>Invalidesum Basis</li>
            </ul>
            <MiniBox>
              <p><strong>Hvordan kan du få fradrag?</strong></p>
              <p>Du har IKKE fradrag for betalinger til din Letsikring, da betalingen sker med penge fra en
                kapitalpension i dit pengeinstitut, hvor du allerede
                har fået fradrag for betalingen.</p>
            </MiniBox>
            <MiniBox>
              <p><strong>Hvordan bliver udbetalingen beskattet?</strong></p>
              <p>Modtageren skal som udgangspunkt betale 40 % af udbetalingen i afgift til staten. afgiften er som
                udgangspunkt på 40%. Hvis der er tale om en
                udbetaling ved din død, skal modtageren af udbetalingen eventuelt betale boafgift.</p>
            </MiniBox>
          </AutoAccordion.Item>

          <AutoAccordion.Item title="Skattekode 5 - Forsikring med engangsudbetaling">
            <p>Under skattekode 5 kan du have følgende produkter:
            </p>
            <ul>
              <li>Invalidesum Basis</li>
              <li>Letsikring ved død</li>
              <li>Letsikring af lån og kredit</li>
              <li>Livsforsikring Basis (70)</li>
              <li>Letsikring ved kritisk sygdom</li>
              <li>Sikring af opsparing</li>
            </ul>
            <MiniBox>
              <p><strong>Hvordan kan du få fradrag?</strong></p>
              <p>Du har IKKE fradrag for betalinger til din Letsikring.</p>
            </MiniBox>
            <MiniBox>
              <p><strong>Hvordan bliver udbetalingen beskattet?</strong></p>
              <p>Modtageren skal ikke betale skat eller afgift til staten af udbetalingen. Hvis der er tale om en
                udbetaling ved din død, skal modtageren af
                udbetalingen eventuelt betale boafgift.</p>
            </MiniBox>
          </AutoAccordion.Item>
        </AutoAccordion>
          </Container>

        <Divider hidden/>

        <Grid centered stackable columns={2}>
          <Grid.Row>
            <CallForAction icon={IkonNounCoins} color="#e49d3b" text="Omkostninger"
                           link={appRoutes.OMKOSTNINGER}/>
            <CallForAction icon={TjekDinPension} color="#b9c35d" text="Tjek din pension"
                           link={appRoutes.TJEK_DIN_PENSION}/>

          </Grid.Row>
        </Grid>

      </div>
  )
};

export default Skatteregler;
