import {HENT_MEDARBEJDERE, INVALIDER_MEDARBEJDERE, MODTAG_MEDARBEJDERE,} from '../actions/medarbejdere.js'


export default function medarbejdere(
    state = {
      indlaeser: false,
      indlaest: false,
      invalideret: false,
      fejlmeddelelse: null,
      medarbejdere: null
    },
    action
)
{
  switch (action.type) {
    case HENT_MEDARBEJDERE:
      return Object.assign({}, state, {
        indlaeser: true,
        indlaest: false,
        invalideret: false,
        fejlmeddelelse: null,
        medarbejdere: null,
      });
    case MODTAG_MEDARBEJDERE:
      return Object.assign({}, state, {
        indlaeser: false,
        indlaest: true,
        invalideret: false,
        fejlmeddelelse: action.fejlmeddelelse,
        medarbejdere: action.medarbejdere
      });
    case INVALIDER_MEDARBEJDERE:
      return Object.assign({}, state, {
        indlaeser: false,
        indlaest: false,
        fejlmeddelelse: null,
        invalideret: true,
        medarbejdere: null
      });
    default:
      return state;
  }
}


