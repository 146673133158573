import React from "react";
import {Card, Divider, Header, Loader} from "semantic-ui-react";
import {useDispatch, useSelector} from 'react-redux';
import {indlaesBestyrelse} from '../../redux/actions/bestyrelse.js';
import './Bestyrelse.css';

const Bestyrelse = props => {

  const data = useSelector(state => state.bestyrelse);
  const dispatch = useDispatch();

  if (!data.indlaest && !data.indlaeser)
    dispatch(indlaesBestyrelse());

  const renderBestyrelsesMedlemmer = () => {
    let medlemsListe = null;
    if (data.medlemmer && data.medlemmer.map) {
      medlemsListe = data.medlemmer.map(function (BestyrelseModel) {
        return <BestyrelseKasse bestyrelsePerson={BestyrelseModel} key={BestyrelseModel.raekkeFoelge}/>;
      });
    }
    return medlemsListe;
  }

  return (
      <div>

        <Header as="h2" textAlign="center">Bestyrelse</Header>
        <Divider hidden/>
        <Card.Group centered>
          <Loader active={data.indlaeser} style={{top:200}}/>
          {renderBestyrelsesMedlemmer()}
        </Card.Group>
      </div>
  );
}

const BestyrelseKasse = props => {
  const {bestyrelsePerson} = props;
  return (
      <Card>
        <div className="bestyrelseMedlem">
          <strong className="mt-30">{bestyrelsePerson.navn}</strong><br/>
          {bestyrelsePerson.bestyrelsesTitel}
        </div>
        <div className="bestyrelseTitel">
          {bestyrelsePerson.stilling}
        </div>
      </Card>
  );
}

export default Bestyrelse;
