import React from "react";
import {Container, Divider, Grid, Header} from "semantic-ui-react";
import Helbredoplysninger from "../../images/IkonHelbredsoplysninger.svg";
import TjekDinPension from "../../images/IkonTjekDinPension.svg";

import appRoutes from "../../routes/appRoutes";
import CallForAction from "../common/CallForAction";

const MoedeForberedelse = props => (
    <div>
        <Container text>
            <Header as="h2" textAlign="center">Forbered dit møde med din rådgiver i dit lokale pengeinstitut</Header>
            <Divider hidden/>

            <p>For at din rådgiver kan vejlede dig bedst muligt, er det vigtigt, at du inden mødet:</p>
            <ul>
                <li>gør dig nogle overvejelser om, hvad der er vigtigt i dit liv.</li>
                <li>afgiver oplysninger om dit helbred, hvis der er behov for forsikringer.</li>
                <li>oplyser, hvilke pensionsordninger du har i dag, herunder hvilke forsikringer, der er knyttet til din
                    pension.
                </li>
            </ul>
            <Header as="h4">Hvad er vigtigt i dit liv?</Header>
            <p>Brug lidt tid på at overveje, hvad der er vigtigt for dig i dit liv. I Letportalen kan du trin for trin
                skabe et personligt billede af dine
                livsværdier under ”Mine værdier”. Når du er bevidst om, hvad der er vigtigt for dig, er du godt klædt på
                til at tale om dit sikkerhedsnet ved
                sygdom, død og pension med din rådgiver.</p>

            <Header as="h4">Oplysninger om dit helbred</Header>
            <p>Via din netbank har du adgang til en elektronisk helbredsportal. Her kan du i ro og mag svare på
                spørgsmål om dit helbred. Du kan gøre det, hvor og
                hvornår det passer dig. Når du har svaret på spørgsmålene, ved din rådgiver med det samme, hvilken
                løsning han eller hun kan tilbyde dig. Der er ingen
                ventetid, og du får den rigtige løsning fra starten.</p>

            <Header as="h4">Hvilke pensionsordninger har du i dag?</Header>
            <p>Hvis din rådgiver har mulighed for at danne sig et overblik over dine nuværende pension- og
                forsikringsaftaler inden mødet, bliver rådgivningen langt
                mere kvalificeret, og det er lettere at afdække om dine nuværende ordninger passer til dine livsværdier.
                Via din netbank kan du logge på PensionsInfo og
                sende alle dine oplysninger direkte til din rådgiver.</p>
        </Container>

        <Divider hidden/>
        <Divider hidden/>

        <Grid centered stackable columns={2}>
            <Grid.Row>
                <CallForAction icon={Helbredoplysninger} color="#e49d3b" text="Helbredsoplysninger"
                               link={appRoutes.HELBREDSOPLYSNINGER}/>
                <CallForAction icon={TjekDinPension} color="#b9c35d" text="Tjek din pension"
                               link={appRoutes.TJEK_DIN_PENSION}/>

            </Grid.Row>
        </Grid>

    </div>
);

export default MoedeForberedelse;
